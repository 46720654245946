import React, { useEffect, useState } from 'react'
import './ActiveAccount.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatchRoot } from '../../redux/store';
import { checkActiveAccountRequest } from '../../redux/controller/login.slice';
import { ActiveAccountRequest } from '../../common/define-identity';
import axios from 'axios';
import { CFG_BASE_URL } from '../../constants/config';
import { notification } from 'antd';

const ActivePage = () => {
    const [isNotFoundPage, setIsNotFoundPage] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatchRoot();

    // Kiểm tra xem đường dẫn của trang có phải đường dẫn hợp lệ không
    useEffect(() => {
        console.log(window.location.pathname);
        if (window.location.pathname.includes("active"))
            setIsNotFoundPage(false);
    }, [window.location.pathname])

    useEffect(() => {
        handleActiveAccount();
    }, [isNotFoundPage]);

    const handleActiveAccount = async () => {
        if (isNotFoundPage) return;
        // Use searchParams.get() to retrieve query parameters
        const emailUser = searchParams.get("email");
        const activeCode = searchParams.get("activeCode");
        console.log(emailUser, activeCode);

        if (emailUser && activeCode) {
            const req: ActiveAccountRequest = {
                email: emailUser,
                activeCode: activeCode,
                additionalProp1: {}
            }

            console.log(req);


            await axios.post(`${CFG_BASE_URL}/users/active-account`, req)
                .then(res => {
                    console.log(res);
                    notification.success({
                        message: 'Kích hoạt tài khoản thành công',
                        description: 'Chuyển hướng đến trang đăng nhập',
                    } as any);
                    setTimeout(() => {
                        navigate('/');
                    }, 4000);
                })
                .catch(err => {
                    console.log(err);
                })
        }

    }

    return (
        <div className='main-active-account'>
            {!isNotFoundPage &&
                <div className="container-active">
                    <h1>Đang xử lý xác nhận đăng nhập</h1>
                    <div className="loader"></div>
                    <p>Chuyển hướng đến trang đăng nhập, vui lòng chờ giây lát</p>
                </div>
            }
        </div>
    )
}

export default ActivePage