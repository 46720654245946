/* eslint-disable react-hooks/exhaustive-deps */
import { CopyOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Card, Image, List, Spin } from 'antd'
// import Meta from 'antd/es/card/Meta'
import Title from 'antd/es/typography/Title'
import { useEffect, useState } from 'react'
import VideoSmall from '../../components/video/videoSmall'
import CameraDetailImage from '../../images/camera_detail_img.png'
import CameraImg1 from '../../images/camera_img_1.png'
import CameraImg10 from '../../images/camera_img_10.png'
import CameraImg11 from '../../images/camera_img_11.png'
import CameraImg12 from '../../images/camera_img_12.png'
import CameraImg13 from '../../images/camera_img_13.png'
import CameraImg14 from '../../images/camera_img_14.png'
import CameraImg15 from '../../images/camera_img_15.png'
import CameraImg16 from '../../images/camera_img_16.png'
import CameraImg2 from '../../images/camera_img_2.png'
import CameraImg3 from '../../images/camera_img_3.png'
import CameraImg4 from '../../images/camera_img_4.png'
import CameraImg5 from '../../images/camera_img_5.png'
import CameraImg6 from '../../images/camera_img_6.png'
import CameraImg7 from '../../images/camera_img_7.png'
import CameraImg8 from '../../images/camera_img_8.png'
import CameraImg9 from '../../images/camera_img_9.png'
import DeleteIcon from '../../images/delete_icon.png'
import EditIcon from '../../images/edit_icon.png'
import './style/cameraDetail.css'
import Meta from 'antd/es/card/Meta'
// import { useNavigate, useParams } from 'react-router-dom';
import CImageLoading from '../../components/video/CImageLoading'
import StreamAPI from '../../api/camera/streaming.api'
import { v4 as uuidv4 } from 'uuid';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store'
import { IGetCameraManage } from '../../common/models/camera-model'
import { fetchListCameraRequest, getCameraByIdRequest, setLoadingCamera } from '../../redux/controller'
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios'
import { CFG_BASE_URL } from '../../constants/config'
import moment from 'moment'

interface DataType {
    title?: string;
    img?: string;
    context?: string;
    loading: boolean;
}

const lstDragonFruitInfo = [
    {
        vitaminC: '(> 6mg/100g)',
        axit: '(>0.4%CMAE)',
    },
    {
        vitaminC: '(5-6mg/100g)',
        axit: '0.21-0.4%CMAE)',
    },
    {
        vitaminC: '(< 5mg/100g)',
        axit: '(<0.2%CMAE)',
    }
]

const lstMangoInfo = [
    {
        vitaminC: '(> 26mg/100g)',
        axit: '(0.3-0.7%CAE)',
    },
    {
        vitaminC: '(20-25mg/100g)',
        axit: '(0.15-0.29%CAE)',
    },
    {
        vitaminC: '(< 20mg/100g)',
        axit: '(<0.15%CAE)',
    }
]
const CameraDetail = (props: any) => {
    let id: any = useParams(); // Lay id truyen qua param react router dom
    const cameraId = id.cameraId;
    const prop = { id: cameraId };


    const { listCamera, currentCamera } = useSelectorRoot((state) => state.camera);
    const { loadingCamera } = useSelectorRoot((state) => state.fruit);
    const [cameraDetailImage, setCameraDetailImage] = useState<string>(CameraDetailImage)
    const [cameraDetailInfor, setCameraDetailInfor] = useState<any>()
    const [cameraDetailCreateDate, setCameraDetailCreateDate] = useState<Date>(new Date())
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<DataType[]>([]);
    const [count, setCount] = useState<number>(5)
    const [step, setStep] = useState<number>(5)
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const dispatch = useDispatchRoot()
    const navigate = useNavigate();
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [thisCamera, setThisCamera] = useState<any>();
    const [originFruit, setOriginFruit] = useState<any>();
    const [lstFruitGood, setLstFruitGood] = useState<any>([]);
    const [lstFruitBad, setLstFruitBad] = useState<any>([]);
    const [lstFruitRot, setLstFruitRot] = useState<any>([]);
    const [warehouse, setWarehouse] = useState<any>();
    console.log(listCamera);
    // useEffect(()=>{
    //     dispatch(getCameraByIdRequest(cameraId));
    // },[])

    useEffect(() => {
        const warehouseId = sessionStorage.getItem('selectedWarehouseId')
        console.log(warehouseId);

        const origin = sessionStorage.getItem('selectedFruitOrigin');

        if (origin) {
            setOriginFruit(origin);
        }
        
        dispatch(fetchListCameraRequest(warehouseId));
    }, [])

    useEffect(() => {
        if (currentCamera) {
            showDetailInforOfCamera(currentCamera)

        }
    }, [currentCamera])

    useEffect(() => {
        console.log("CameraId:" + cameraId, "List camera" + listCamera);


        getCameraById(cameraId);
        getInfoFruitInCamera(cameraId);
        getAllWarehoues();
        // setLoadingPage(true);
        // // Đặt một timer chờ 5 giây
        // const timer = setTimeout(() => {
        //     // Sau 5 giây, thay đổi giá trị của isLoading thành false
        //     setLoadingPage(false);
        // }, 5000);

        // // Dọn dẹp timer khi component unmount
        // return () => clearTimeout(timer);
    }, [cameraId]); // Mảng rỗng đảm bảo useEffect chỉ chạy một lần sau khi component được mount

    useEffect(() => {
        setLoadingPage(loadingCamera);
        // Đặt một timer chờ 5 giây
        const timer = setTimeout(() => {
            dispatch(setLoadingCamera(false));
            // Sau 5 giây, thay đổi giá trị của isLoading thành false
            setLoadingPage(false);
        }, 5000);
        // Dọn dẹp timer khi component unmount
        return () => clearTimeout(timer);
    }, [loadingCamera])

    const getAllWarehoues = async () => {
        let token = sessionStorage.getItem('token');
        console.log(token);
        if (token) {
            token = token.replace(/"/g, '');
        }



        await axios.get(`${CFG_BASE_URL}/warehouses?size=100&offset=0`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                console.log('response', response.data.data);

                const lstWarehouse = response.data.data;

                const warehouse = lstWarehouse.find((item: any) => item.id === sessionStorage.getItem('selectedWarehouseId'));

                console.log('warehouse', warehouse);
                setWarehouse(warehouse);

            })
            .catch((error) => {
                console.log('error', error);
            });

    }

    const getInfoFruitInCamera = async (cameraId: string) => {
        let token = sessionStorage.getItem('token');
        console.log('token', token);
        if (token) {
            token = token.replace(/"/g, '');
        }

        await axios.get(`${CFG_BASE_URL}/fruit?cameraId=${cameraId}&size=1000&offset=0`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                console.log('response', response.data.data.items);
                const lstFruit = response.data.data.items;
                console.log(lstFruit);

                const goodFruit = lstFruit.filter((item: any) => item.status === 0);
                const badFruit = lstFruit.filter((item: any) => item.status === 1);
                const rotFruit = lstFruit.filter((item: any) => item.status === 2);

                setLstFruitGood(goodFruit);
                setLstFruitBad(badFruit);
                setLstFruitRot(rotFruit);

            })
            .catch((error) => {
                console.log('error', error);
            });
    }

    const getCameraById = async (id: string) => {
        await axios.get(`${CFG_BASE_URL}/camera/${id}`).then((response) => {
            console.log(response.data.data);
            const camera: any = listCamera.find((camera: IGetCameraManage) => camera.id === id);
            setThisCamera({
                ...response.data.data,
                numOfCrate: camera?.numOfCrate,
            });
        }).catch(() => {
            console.log('error fetching data!!')
        })
    }


    const showDetailInforOfCamera = (currentCamera: any) => {
        console.log(currentCamera)
        setCameraDetailInfor(
            <>
                <Title className="camera-detail-title" level={3}>
                    <div className='title-name'>{currentCamera.name}</div>
                    <div className='title-icon'>

                        {/* Icon sửa camera */}
                        <img alt='' src={EditIcon} />

                        {/* Icon xóa camera */}
                        <img alt='' src={DeleteIcon} />
                    </div>
                </Title>
                <div className='camera-detail-date'>
                    <label>Ngày nhập:</label>
                    <div style={{ marginLeft: 5 }}>{cameraDetailCreateDate.toLocaleDateString()}</div>
                </div>
                <div className='camera-detail-note'>
                    Mô tả: {currentCamera.note}
                    {/* Lorem ipsum dolor sit amet consectetur. Id quis fermentum quis nulla semper sit fusce tempor. Tempus ultricies justo mauris turpis feugiat dignissim feugiat sit. Ultricies montes auctor at risus. Amet dictum diam nullam praesent viverra elementum. In ut sit tortor sed mi amet viverra nibh eros. Fringilla mollis blandit blandit erat molestie ut natoque eu vel. */}
                </div>
            </>
        )
    }


    const handleSetAxit = (axit: any, fruitName: any) => {
        switch (axit) {
            case 0:
                return `${fruitName === 'Thanh long' ? lstDragonFruitInfo[0].axit : lstMangoInfo[0].axit}`;
            case 1:
                return `${fruitName === 'Thanh long' ? lstDragonFruitInfo[1].axit : lstMangoInfo[1].axit}`;
            case 2:
                return `${fruitName === 'Thanh long' ? lstDragonFruitInfo[2].axit : lstMangoInfo[2].axit}`;
            default:
                return `Độ axit trung bình`;
        }
    }

    const handleSetVitamin = (axit: any, fruitName: any) => {
        switch (axit) {
            case 0:
                return `${fruitName === 'Thanh long' ? lstDragonFruitInfo[0].vitaminC : lstMangoInfo[0].vitaminC}`;
            case 1:
                return `${fruitName === 'Thanh long' ? lstDragonFruitInfo[1].vitaminC : lstMangoInfo[1].vitaminC}`;
            case 2:
                return `${fruitName === 'Thanh long' ? lstDragonFruitInfo[2].vitaminC : lstMangoInfo[2].vitaminC}`;
            default:
                return `Lượng Vitamin trung bình`;
        }
    }


    return (
        <Spin spinning={loadingPage} tip="Đang lấy chi tiết camera" size="large">
            <div className='camera-detail-main'>
                <div className='camera-detail-left'>
                    {/* <Image className='camera-detail-image' preview={false} alt="example" src={cameraDetailImage} /> */}
                    {
                        cameraId ?
                            <CImageLoading
                                className='camera-detail-image h-56'
                                src={StreamAPI.getStream(cameraId)}
                                // className="h-56"
                                uuid={uuidv4()}
                                isFullScreen={isFullScreen}
                                setIsFullScreen={setIsFullScreen}
                                enableRTC={true}
                                // onClick={() =>{
                                //     navigate(`/camera_detail/${camera.id}`)
                                // }}
                                // pen={pen}
                                // updateSelectedPen={updateSelectedPen}
                                // onClick={() =>
                                //     handleShowExpandMenu(true, item)
                                // }
                                // isHiddenCamera={pen?.cameras[0]?.isHiddenCamera ? true : false}
                                idCamera={cameraId} // Hien ra danh sach cac chuong, va chi hien thi 1 camera cua 1 chuong

                            /> :
                            <div>Loading....</div>
                    }
                    {cameraDetailInfor}

                    {
                        thisCamera &&
                        <div className="container-camera-detail">
                            <h1>
                                Tên camera: {thisCamera.name}
                            </h1>
                            <div className="info">
                                <p><strong>Ngày nhập:</strong>
                                    {moment(thisCamera.createDate).format('DD/MM/YYYY')}
                                </p>
                                <p><strong>Mô tả: </strong>
                                    {thisCamera.note}
                                </p>
                                <p><strong>Số sọt phát hiện: </strong>
                                    {thisCamera.numOfCrate} sọt
                                </p>
                                <p><strong>Nguồn gốc: </strong>
                                    {originFruit}
                                </p>
                            </div>

                            <table className="table">
                                <tr>
                                    <th>Trạng thái</th>
                                    <th>Độ axit</th>
                                    <th>Lượng vitamin C</th>
                                </tr>
                                <tr>
                                    <td><span className="status green">Quả tốt</span></td>
                                    <td>
                                        {warehouse?.fruitType?.name === 'Thanh long' ? lstDragonFruitInfo[0].axit : lstMangoInfo[0].axit}
                                    </td>
                                    <td>
                                        {warehouse?.fruitType?.name === 'Thanh long' ? lstDragonFruitInfo[0].vitaminC : lstMangoInfo[0].vitaminC}
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="status yellow">Quả mới hỏng</span></td>
                                    <td>
                                        {warehouse?.fruitType?.name === 'Thanh long' ? lstDragonFruitInfo[1].axit : lstMangoInfo[1].axit}
                                    </td>
                                    <td>
                                        {warehouse?.fruitType?.name === 'Thanh long' ? lstDragonFruitInfo[1].vitaminC : lstMangoInfo[1].vitaminC}
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="status red">Quả đã hỏng</span></td>
                                    <td>
                                        {warehouse?.fruitType?.name === 'Thanh long' ? lstDragonFruitInfo[2].axit : lstMangoInfo[2].axit}
                                    </td>
                                    <td>
                                        {warehouse?.fruitType?.name === 'Thanh long' ? lstDragonFruitInfo[2].vitaminC : lstMangoInfo[2].vitaminC}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        // <div className='camera-detail-link'>
                        //     <div className='camera-title'>
                        //         Tên camera: {thisCamera.name}
                        //     </div>

                        //     <div className='camera-date'>
                        //         Ngày nhập: {moment(thisCamera.createDate).format('DD/MM/YYYY')}
                        //     </div>

                        //     <div className='camera-note'>
                        //         Mô tả: {thisCamera.note}
                        //     </div>

                        //     <div className='camera-numOfCrate'>
                        //         Số sọt phát hiện: {thisCamera.numOfCrate} sọt
                        //     </div>

                        //     <div className='camera-numOfCrate'>
                        //         Nguồn gốc: {originFruit}
                        //     </div>

                        //     <div className='camera-numOfCrate'>
                        //         Thông tin quả trong camera
                        //     </div>


                        //     {/* Tạo ra 3 chấm xanh vàng đỏ tượng trưng cho 3 loại quả tốt mới hỏng và đã hỏng cùng với số lượng của chúng */}

                        //     <div className='camera-fruit'>
                        //         <div className='camera-fruit-good'>
                        //             <div className='camera-fruit-good-circle'></div>
                        //             <div className='camera-fruit-good-number'>{lstFruitGood.length > 0 ? lstFruitGood.length : '0'}</div>
                        //             <div className='camera-fruit-good-text'>Quả tốt</div>
                        //             <div className='camera-fruit-good-text'>
                        //                 {
                        //                     `{Độ axit: ${handleSetAxit(0, originFruit)}, Lượng vitaminC: ${handleSetVitamin(0, originFruit)}}`
                        //                 }
                        //             </div>
                        //         </div>

                        //         <div className='camera-fruit-new'>
                        //             <div className='camera-fruit-new-circle'></div>
                        //             <div className='camera-fruit-new-number'>{lstFruitBad.length > 0 ? lstFruitBad.length : '0'}</div>
                        //             <div className='camera-fruit-new-text'>Quả mới hỏng</div>
                        //             <div className='camera-fruit-new-text'>
                        //                 {
                        //                     `{Độ axit: ${handleSetAxit(1, originFruit)}, Lượng vitaminC: ${handleSetVitamin(1, originFruit)}}`
                        //                 }
                        //             </div>
                        //         </div>

                        //         <div className='camera-fruit-bad'>
                        //             <div className='camera-fruit-bad-circle'></div>
                        //             <div className='camera-fruit-bad-number'>{lstFruitRot.length > 0 ? lstFruitRot.length : '0'}</div>
                        //             <div className='camera-fruit-bad-text'>Quả đã hỏng</div>
                        //             <div className='camera-fruit-bad-text'>
                        //                 {
                        //                     `{Độ axit: ${handleSetAxit(2, originFruit)}, Lượng vitaminC: ${handleSetVitamin(2, originFruit)}}`
                        //                 }
                        //             </div>

                        //         </div>

                        //     </div>

                        // </div>
                    }

                    {/* <div className=''>
                        <Button className='camera-detail-button' onClick={handleClickViewHistory}>
                            <CopyOutlined />
                            <span className='button-text'>Xem lịch sử</span>
                        </Button>
                    </div> */}



                </div>
                {/* <div className='camera-detail-right'>
                <List
                    className="camera-detail-list"
                    dataSource={data}
                    loading={initLoading}
                    loadMore={loadMore}
                    renderItem={(item) => (
                        <List.Item>
                            <Card
                                className="camera-detail-card"
                                cover={
                                    <Image preview={false} alt="example" src={item.img} />
                                }
                            >
                                <Meta title={item.title} description={item.context} />
                            </Card>
                        </List.Item>
                    )}
                />
            </div> */}
                <VideoSmall id={cameraId} />
            </div>
        </Spin>
    )
}

export default CameraDetail