import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Table } from 'antd';
import axios from 'axios';
import { CFG_BASE_URL } from '../../constants/config';
const CameraTotalTable = () => {
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [dataTable, setDataTable] = useState([]);


    useEffect(() => {
        getCameraTotal();
    }, []);


    const getCameraTotal = async () => {
        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
        }
        axios.get(`${CFG_BASE_URL}/users/total-camera-warehouse`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then((res) => {
            console.log(res);
            if (res.data && res.data.data) {
                const formattedData = res.data.data.map((item : any, index : any) => ({
                    key: index,
                    name: item.warehouseName,
                    quantity: item.totalCamera
                }));
                setDataTable(formattedData);
            }

        })
        .catch((err) => {
            console.log(err);
        })
    }

    const columns = [
        {
            title: 'Tên kho',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Số lượng camera',
            dataIndex: 'quantity',
            key: 'quantity',
        },
    ];

   


    return <div style={{width: '100%', height: '100%'}}>
        <Table columns={columns} dataSource={dataTable} pagination={false} bordered style={{borderRadius: '10px', width: '100%', height: '100%'}}  />
    </div>;
};

export default CameraTotalTable;
