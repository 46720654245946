import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import IdentityApi from "../../../api/identity.api";
import * as XLSX from 'xlsx';
import { Button, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'ID sọt phát hiện bởi AI',
        dataIndex: 'crateAIId',
    },
    {
        title: 'Chất lượng',
        dataIndex: 'quality',
    },
    {
        title: 'Tên camera',
        dataIndex: 'camName',
    },
    {
        title: 'Link camera',
        dataIndex: 'camLink',
    },

];
const api = IdentityApi.host;
const WarehouseDetails: FC = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [totalCrates, setTotalCrates] = useState(0);
    const history = useNavigate();
    // routers/route-names.tsx có: profile_farmList: '/profile/quan-ly-trang-trai/:userID'
    // Phần sau dấu hai chấm là tên biến (có thể thay đổi được), ở đây được đặt là "userID",
    // thế nên useParams() trả về một object có field "userID" với value là ID của user
    const id: any = useParams();

    useEffect(() => {
        const role = sessionStorage.getItem('role')
        if (role !== '"ADMIN"') {
            history('/404-not-found');
            return;
        }
        getDataUser();
        fetchData();
    }, []);

    const fetchData = () => {
        axios
            .get(`${api}/admin/warehouseById/${id.warehouseID}`)
            .then((response) => {
                console.log(response?.data);
                let count = 0;
                setTotalCrates(response.data?.crates?.length || 0);
                setData(
                    response.data?.data?.crates?.map((item: any) => {

                        count += 1;
                        return {
                            key: count, // STT (cột đầu tiên)
                            crateAIId: item.crateAIId || 'Không xác định', // Tên trang trại 
                            quality: item.quality || 'Không xác định',
                            camName: item.camera?.name || 'Không xác định',
                            camLink: item.camera?.link || 'Không xác định',
                        };
                    }),
                );
            })

            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));
    }

    const getDataUser = () => {
        axios.get('https://sit.api.pigman.com.vn/admin/users').then((response) => {
            console.log(response.data.data);

        })
    }

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: totalCrates,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };
    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.farmName.toLowerCase().includes(filter));

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, padding: '20px' }}>
                {/* <h1>hunghunghunghunghung</h1> */}
                <div style={{ display: 'block', flexWrap: 'wrap', gap: '20px' }}>
                    <div className="farmList-body">
                        <div className="button-farmList">
                            <p className="farmList-h2" style={{ fontSize: 24, fontWeight: 'bolder', fontFamily: '"Inter", sans- serif' }}>Quản lý chi tiết kho</p>
                            {/* <div className="search-and-sort">
                        <Search placeholder="Tìm kiếm" onSearch={onSearch} entserButton allowClear />
                    </div> */}
                            {/* <Search
                        className='search-and-sort'
                        placeholder="Tìm kiếm tên kho"
                        onChange={(event) => setFilter(event.target.value)}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    /> */}
                        </div>
                        <div className="table-farmList">
                            <Table
                                bordered
                                dataSource={sortData}
                                columns={columns}
                                rowClassName={getRowClassName}
                                pagination={paginationConfig}
                                style={{ minHeight: '800px' }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default WarehouseDetails;