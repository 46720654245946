import React, { FC, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatchRoot, useSelectorRoot } from '../../../redux/store';
import axios from 'axios';
import moment from 'moment';
import { DownloadOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Tooltip } from 'antd';
import user from '../../../services/user';
import { Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import './allWarehouse.scss'
import IdentityApi from "../../../api/identity.api";
import { CFG_BASE_URL } from '../../../constants/config';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'Tên trang trại',
        dataIndex: 'warehouseName',
    },
    {
        title: 'Địa chỉ',
        dataIndex: 'address',
    },
    {
        title: 'Chủ trạng trại',
        dataIndex: 'userName',
    },
    {
        title: 'Số kho được hệ thống theo dõi',
        dataIndex: 'crates',
    },
    {
        title: 'Loại quả',
        dataIndex: 'fruitType',
    },
    {
        title: 'Thao tác',
        dataIndex: 'operation',
    },

];


const WarehouseManage = () => {
    const [lstUser, setLstUser] = useState<any>([]);
    const [lstWarehouse, setLstWarehouse] = useState<any>([]);
    const [lstFruitType, setLstFruitType] = useState<any>([]);
    const [responseBlock, setResponseBlock] = useState('')
    const [lstWarehouseOfUser, setLstWarehouseOfUser] = useState<any>([]); // Danh sách kho của từng user
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const history = useNavigate();
    const api = IdentityApi.host


    useEffect(() => {
        getUser();
        getTypeFruit();
    }, [])

    useEffect(() => {
        getAllWarehouse();
    }, [lstUser])

    useEffect(() => {
        console.log(lstWarehouse);
        if (lstWarehouse.length === 0) return;
        const tmp = lstUser.map((user: any, index: any) => {
            const numWarehouse = lstWarehouse.filter((warehouse: any) => warehouse.userId === user.id);
            console.log('numWarehouse', numWarehouse);

            const warehouse = lstWarehouse.filter((warehouse: any) => warehouse.userId === user.id);
            // Lấy ra các warehouse nào chỉ có crate
            const crateWarehouse = warehouse.filter((warehouse: any) => warehouse.crates.length > 0);

            console.log('crateWarehouse', crateWarehouse);


            let fruitType = null;
            if (warehouse.length > 0) {
                const fruit = warehouse[0].fruitTypeId;
                fruitType = lstFruitType.find((fruitType: any) => fruitType.id === fruit);
            }

            return {
                key: index + 1,
                warehouseName: user.farmName ? user.farmName : 'Chưa có tên trang trại',
                address: user.address ? user.address : 'Chưa có địa chỉ',
                userName: user.name,
                crates: warehouse.length,
                fruitType: fruitType ? fruitType.name : 'Chưa có loại quả',
                operation: (
                    <Space>
                        <div className="text-link" onClick={() => handleClickWWW(user.id)}>
                            <Tooltip title="Xem kho">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 24 24"><path fill="black" fill-rule="evenodd" d="M11.217 3.553a1.75 1.75 0 0 1 1.566 0l7 3.5c.592.296.967.902.967 1.565V20a.75.75 0 0 1-1.5 0V8.618a.25.25 0 0 0-.138-.224l-7-3.5a.25.25 0 0 0-.224 0l-7 3.5a.25.25 0 0 0-.138.224V20a.75.75 0 0 1-1.5 0V8.618c0-.663.375-1.269.967-1.565zM6.25 12c0-.966.784-1.75 1.75-1.75h8c.966 0 1.75.784 1.75 1.75v7A1.75 1.75 0 0 1 16 20.75H8A1.75 1.75 0 0 1 6.25 19zM8 11.75a.25.25 0 0 0-.25.25v1.25h8.5V12a.25.25 0 0 0-.25-.25zm8.25 3h-8.5v1.5h8.5zm0 3h-8.5V19c0 .138.112.25.25.25h8a.25.25 0 0 0 .25-.25z" clip-rule="evenodd" /></svg>
                            </Tooltip>
                        </div>
                    </Space>
                ),
            }
        });

        // Sắp xếp theo số lượng kho giảm dần
        tmp.sort((a: any, b: any) => b.numWarehouse - a.numWarehouse);

        setLstWarehouseOfUser(tmp);

    }, [lstWarehouse]);

    const getUser = async () => {
        await axios.get(`${api}/admin/users`).then((response) => {
            console.log(response?.data?.data);
            setLstUser(response?.data?.data);
        }).catch(() => {
            console.log('error fetching data!!')
        })
    };

    const getAllWarehouse = async () => {
        if (lstUser.length === 0) return;

        lstUser.map((user: any) => {
            axios.get(`${CFG_BASE_URL}/admin/warehouseOfUser?userId=${user.id}`)
                .then((res) => {
                    console.log(res.data.data);

                    setLstWarehouse((prev: any) => [...prev, ...res.data.data]);
                })
                .catch((err) => {
                    console.log(err);
                })
        })
    }

    const getTypeFruit = async () => {
        await axios.get(`${CFG_BASE_URL}/fruitTypes/`)
            .then((res) => {
                console.log(res.data.data);
                setLstFruitType(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleClickWWW = (id: string) => {
        history(`/warehouse-manage/${id}`)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handleChangePage = (page: number, pageSize?: number) => {
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: lstWarehouseOfUser.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };

    const sortData = filter.length === 0 ? lstWarehouseOfUser : lstWarehouseOfUser.filter((item: any) => item?.warehouseName?.toLowerCase().includes(filter.toLocaleLowerCase()));

    const exportToExcel = async () => {
        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };

        // Add title row
        const today = new Date();
        const formattedDate = `ngày ${today.getDate()} tháng ${today.getMonth() + 1} năm ${today.getFullYear()}`;

        // Add title row
        const titleRow = worksheet.addRow([`Danh sách trang trại (tính đến ${formattedDate})`]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:F1'); // Adjust the range based on the number of columns

        // Add an empty row to separate the title from the headers
        worksheet.addRow([]);

        // Add first row of headers (merged cells)
        const headerRow1 = worksheet.addRow(['', '', '', '', '', '']);
        worksheet.mergeCells('A2:A3'); // STT
        worksheet.mergeCells('B2:B3'); // Họ và tên
        worksheet.mergeCells('C2:C3'); // Email
        worksheet.mergeCells('D2:D3'); // Số điện thoại
        worksheet.mergeCells('E2:E3'); // Địa chỉ
        worksheet.mergeCells('F2:F3'); // Ngày tạo tài khoản

        // Add second row of headers
        const headerRow2 = worksheet.addRow([
            'STT',
            'Tên trang trại',
            'Địa chỉ',
            'Chủ trang trại',
            'Số kho được hệ thống theo dõi',
            'Loại quả',
        ]);

        headerRow1.font = { bold: true };
        headerRow2.font = { bold: true };

        // Add columns (keys only, no headers)
        worksheet.columns = [
            { key: 'key', width: 10 },
            { key: 'warehouseName', width: 30 },
            { key: 'address', width: 30 },
            { key: 'userName', width: 30 },
            { key: 'crates', width: 30 },
            { key: 'fruitType', width: 30 },
        ];

        // worksheet.getRow(1).font = { bold: true };

        // Add data
        sortData.forEach((item: any, index: number) => {
            worksheet.addRow({
                key: item.key,
                warehouseName: item.warehouseName,
                address: item.address,
                userName: item.userName,
                crates: item.crates,
                fruitType: item.fruitType,
            });
        });

        worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber === 1 || rowNumber === 2 || rowNumber === 3) return; // Skip the title and header rows
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Danh sách trang trại.xlsx');
    };

    return (
        <div className="farmList-body">
            <div className="button-farmList">
                <p className="farmList-h2">Quản lý trang trại</p>
                {/* <div className="search-and-sort">
                        <Search placeholder="Tìm kiếm" onSearch={onSearch} enterButton allowClear />
                    </div> */}
                <Search
                    className='search-and-sort'
                    placeholder="Tìm kiếm tên kho"
                    onChange={(event) => setFilter(event.target.value)}
                    onSearch={onSearch}
                    enterButton
                    allowClear
                />
            </div>
            <Spin spinning={sortData.length === 0} >
                <div className="table-farmList">
                    <Table
                        dataSource={sortData}
                        columns={columns}
                        rowClassName={getRowClassName}
                        pagination={paginationConfig}
                    />
                    <div className='button-excel'>
                        <Button disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                    </div>
                </div>
            </Spin>
        </div >

    )
}

export default WarehouseManage