import { CameraFilled, EditFilled, EyeFilled } from '@ant-design/icons'
import { Button, Form, Modal, Select, Table, Tooltip } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const TempData = [
    {
        basketId: 1,
        batchId: 1,
        fruitType: "Xoài", // Loại quả
        entryDate: "2022-01-01", // Ngày nhập sọt vào lô
        quality: "Tốt", // Chất lượng
        agricultureType: "Loại 1", // Phân loại nông sản
        status: "Đã chuyển đi", // Tình trạng
    },
    {
        basketId: 2,
        batchId: 1,
        fruitType: "Thanh long", // Loại quả
        entryDate: "2022-01-01", // Ngày nhập sọt vào lô
        quality: "Thường", // Chất lượng
        agricultureType: "Loại 2", // Phân loại nông sản
        status: "Tại kho", // Tình trạng
    },
    {
        basketId: 3,
        batchId: 1,
        fruitType: "Thanh long", // Loại quả
        entryDate: "2022-01-01", // Ngày nhập sọt vào lô
        quality: "Hơi hỏng", // Chất lượng
        agricultureType: "Loại 1", // Phân loại nông sản
        status: "Đã chuyển đi", // Tình trạng
    },
    {
        basketId: 4,
        batchId: 1,
        fruitType: "Xoài", // Loại quả
        entryDate: "2022-01-01", // Ngày nhập sọt vào lô
        quality: "Hỏng hẳn", // Chất lượng
        agricultureType: "Loại 3", // Phân loại nông sản
        status: "Đã chuyển đi", // Tình trạng
    },
    {
        basketId: 5,
        batchId: 1,
        fruitType: "Xoài", // Loại quả
        entryDate: "2022-01-01", // Ngày nhập sọt vào lô
        quality: "Thường", // Chất lượng
        agricultureType: "Không xác định", // Phân loại nông sản
        status: "Tại kho", // Tình trạng
    },
    {
        basketId: 6,
        batchId: 1,
        fruitType: "Xoài", // Loại quả
        entryDate: "2022-01-01", // Ngày nhập sọt vào lô
        quality: "Tốt", // Chất lượng
        agricultureType: "Loại 2", // Phân loại nông sản
        status: "Đã chuyển đi", // Tình trạng
    },

]

const columns = [
    {
        title: 'STT',
        dataIndex: 'orderByNumber',
    },
    {
        title: 'ID sọt',
        dataIndex: 'basketId',
    },
    {
        title: 'ID lô',
        dataIndex: 'batchId',
    },
    {
        title: 'Loại quả',
        dataIndex: 'fruitType',
    },
    {
        title: 'Ngày nhập sọt vào lô',
        dataIndex: 'entryDate',
    },
    {
        title: 'Chất lượng',
        dataIndex: 'quality',
    },
    {
        title: 'Phân loại nông sản',
        dataIndex: 'agricultureType',
    },
    {
        title: 'Tình trạng',
        dataIndex: 'status',
    },
    {
        title: 'Thao tác',
        dataIndex: 'action',
    },
]
const { Option } = Select;

const DetailPackManager = () => {

    const id = useParams()
    const [detailData, setDetailData] = useState<any>([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [agricultureType, setAgricultureType] = useState('');

    useEffect(() => {
        console.log(id);
        getDetailData();

    }, [id])

    const getDetailData = async () => {
        const warehouseId = sessionStorage.getItem('warehouseId') ? sessionStorage.getItem('warehouseId')?.toString().slice(1, -1) : '';
        console.log(warehouseId);

        await axios.get(`https://api.ai-fruits.org/consignments/warehouse?warehouseId=${warehouseId}`)
            .then((res) => {
                console.log(res.data.data);

                // Lấy ra lô theo warehouseId
                const tmp = res?.data?.data.filter((item: any) => item.id === id.packId)
                console.log(tmp[0]);

                const tmp1 = TempData.map((item, index) => {
                    return {
                        ...item,
                        orderByNumber: index + 1,
                        batchId: tmp[0].name,
                        action: (
                            <div className='action-lst'>
                                <Tooltip title='Xem camera'>
                                    <Button className='btn-edit' type="primary" icon={<CameraFilled />} />
                                </Tooltip>
                                <Tooltip title='Xem chi tiết sọt'>
                                    <Button className='btn-edit' type="primary" icon={<EyeFilled />} />
                                </Tooltip>
                                <Tooltip title='Chỉnh sửa'>
                                    <Button className='btn-edit' type="primary" icon={<EditFilled />}
                                        onClick={showModal}
                                    />
                                </Tooltip>
                            </div>
                        ),
                    }
                })
                setDetailData(tmp1)

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        // Xử lý dữ liệu tại đây
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSelectChange = (value: string) => {
        setAgricultureType(value);
    };
    return (
        <div className='main-detail-pack-manager'>
            <div className='title'>
                Chi tiết lô
            </div>
            <Table
                className='table-detail-pack-manager'
                columns={columns}
                dataSource={detailData}
                pagination={false}
            />
            <Modal
                className='modal-edit-agriculture-type'
                title="Chỉnh sửa phân loại nông sản"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText='Lưu'
                cancelText='Hủy'
            >
                <Form>
                    <Form.Item name="agricultureType" label="Phân loại nông sản">
                        <Select placeholder="Chọn loại" onChange={handleSelectChange}>
                            <Option value="type1">Loại 1</Option>
                            <Option value="type2">Loại 2</Option>
                            <Option value="type3">Loại 3</Option>
                            <Option value="undefined">Không xác định</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default DetailPackManager
