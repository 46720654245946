/* eslint-disable react-hooks/exhaustive-deps */
import { CopyOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Card, Image, List, Table } from 'antd'
// import Meta from 'antd/es/card/Meta'
import Title from 'antd/es/typography/Title'
import { useEffect, useState } from 'react'
import moment from 'moment';
import './style/cameraDetail.css'
import Meta from 'antd/es/card/Meta'
// import { useParams } from 'react-router'
import CImageLoading from '../../components/video/CImageLoading'
import StreamAPI from '../../api/camera/streaming.api'
import { v4 as uuidv4 } from 'uuid';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store'
import { IGetCameraManage } from '../../common/models/camera-model'
import { getCameraByIdRequest } from '../../redux/controller'
import IdentityApi from "../../api/identity.api";
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
interface DataType {
    title?: string;
    img?: string;
    context?: string;
    loading: boolean;
}


const CameraHistory = (props: any) => {
    let id: any = useParams(); // Lay id truyen qua param react router dom
    const cameraId = id.cameraId;

    const api = IdentityApi.host;
    const [cameraHistory, setCameraHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
        },
        {
            title: 'ID rổ phát hiện bởi AI',
            dataIndex: 'crateAIId',
        },
        {
            title: 'Loại quả',
            dataIndex: 'fruitType',
        },
        {
            title: 'Lô',
            dataIndex: 'consignment',
        },
        {
            title: 'Ngày nhập',
            dataIndex: 'createdDate',
        },
        {
            title: 'Ngày xuất',
            dataIndex: 'movedDate',
        },
    ]
    useEffect(() => {
        const role = sessionStorage.getItem('role')
        // if(role !=='"ADMIN"'){
        //   navigator('/404-not-found');
        //   return;
        // }
        // getUser();
        getCameraHistory();
    }, [])

    const getCameraHistory = async () => {
        await axios.get(`${api}/cameraHistory/${cameraId}?size=10000`).then((response) => {
            let count = 0;
            setCameraHistory(
                response.data?.data[0]?.items.map((item: any) => {
                    count += 1;
                    return {
                        key: count, // STT (cột đầu tiên)
                        crateAIId: item.crateAIId ? item.crateAIId : 'Không xác định', // Tên trang trại 
                        fruitType: item.fruitType?.name ? item.fruitType?.name : 'Không xác định',
                        consignment: item.consignment?.name ? item.consignment?.name : 'Không xác định',
                        createdDate: item.createdDate ? moment(item.createdDate).format('HH:MM:SS DD/MM/YYYY') : 'Không xác định',
                        movedDate: item.movedDate ? moment(item.movedDate).format('HH:MM:SS DD/MM/YYYY') : 'Đang theo dõi'
                    };
                }),
            );
        }).catch(() => {
            console.log('error fetching data!!')
        })
    };
    console.log(cameraHistory);

    const handleChangePage = (page: number, pageSize?: number) => {
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: cameraHistory?.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, padding: '20px' }}>
                {/* <h1>hunghunghunghunghung</h1> */}
                <div style={{ display: 'block', flexWrap: 'wrap', gap: '20px' }}>
                    <div className="farmList-body">
                        <div className="button-farmList">
                            <p className="farmList-h2" style={{ fontSize: 24, fontWeight: 'bolder', fontFamily: '"Inter", sans- serif' }}>Lịch sử Camera</p>
                            {/* <div className="search-and-sort">
                        <Search placeholder="Tìm kiếm" onSearch={onSearch} entserButton allowClear />
                    </div> */}
                            {/* <Search
                        className='search-and-sort'
                        placeholder="Tìm kiếm tên kho"
                        onChange={(event) => setFilter(event.target.value)}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    /> */}
                        </div>
                        <div className="table-farmList">
                            <Table
                                dataSource={cameraHistory}
                                columns={columns}
                                rowClassName={getRowClassName}
                                pagination={paginationConfig}
                                style={{ minHeight: '800px' }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CameraHistory;
