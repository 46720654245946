import { Button, Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { AddWarehouseRequest } from '../../common/define-warehouse';
import { addWarehouseByUserIdRequest } from '../../redux/controller';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import './addWarehouseModal.css';
interface MyProps {
    isOpenModal: boolean;
    toggleEditWarehouseModal: () => void;
    selectedWarehouse: any;
}
const EditWarehouseModal = (props: MyProps) => {
    const dispatch = useDispatchRoot()
    const [warehouseName, setWarehouseName] = useState('')
    const [warehouseAddress, setWarehouseAddress] = useState('')
    const [form] = Form.useForm();
    const { selectedWarehouse } = useSelectorRoot(item => item.fruit);


    useEffect(() => {
        console.log('selectedWarehouseId: ', selectedWarehouse);
        if (selectedWarehouse) {
            console.log('selectedWarehouse: ', selectedWarehouse);
            form.setFieldsValue({
                name: selectedWarehouse.name,
                address: selectedWarehouse.address,
            });
        }
        // if (props.selectedWarehouse) {
        //     form.setFieldsValue({
        //         name: props.selectedWarehouse.name,
        //         address: props.selectedWarehouse.address,

        //     });
        // }
    }, [selectedWarehouse])


    const editWarehouse = (data: any) => {
        let userId = sessionStorage.getItem('userId')?.toString()
        if (userId) {
            // userId = userId.slice(1);
            // userId = userId.slice(0, userId.length - 1);
            // const param: AddWarehouseRequest = {
            //     name: warehouseName,
            //     address: warehouseAddress,
            //     userId: userId ? userId : '',
            // }

            // console.log(param);
            // dispatch(addWarehouseByUserIdRequest(param))
            props.toggleEditWarehouseModal()
        }
    }


    return (
        <Modal
            className='add-camera-modal'
            title="Sửa chi tiết kho"
            open={props.isOpenModal}
            onOk={props.toggleEditWarehouseModal}
            onCancel={props.toggleEditWarehouseModal}
            footer={[
                <Button className='btn-cancel' key="back" onClick={props.toggleEditWarehouseModal}>
                    Hủy bỏ
                </Button>,
                <Button className='btn-submit' key="submit" type="primary" onClick={editWarehouse}>
                    Cập nhật
                </Button>,
            ]}
        >
            <Form
                className="edit-warehouse-form"
                initialValues={{ remember: true }}
                onFinish={editWarehouse}
                layout='vertical'
                form={form}
            >
                <Form.Item
                    name="name"
                    label={<div>Tên kho <strong>*</strong></div>}
                    rules={[{ required: true, message: 'Vui lòng nhập tên kho' }]}
                >
                    <Input
                        onChange={(e) => { setWarehouseName(e.target.value) }}
                        className='form-input' placeholder="Nhập tên kho"
                    />
                </Form.Item>
                <Form.Item
                    name="address"
                    label={<div>Địa chỉ <strong>*</strong></div>}
                    rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                >
                    <Input
                        onChange={(e) => { setWarehouseAddress(e.target.value) }}
                        className='form-input' placeholder="Nhập địa chỉ"
                    />
                </Form.Item>
                {/* <Form.Item
                    name="note"
                >
                    <Input className='form-input' placeholder="Nhập mô tả" />
                </Form.Item> */}
            </Form>
        </Modal>
    )
}

export default EditWarehouseModal