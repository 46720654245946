import { Button, Form, Input, Modal } from 'antd';
import { useState } from 'react';
import { AddWarehouseRequest } from '../../common/define-warehouse';
import { addWarehouseByUserIdRequest } from '../../redux/controller';
import { useDispatchRoot } from '../../redux/store';
import './addWarehouseModal.css';
interface MyProps {
    isOpenModal: boolean;
    toggleAddWarehouseModal: () => void;
}
const AddWarehouseModel = (props: MyProps) => {

    const dispatch = useDispatchRoot()

    const [warehouseName, setWarehouseName] = useState('')
    const [warehouseAddress, setWarehouseAddress] = useState('')
    const [warehouseNote, setWarehouseNote] = useState('')
    const addWarehouse = (data: any) => {
        let userId = sessionStorage.getItem('userId')?.toString()
        if (userId) {
            userId = userId.slice(1);
            userId = userId.slice(0, userId.length - 1);
            const param: AddWarehouseRequest = {
                name: warehouseName,
                address: warehouseAddress,
                userId: userId ? userId : '',
                // note: data.note,
            }

            console.log(param);
            dispatch(addWarehouseByUserIdRequest(param))
            props.toggleAddWarehouseModal()
        }
    }


    return (
        <Modal
            className='add-camera-modal'
            title="Tạo kho mới"
            open={props.isOpenModal}
            onOk={props.toggleAddWarehouseModal}
            onCancel={props.toggleAddWarehouseModal}
            okText='Tạo mới'
            cancelText='Hủy bỏ'
        >
            <Form
                name="normal_login"
                className="edit-warehouse-form"
                initialValues={{ remember: true }}
                onFinish={addWarehouse}
                layout='vertical'
            >
                <Form.Item
                    name="name"
                    label={<div>Tên kho <strong>*</strong></div>}
                    rules={[{ required: true, message: 'Vui lòng nhập tên kho' }]}
                >
                    <Input
                        onChange={(e) => { setWarehouseName(e.target.value) }}
                        className='form-input' placeholder="Nhập tên kho"
                    />
                </Form.Item>
                <Form.Item
                    name="address"
                    label={<div>Địa chỉ <strong>*</strong></div>}
                    rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                >
                    <Input
                        onChange={(e) => { setWarehouseAddress(e.target.value) }}
                        className='form-input' placeholder="Nhập địa chỉ"
                    />
                </Form.Item>
                {/* <div>
                    <span className='form-span-text'>Mô tả</span>
                    <Form.Item
                        name="note"
                    >
                        <Input className='form-input' placeholder="Nhập mô tả" onChange={(e) => {
                            setWarehouseNote(e.target.value)
                        }} />
                    </Form.Item>
                </div> */}
            </Form>
        </Modal>
    )
}

export default AddWarehouseModel