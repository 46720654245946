import React from 'react';
import './policiesterms.scss';

const PoliciesTerms: React.FC = () => {
    return (
        <div className='policies-terms-page'>
            <h1>Điều khoản và Chính sách</h1>
            <section>
                <h2>1. Giới thiệu</h2>
                <p>Chào mừng bạn đến với AI Fruit - phần mềm giám sát chất lượng nông sản bằng AI. Bằng việc sử dụng dịch vụ của chúng tôi, bạn đồng ý với các điều khoản và chính sách sau đây.</p>
            </section>
            <section>
                <h2>2. Sử dụng dịch vụ</h2>
                <p>AI Fruit cung cấp giải pháp tự động giám sát chất lượng nông sản. Bạn có thể sử dụng dịch vụ này từ bất kỳ đâu, nhưng phải tuân thủ các quy định về bản quyền và sử dụng hợp pháp.</p>
            </section>
            <section>
                <h2>3. Quyền riêng tư và bảo mật</h2>
                <p>Chúng tôi cam kết bảo vệ thông tin cá nhân và dữ liệu của bạn. Thông tin chi tiết về cách chúng tôi thu thập, sử dụng và bảo vệ dữ liệu được nêu trong Chính sách Bảo mật của chúng tôi.</p>
            </section>
            <section>
                <h2>4. Trách nhiệm của người dùng</h2>
                <p>Bạn chịu trách nhiệm về việc sử dụng dịch vụ của chúng tôi và đảm bảo rằng việc sử dụng đó tuân thủ pháp luật và không gây hại cho người khác.</p>
            </section>
            <section>
                <h2>5. Thay đổi điều khoản</h2>
                <p>Chúng tôi có thể cập nhật các điều khoản và chính sách này theo thời gian. Bạn sẽ được thông báo về bất kỳ thay đổi quan trọng nào.</p>
            </section>
            <section>
                <h2>6. Liên hệ</h2>
                <p>Nếu bạn có bất kỳ câu hỏi nào về Điều khoản và Chính sách của chúng tôi, vui lòng liên hệ với chúng tôi qua email: vanthu@cretech.vast.vn</p>
            </section>
        </div>
    );
};

export default PoliciesTerms;
