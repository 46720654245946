import React, { FC, useEffect, useState } from 'react';

// import DoughnutChart from '../chart/DoughnutChart';

// import farm from '../../../public/assets/profile-Kho-farm.png';
// import pig from '../../../public/assets/profile-Kho-pig.png';
// import pigsty from '../../../public/assets/profile-Kho-pigsty.png';
// import user from '../../../public/assets/profile-Kho-user.png';
import { BarChart, Bar, Cell, PieChart, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Pie, Sector } from 'recharts';
import { FaUsers, FaWarehouse, FaAppleAlt, FaImage, FaApple } from "react-icons/fa";


// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import tongquan from '../../../public/assets/tong-quan.png';
import usersIcon from '../../../public/assets/user-icon.png';
import warehouseIcon from '../../../public/assets/warehouse-icon.png';
import aiModel from '../../../public/assets/ai-model.png';
import fruitType from '../../../public/assets/fruit-type.png';
import './dashboard.scss';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { Sidebar, navMenuAdmin } from '../HeaderAdmin';
import IdentityApi from '../../../api/identity.api';
import LineChart from '../../../components/LineChart/LineChart';
import { CFG_BASE_URL } from '../../../constants/config';
import { Select } from 'antd';
import TableComponent from '../../../components/Table/Table';
const api = IdentityApi.host

const columnsWarehouse = [
    {
        title: 'Tên người dùng',
        dataIndex: 'nameUser',
    },
    {
        title: 'Email người dùng',
        dataIndex: 'email',
    },
    {
        title: 'Tên trang trại',
        dataIndex: 'nameFarm',
    },
    {
        title: 'Số lượng kho',
        dataIndex: 'numWarehouse',
    },
]

const Dashboard: FC = () => {

    const [dataAdmin, setDataAdmin] = useState<any>();
    const [objectData, setObjectData] = useState<any[]>([]);
    const history = useNavigate();
    const [lstUser, setLstUser] = useState<any[]>([]);
    const [yearSelect, setYearSelect] = useState<any>(new Date().getFullYear());
    const [lstWarehouse, setLstWarehouse] = useState<any[]>([]);
    const [lstWarehouseOfUser, setLstWarehouseOfUser] = useState<any[]>([]);
    const dataBartChart = [
        {
            name: 'Hợp tác xã xoài Tân Thuận Tây',
            'Xoài': 3192,
            'Thanh long': 0,
        },
        {
            name: 'Nông trại Thanh Long hộ gia đình anh Thanh',
            'Xoài': 0,
            'Thanh long': 1398,
        },

    ];

    const dataPieChart = [
        { name: 'Thanh long', value: 1398 },
        { name: 'Xoài', value: 3192 },
    ];

    const COLORS = ['#6366F1', '#6365f180'];
    const RADIAN = Math.PI / 180;

    useEffect(() => {
        const role = sessionStorage.getItem('role')
        if (role !== '"ADMIN"') {
            history('/404-not-found');
            return;
        }
        getAllDataAdmin();
        getAllUser();
        // getAllWarehouse();
    }, []);

    useEffect(() => {
        setData();
    }, [dataAdmin]);

    useEffect(() => {
        getAllWarehouse();
    }, [lstUser]);

    useEffect(() => {
        console.log(lstWarehouse);
        if (lstWarehouse.length === 0) return;
        const tmp = lstUser.map((user: any) => {
            const numWarehouse = lstWarehouse.filter((warehouse: any) => warehouse.userId === user.id).length;
            return {
                nameUser: user.name,
                email: user.email,
                nameFarm: user.farmName ? user.farmName : 'Chưa có tên trang trại',
                numWarehouse: numWarehouse
            }
        });

        // Sắp xếp theo số lượng kho giảm dần
        tmp.sort((a: any, b: any) => b.numWarehouse - a.numWarehouse);

        // const tmp1 = lstUser.map((user: any) => {
        //     const numWarehouse = lstWarehouse.filter((warehouse: any) => warehouse.userId === user.id).length;
        //     return {
        //         nameUser: user.name,
        //         email: user.email,
        //         nameFarm: user.farmName ? user.farmName : 'Chưa có tên trang trại',
        //         numWarehouse: numWarehouse
        //     }
        // });

        // const lst = tmp.concat(tmp1);

        setLstWarehouseOfUser(tmp);

    }, [lstWarehouse]);

    useEffect(() => {
        console.log(lstWarehouseOfUser);
    }, [lstWarehouseOfUser]);

    const getAllUser = async () => {
        await axios.get(`${CFG_BASE_URL}/admin/users`)
            .then((res) => {
                console.log(res.data.data);
                setLstUser(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getAllWarehouse = async () => {
        if (lstUser.length === 0) return;

        lstUser.map((user: any) => {
            axios.get(`${CFG_BASE_URL}/admin/warehouseOfUser?userId=${user.id}`)
                .then((res) => {
                    console.log(res.data.data);

                    setLstWarehouse((prev: any) => [...prev, ...res.data.data]);
                })
                .catch((err) => {
                    console.log(err);
                })
        })

    }

    const getAllDataAdmin = async () => {
        axios.get(`${api}/admin`)
            .then((res) => {
                setDataAdmin(res.data.data);
            })
    }

    const setData = () => {
        if (!dataAdmin) return;
        const tmp = [
            {
                name: 'Người dùng',
                number: dataAdmin?.users,
                // miniNoti: dataAdmin?.ascendUser > 0 ? `Tăng ${dataAdmin?.ascendUser}% so với tháng trước` : `Giảm ${dataAdmin?.ascendUser}% so với tháng trước`,
                className: 'overview-body-item-1',
                // picture: user,
                link: '/user-manage',
                icon: <img src={usersIcon} alt="Logo" className="logo-image" width={50} height={50} />,
            },
            {
                name: 'Tổng số kho',
                number: dataAdmin?.warehouses,
                // miniNoti: dataAdmin?.ascendFarm > 0 ? `Tăng ${dataAdmin?.ascendFarm}% so với tháng trước` : `Giảm ${dataAdmin?.ascendFarm}% so với tháng trước`,
                className: 'overview-body-item-2',
                // picture: farm,
                link: '/admin',
                icon: <img src={warehouseIcon} alt="Logo" className="logo-image" width={50} height={50} />,
            },
            {
                name: 'Loại quả',
                number: dataAdmin?.numFruit,
                // miniNoti: dataAdmin?.ascendPig > 0 ? `Tăng ${dataAdmin?.ascendPig}% so với tháng trước` : `Giảm ${dataAdmin?.ascendPig}% so với tháng trước`,
                className: 'overview-body-item-4',
                // picture: farm,
                // link: '/admin',
                icon: <img src={fruitType} alt="Logo" className="logo-image" width={50} height={50} />,
            },

            {
                name: 'CSDL ảnh',
                number: dataAdmin?.images,
                // miniNoti: dataAdmin?.ascendPig > 0 ? `Tăng ${dataAdmin?.ascendPig}% so với tháng trước` : `Giảm ${dataAdmin?.ascendPig}% so với tháng trước`,
                className: 'overview-body-item-6',
                // picture: user,
                link: '/model-manage',
                icon: <img src={aiModel} alt="Logo" className="logo-image" width={50} height={50} />,
            }
        ]
        setObjectData(tmp);
    }

    const renderCustomizedLabel: any = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const handleChangeLink = (item: any) => () => {
        if (item.link) {
            history(item.link);
        }
    }

    return (
        <div className='container-dashboard'>
            <div className='overview-body'>
                {objectData.map((item, index) => {
                    return (
                        <div className={'overview-body-item'} key={item.name} onClick={handleChangeLink(item)} >
                            <div className='overview-body-text'>
                                <div className='overview-body-text-large' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className='icon-text-large'>
                                        {
                                            index === 0 && <FaUsers />
                                        }
                                        {
                                            index === 1 && <FaWarehouse />
                                        }
                                        {
                                            index === 2 && <FaAppleAlt />
                                        }
                                        {
                                            index === 3 && <FaImage />
                                        }
                                    </div>
                                    <div className='number-text-large'>{item.number}</div>

                                </div>
                                <div className='overview-body-text-small'>{item.name}</div>

                                {/* <div className='overview-body-text-small'>
                                    {item.miniNoti.includes('Tăng')
                                        ? <ArrowUpOutlined style={{ paddingRight: '10px' }} />
                                        : <ArrowDownOutlined style={{ paddingRight: '10px' }} />}
                                    {item.miniNoti}
                                </div> */}
                            </div>
                            {/* <img src={item.picture} alt='picture' style={{ maxWidth: '40%' }} /> */}
                        </div>
                    );
                })}
            </div>
            <div className='overview-chart'>
                <div className='bar-chart'>
                    <div className='label-chart'>
                        <div>
                            Thống kê số lượng người dùng theo thời gian
                        </div>
                        <div>
                            {/* Tạo ra select theo năm */}
                            <Select defaultValue={yearSelect} onChange={(value) =>
                                setYearSelect(value)
                            }>
                                <Select.Option value={new Date().getFullYear()}>{new Date().getFullYear()}</Select.Option>
                                <Select.Option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</Select.Option>
                                <Select.Option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</Select.Option>
                                <Select.Option value={new Date().getFullYear() - 3}>{new Date().getFullYear() - 3}</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <LineChart lstUser={lstUser} yearSelect={yearSelect} />

                </div>
                <div className='pie-chart'>
                    <div className='label-chart'>
                        Thống kê số lượng kho được hệ thống giám sát
                    </div>
                    <TableComponent data={lstWarehouseOfUser} columns={columnsWarehouse} pageSize={10} />
                </div>
            </div>

            {/* <div style={{ display: 'flex' }}>
                <DoughnutChart headerText='TỔNG USER' conclusionText='Tăng 8% so với tháng trước' centerText='568' subText='User' />
                <DoughnutChart headerText='TỔNG KHO' conclusionText='Tăng 5% so với tháng trước' centerText='18' subText='Trang trại' />
            </div> */}
        </div>
    );
};

export default Dashboard;
