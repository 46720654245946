import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import './multibarchar.scss';
import axios from 'axios';
import { CFG_BASE_URL } from '../../constants/config';
interface MultiBarChartProps {
    lstWarehouse: any
}

const MultiBarChart = (props: MultiBarChartProps) => {
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [data, setData] = useState<any>();
    const [warehouses, setWarehouses] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        getDataChart();
    }, []);

    const getDataChart = async () => {
        setIsLoading(true);
        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
        }
        const header = {
            'Authorization': 'Bearer ' + token
        }
        const result = axios.get(`${CFG_BASE_URL}/users/getDataChartDashboard`, { headers: header }).then(res => {
            let series: any = [
                {
                    name: 'Tốt - Xoài',
                    group: 'mango',
                    data: []
                },
                {
                    name: 'Tốt - Thanh long',
                    group: 'dragonfruit',
                    data: []
                },
                {
                    name: 'Mới hỏng - Xoài',
                    group: 'mango',
                    data: []
                },
                {
                    name: 'Mới hỏng - Thanh long',
                    group: 'dragonfruit',
                    data: []
                },
                {
                    name: 'Đã hỏng - Xoài',
                    group: 'mango',
                    data: []
                },
                {
                    name: 'Đã hỏng - Thanh long',
                    group: 'dragonfruit',
                    data: []
                },

            ]
            console.log(res.data);

            setWarehouses(res.data.data);
            for (let i = 0; i < res.data.data.length; i++) {
                const element: any = res.data.data[i];
                series[0]?.data?.push(element.mango.totalStatus0 || 0);
                series[1]?.data?.push(element.dragonfruit.totalStatus0 || 0);
                series[2]?.data?.push(element.mango.totalStatus1 || 0);
                series[3]?.data?.push(element.dragonfruit.totalStatus1 || 0);
                series[4]?.data?.push(element.mango.totalStatus2 || 0);
                series[5]?.data?.push(element.dragonfruit.totalStatus2 || 0);
            }
            console.log(series);
            
            setData(series);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        console.log(windowSize);

    }, [windowSize]);

    const series = [
        {
            name: 'Tốt - Xoài',
            group: 'mango',
            data: [10, 20]
        },
        {
            name: 'Tốt - Thanh long',
            group: 'dragonfruit',
            data: [20, 30]
        },
        {
            name: 'Mới hỏng - Xoài',
            group: 'mango',
            data: [25, 35]
        },
        {
            name: 'Mới hỏng - Thanh long',
            group: 'dragonfruit',
            data: [15, 25]
        },
        {
            name: 'Đã hỏng - Xoài',
            group: 'mango',
            data: [20, 25]
        },
        {
            name: 'Đã hỏng - Thanh long',
            group: 'dragonfruit',
            data: [10, 30]
        },

    ]

    const options: any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }
        ],
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    // total: {
                    //     enabled: true,
                    //     style: {
                    //         fontSize: '13px',
                    //         fontWeight: 900
                    //     }
                    // }
                }
            }
        },
        stroke: {
            width: .5,
            colors: ['#fff']
        },
        dataLabels: {
            // formatter: (val: any) => {
            //     return val
            // }
        },
        xaxis: {
            categories: warehouses.map((item: any) => item.warehouseName),
            style: {
                width: '100px',
                fontSize: 10,
                fontWeight: 900
            },
            labels: {
                // formatter: (val: any) => {
                //     return val
                // }
            }
        },
        fill: {
            opacity: 1,
        },
        colors: ['#1A5319', '#810075', '#508D4E', '#C238B5', '#80AF81', '#E1ADDC',],
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        }
    }

    return <div>
        <div >
            { !isLoading && <ReactApexChart options={options} series={data} type="bar" height={350} width={(windowSize[0] / 2) - 100} />}
        </div>
        <div id="html-dist"></div>
    </div>;
};

export default MultiBarChart;
