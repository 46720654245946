/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import './cameraManager.scss';
import PageHeader from '../common/page-header/page-header';
// import farmService from '@services/farm';
// import { apiPen } from '@api/pen';
// import { IPenData } from '@type/pen';
import CImageLoading from './CImageLoading';
import StreamAPI from '../../api/camera/streaming.api';
import { Button, Form, Input, Modal, Modal as ModalAntd, Select } from 'antd';
import ModalHeader from '../common/modal/modal-header';
import CameraAPI from '../../api/camera/camera.api';
import { Camera } from '../../common/models/camera-model';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
// import { fetchlstCameraRequest } from '../../redux/controller/camera.slice';
import { Link, useNavigate } from 'react-router-dom';
import { Card, notification, Tooltip } from 'antd';
import Meta from 'antd/es/card/Meta';
import './video.scss'
import axios from 'axios';
import { CFG_BASE_URL, WEBRTC_STREAMING } from '../../constants/config';
import { setLstCameraRequest } from '../../redux/controller/camera.slice';
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete, AiOutlineCamera } from 'react-icons/ai';
import { useForm } from 'antd/es/form/Form';
import { apiCamera } from '../../api/camera';

// import PenEmptyCreat from '../farm/pen-empty/pen-empty-create';
// import { apiStreamingVideo } from '@api/streaming-video'
// import CamDetail from '../farm/pen/cam-detail'; // xem Detail cam

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '80%'
        },
        videoList: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '10px',
            justifyContent: 'start',
            flexWrap: 'wrap'
        },
        videoItem: {
            marginLeft: '10px',
            marginRight: '10px',
            width: '420px'
        },
        videoItemFullScreen: {
            marginLeft: '10px',
            marginRight: '10px'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContentCreate: {
            backgroundColor: 'white',
            width: '72%',
        },
        modalContentAlert: {
            width: '30%',
        },
        btnSave: {
            backgroundColor: '#FF5A80',
            color: '#FFFFFF',
            fontSize: '15px',
            textTransform: 'none',

            paddingLeft: '31px',
            paddingRight: '31px',
            marginLeft: '70%',
            marginTop: '1%',
            '&:hover': {
                backgroundColor: "#FF5A80",
                color: '#FFFFFF',
            },
        },
        btnDisconnected: {
            backgroundColor: 'gray',
            color: '#FFFFFF',
            fontSize: '15px',
            textTransform: 'none',
            paddingLeft: '31px',
            paddingRight: '31px',
            marginLeft: '70%',
            marginTop: '1%',
        },
        NoCam: {
            backgroundColor: '#6B7280',
            display: 'flex',
            height: '232.88px',
            padding: '3px',
            marginTop: '3px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        ContentNoCam: {
            color: '#FFFFFF',
            fontSize: '16px',
            fontFamily: 'Inter',
            fontStyle: 'normal'
        }
    }),
);

enum TabPaneKey {
    PenCreate = 'penCreate',
    CamSettings = 'camSettings',
    AddPig = 'addPig'
}
type Props = {
    warehouse: any;
}
const Video: FC<Props> = ({ warehouse }: Props) => {
    const classes = useStyles();
    const [pens, setPens] = useState<Array<Camera[] | any>>([]);
    const [penEmpty, setPenEmpty] = useState<Array<any>>([]); // Luu danh sach cac chuong trong

    // const currentFarm = farmService.getCurrentFarm();
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [isFullScreenEmpty, setIsFullScreenEmpty] = useState<boolean>(false);

    const [openCU, setOpenCU] = useState(false);

    const [selectedPen, updateSelectedPen] = useState<any>(); //Luu chuong duoc chon

    const [lstStreamingRtcCamera, setLstStreamingRtcCamera] = useState<any>();

    const [lstPenEmptyCamera, setLstPenEmptyCamera] = useState<any>();

    const [isConnected, setIsConnected] = useState<boolean>(false);

    const [isAddPig, setIsAddPig] = useState<boolean>(false);

    const dispatch = useDispatchRoot();

    // const { lstCamera, errorCode } = useSelectorRoot((state) => state.camera);

    const [uuId, updateUuid] = useState<string>(uuidv4())

    const [isUpdate, setIsUpdate] = useState(false);

    const [lstCamera, setLstCamera] = useState<any>([]);

    const [openEditCamera, setOpenEditCamera] = useState<boolean>(false);

    const [selectedCamera , setSelectedCamera] = useState<any>();

    const [formEdit] = useForm();

    const navigate = useNavigate();


    // const [warehouseId, setWarehouseId] = useState<string>();

    useEffect(() => {
        console.log(warehouse);
        // 
        // setWarehouseId(warehouse.id);

    }, [warehouse])

    useEffect(() => {
        // const warehouseId = sessionStorage.getItem('selectedWarehouseId')
        if (warehouse) {
            getLstCamera()
            // dispatch(fetchlstCameraRequest(warehouse.id));
        }
    }, [warehouse])

    useEffect(() => {
        // Lay danh sach camera theo id nha kho
        initRtcCamera() // Do cau truc cua lon la 1 trai co nhieu chuong, 1 chuong nhieu camera. con fruit la 1 trai nhieu cam, 1 cam 1 dong 
    }, [lstCamera])

    const getLstCamera = async () => {
        await axios.get(`${CFG_BASE_URL}/camera/warehouse?warehouseId=${warehouse.id}`)
            .then((res) => {
                console.log(res.data.data);
                setLstCamera(res.data.data);

                dispatch(setLstCameraRequest(res.data.data));
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const renderDescription = (camera: any) => (
        <div>
            {'Mô tả:   ' + camera.note}
            <br />
            {'Link Camera: '}
            <a href={camera.link} target="_blank" rel="noopener noreferrer">
                {camera.link}
            </a>
        </div>
    );

    const handleDeleteCamera = (camera: any) => {
        console.log(camera);

        ModalAntd.confirm({
            title: 'Xác nhận xóa camera',
            content: 'Bạn có chắc chắn muốn xóa camera này?',
            okText: 'Xóa',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk: async () => {
                await axios.delete(`${CFG_BASE_URL}/camera/${camera.id}`)
                    .then(async (res) => {
                        console.log(res);
                        getLstCamera();
                        notification.success({
                            message: 'Xóa camera thành công'
                        })

                        await axios.get(`${WEBRTC_STREAMING}/rtc/stop/${camera.id}`)
                            .then((res) => {
                                console.log(res);
                            })
                            .catch((err) => {
                                console.log(err);
                            })

                    })
                    .catch((err) => {
                        console.log(err);
                        notification.error({
                            message: 'Xóa camera thất bại'
                        })
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    }

    const handleClickEditCamera = (camera: any) => {
        console.log(camera);
        formEdit.setFieldsValue(camera);
        setOpenEditCamera(true);
        setSelectedCamera(camera);
    }


    const initRtcCamera = () => { // Khoi tao camera sau khi call API tat ca cac chuong 
        // const warehouseId = "63b542fe87dd5b2cfc063f5b"

        console.log(lstCamera)
        setLstStreamingRtcCamera(
            <>
                {
                    (lstCamera && lstCamera.length > 0) &&
                    <div className={'container'}>
                        <div className='title-container'>
                            Tên kho:  {warehouse.name}
                        </div>
                        <div className='lst-item'>
                            {
                                lstCamera.map((camera: any, index: any) => {
                                    return (
                                        <div>
                                            {/*video*/}
                                            <Card
                                                className="camera-manager-card"
                                                hoverable
                                                cover={
                                                    <CImageLoading
                                                        src={StreamAPI.getPreviewStream(camera.id)}
                                                        className="h-56"
                                                        uuid={uuidv4()}
                                                        isFullScreen={isFullScreen}
                                                        setIsFullScreen={setIsFullScreen}
                                                        enableRTC={false}
                                                        onClick={() => {
                                                            navigate(`/camera_detail/${camera.id}`)
                                                            sessionStorage.setItem('selectedWarehouseId', camera.warehouseId)
                                                            sessionStorage.setItem('selectedFruitType', warehouse.fruitType.name)
                                                            sessionStorage.setItem('selectedFruitOrigin', warehouse.origin)
                                                        }}
                                                        // pen={pen}
                                                        // updateSelectedPen={updateSelectedPen}
                                                        // onClick={() =>
                                                        //     handleShowExpandMenu(true, item)
                                                        // }
                                                        // isHiddenCamera={pen?.cameras[0]?.isHiddenCamera ? true : false}
                                                        idCamera={camera.id} // Hien ra danh sach cac chuong, va chi hien thi 1 camera cua 1 chuong
                                                    />
                                                }

                                            >
                                                <div
                                                    className='camera-item'
                                                    onClick={() => {
                                                        navigate(`/camera_detail/${camera.id}`)
                                                        sessionStorage.setItem('selectedWarehouseId', camera.warehouseId)
                                                        sessionStorage.setItem('selectedFruitType', warehouse.fruitType.name)
                                                        sessionStorage.setItem('selectedFruitOrigin', warehouse.origin)
                                                    }}
                                                >
                                                    <div className='camera-title'><strong>Tên camera: {camera?.name}</strong> </div>
                                                    <div className="camera-description"><strong>Mô tả:</strong> {camera.note}</div>
                                                    <div className='camera-bakets'><strong>Số sọt phát hiện:</strong> {camera.numOfCrate || 0} sọt</div>
                                                    <div className='camera-fruits'><strong>Số quả (ước lượng):</strong> {warehouse.fruitsPerCrate ? warehouse.fruitsPerCrate * camera.numOfCrate : 0} quả</div>
                                                    <div className='camera-fruits'><strong>Khối lượng (ước lượng):</strong> {warehouse.weightPerCrate ? warehouse.weightPerCrate * camera.numOfCrate : 0} kg</div>
                                                </div>
                                                <Tooltip title='Xóa camera'>
                                                    <div className='button-delete' onClick={() => handleDeleteCamera(camera)}>
                                                        {/* Thêm button xóa cam */}
                                                        <AiOutlineDelete />
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title='Sửa camera'>
                                                    <div className='button-edit' onClick={() => handleClickEditCamera(camera)}>
                                                        {/* Thêm button xóa cam */}
                                                        <AiOutlineEdit />
                                                    </div>
                                                </Tooltip>

                                            </Card>

                                            {/* <div className={`cursor-pointer ${classes.videoItem}`}>
                                        <p>{camera?.name} </p>

                                        <CImageLoading
                                            src={StreamAPI.getStream(camera.id)}
                                            className="h-56"
                                            uuid={uuidv4()}
                                            isFullScreen={isFullScreen}
                                            setIsFullScreen={setIsFullScreen}
                                            // pen={pen}
                                            // updateSelectedPen={updateSelectedPen}
                                            // onClick={() =>
                                            //     handleShowExpandMenu(true, item)
                                            // }
                                            // isHiddenCamera={pen?.cameras[0]?.isHiddenCamera ? true : false}
                                            idCamera={camera.id} // Hien ra danh sach cac chuong, va chi hien thi 1 camera cua 1 chuong
                                            
                                        />
                                    </div> */}
                                            {/*end*/}

                                        </div>
                                    )
                                })
                            }
                        </div>

                        
                    </div>
                }
            </>
        )
    }

    const handleCloseCU = () => {
        setIsFullScreen(false);
    };

    const handleCloseCUEmpty = () => {
        setIsFullScreenEmpty(false);
    };

    const handleCloseCUWithoutCancelStream = () => {
        setOpenCU(false);
    }

    const handleCloseCUAddPig = () => {
        setOpenCU(false);
    };

    // const initRtcCameraEmpty = (pensItem: IPenData[] | any) => { // Khoi tao camera cho chuong trong sau khi call API
    //     setLstPenEmptyCamera(
    //         <div className={classes.videoList}>
    //             {
    //                 pensItem && pensItem.map((pen: IPenData | any, index: any) => {
    //                     return (
    //                         <>
    //                             {/*video*/}

    //                             { (pen?.cameras.length > 0 ) ?
    //                             <div className={`cursor-pointer ${classes.videoItem}`}>
    //                                 <p>{pen?.name} - {pen?.cameras[0].camName}</p>
    //                                 <CImageLoading
    //                                     src={StreamAPI.getStream(pen.id)}
    //                                     className="h-56"
    //                                     uuid={uuidv4()}
    //                                     isEmptyPen = {true}
    //                                     isFullScreen={isFullScreen}
    //                                     setIsFullScreen={setIsFullScreenEmpty}
    //                                     pen={pen}
    //                                     updateSelectedPen={updateSelectedPen}
    //                                     // onClick={() =>
    //                                     //     handleShowExpandMenu(true, item)
    //                                     // }
    //                                     isHiddenCamera={pen?.cameras[0]?.isHiddenCamera ? true : false}
    //                                     idCamera={pen.cameras[0]?.id}
    //                                 />
    //                                 <Button
    //                                     onClick={() => handleOpenCU(true, pen)}
    //                                     variant="contained"
    //                                     className={classes.btnSave}
    //                                     // disabled={ isConnected ? false : true}
    //                                     >
    //                                     Đánh số
    //                                 </Button>
    //                             </div>
    //                             : <div className={`cursor-pointer ${classes.videoItem}`}>
    //                                 <p>{pen?.name}</p>
    //                                 <div className={classes.NoCam}>
    //                                     <div className={classes.ContentNoCam}>
    //                                         CHUỒNG CHƯA CÓ CAMERA
    //                                     </div>
    //                                 </div>

    //                             </div>
    //                             }
    //                             {/*end*/}
    //                         </>
    //                     )
    //                 })
    //             }
    //         </div>
    //     )
    // }


    // useEffect(() => {
    //     // setLstStreamingRtcCamera(null)
    //     // setLstPenEmptyCamera(null)
    //     getAllPenEmptys();
    //     getAllPens();
    // }, [currentFarm])


    // const terminateStreaming = () => {
    //     const payload = [uuId]
    //     pens.map( item => {
    //         apiStreamingVideo.terminateRTCStreaming(item.id, payload)
    //             .then((res) => {
    //                 console.log(res);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     })
    //     penEmpty.map( item => {
    //         apiStreamingVideo.terminateRTCStreaming(item.id, payload)
    //             .then((res) => {
    //                 console.log(res);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     })
    // }

    // const handleOpenCU = (penSelected?: IPenData) => {
    //     setOpenCU(true);
    // };



    // const getAllPens = () => {
    //     const warehouseId = "63b542fe87dd5b2cfc063f5b"

    //     dispatch(fetchlstCameraRequest(warehouseId));

    //     initRtcCamera(res.data);
    //     // CameraAPI.getlstCameraByIdWarehouse(params) //Doi sang call api Camera
    //     //     .then((res: any) => {
    //     //         setPens(res.data);
    //     //         initRtcCamera(res.data);
    //     //     })
    //     //     .catch((err: any) => {
    //     //         console.log(err);
    //     //     })
    // };



    // const [penSelected, setPenSelected] = useState<IPenData | any>(); //Luu chuong da duoc chon 



    // const handleOpenCU = (isUpdate: boolean, penSelected?: IPenData) => {  // Danh so trong modal khoi tao camera cho chuong trong
    //     setOpenCU(true);
    //     setPenSelected(penSelected);
    //     setIsUpdate(isUpdate);
    // };



    const onFinishEditCamera = async (values: any) => {
        console.log(values);
        let payload : any = {}
        values.name && (payload['name'] = values.name)
        values.link && (payload['link'] = values.link) 
        values.note && (payload['note'] = values.note)
        try {
            console.log(JSON.stringify(payload));
            
            axios.put(`${CFG_BASE_URL}/camera/${selectedCamera.id}`, payload).then(async (res) => {
                console.log(res.data.data);
                notification.success({
                    message: 'Sửa camera thành công',
                    type: 'success'
                })

                getLstCamera();
                setOpenEditCamera(false);
            }).catch((err) => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
            
        }
    }



    return (
        <div >
            {/* <PageHeader
                pageTile={`${pens.length} Chuồng nuôi`}
                isButton={false}
            /> */}
            {/* <div className={classes.videoList}>
                {
                    pens.map((pen, index) => {
                        return (
                        return (
                            <>
                                <div className={classes.videoItem}>
                                    <VideoItem uuId={uuidv4()} camId={pen.cameras[0]?.id} width='300' height='300' />
                                </div>
                            </>
                        )
                    })
                }
            </div> */}

            {/* Danh sach camera */}
            {lstStreamingRtcCamera}

            {/* <PageHeader
                pageTile={`${penEmpty.length} Chuồng trống`}
                isButton={false}
            /> */}
            {/* {lstPenEmptyCamera} */}
            <Modal
                open={isFullScreen}
                // onClose={handleCloseCU}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}>
                <div className={classes.modalContentCreate}>
                    {/* Modal xem chi tiet camera */}
                    <ModalHeader title={`${selectedPen?.name}`} closeButton={handleCloseCU} />
                    <div className={`cursor-pointer ${classes.videoItemFullScreen}`}>
                        {/* <CamDetail camSelectedData={selectedPen?.cameras[0]} isBackOnClick={false} isEmptyPen={false} /> */}
                        {/* <CImageLoading
                            src={StreamAPI.getStream(selectedPen?.id)}
                            className="h-60"
                            uuid={uuidv4()}
                            // onClick={() =>
                            //     handleShowExpandMenu(true, item)
                            // }
                            isHiddenCamera={selectedPen?.cameras[0]?.isHiddenCamera ? true : false}
                            idCamera={selectedPen?.cameras[0]?.id}
                        /> */}
                    </div>
                </div>
            </Modal>
            <Modal
                open={isFullScreenEmpty}
                // onClose={handleCloseCU}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}>
                <div className={classes.modalContentCreate}>
                    <ModalHeader title={`${selectedPen?.name}`} closeButton={handleCloseCUEmpty} />
                    <div className={`cursor-pointer ${classes.videoItemFullScreen}`}>
                        <CImageLoading
                            src={StreamAPI.getStream(selectedPen?.id)}
                            className="h-60"
                            isEmptyPen={true}
                            uuid={uuidv4()}
                            enableRTC={true}
                            // onClick={() =>
                            //     handleShowExpandMenu(true, item)
                            // }
                            isHiddenCamera={selectedPen?.cameras[0]?.isHiddenCamera ? true : false}
                            idCamera={selectedPen?.cameras[0]?.id}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                visible={openEditCamera}
                title='Sửa camera'
                className='modal-add-pack'
                onCancel={() => setOpenEditCamera(false)}

            >
                <Form
                    form={formEdit}
                    layout='vertical'
                    name='form_add_camera'
                    onFinish={onFinishEditCamera}
                >
                    <Form.Item
                        label='Tên camera'
                        name='name'
                        rules={[{ required: true, message: 'Vui lòng nhập tên camera' }]}
                    >
                        <Input placeholder='Nhập tên camera' />
                    </Form.Item>
                    <Form.Item
                        label='Link camera'
                        name='link'
                        rules={[{ required: true, message: 'Vui lòng nhập link camera' }]}
                    >
                        <Input placeholder='Nhập link camera' />
                    </Form.Item>

                    <Form.Item
                        label='Mô tả'
                        name='note'
                        rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}
                    >
                        <Input.TextArea placeholder='Nhập mô tả' rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px'
                        }}>
                            <Button type='default' onClick={
                                () => setOpenEditCamera(false)
                            }>Hủy</Button>
                            <Button type='primary' htmlType='submit'>Sửa</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>


            {/* Cap nhat chuong */}
            {/* <Modal
                    open={openCU} 
                    onClose={handleCloseCUAddPig}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}>
                    <div className={classes.modalContentCreate}>
                        <ModalHeader title='Cập nhật chuồng' closeButton={handleCloseCUAddPig} />
                        <div style={{ zIndex: 2000 }}>
                            <PenEmptyCreat
                                onClose={handleCloseCU}
                                onRefresh={getAllPenEmptys}
                                penData={penSelected}
                                isUpdate={isUpdate}
                                keyProp = {TabPaneKey.AddPig}
                                onCloseWithoutCancelStream={handleCloseCUWithoutCancelStream}
                                setIsAddPigPar={setIsAddPig}
                            />
                        </div>
                    </div>
            </Modal> */}
        </div>
    );
}

export default Video;
