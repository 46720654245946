import React, { FC, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatchRoot, useSelectorRoot } from '../../../redux/store';
import { Avatar, Space, Table, Tooltip, Select, Spin, notification, Modal, Button } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { LockOutlined, DeleteOutlined, UnlockOutlined, DownloadOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import user from '../../../services/user';
import * as XLSX from 'xlsx';
import './style.userManage.scss';
import IdentityApi from "../../../api/identity.api";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    // {
    //     title: 'Avatar',
    //     dataIndex: 'avatar',
    // },
    {
        title: 'Họ và tên',
        dataIndex: 'name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Số điện thoại',
        dataIndex: 'phone',
    },
    {
        title: 'Địa chỉ',
        dataIndex: 'address',
    },
    {
        title: 'Ngày tạo tài khoản',
        dataIndex: 'createTime',
    },

    // {
    //   title: 'Thời gian tạo',
    //   dataIndex: 'date',
    // },
    // {
    //     title: 'Ngày cập nhật',
    //     dataIndex: 'updateDate',
    // },
    {
        title: 'Thao tác',
        dataIndex: 'operation',
    },
];
const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
};
const api = IdentityApi.host
const UserManage = () => {
    const [users, setUsers] = useState([]);
    const [responseBlock, setResponseBlock] = useState('')
    const [selectedUser, setSelectedUser] = useState(null);
    const [warehouseData, setWarehouseData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang 
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [filters, setFilters] = useState(''); // dùng để tìm kiếm
    const [isVisibleDeleteUser, setIsVisibleDeleteUser] = useState(false);
    const history = useNavigate();

    useEffect(() => {
        const role = sessionStorage.getItem('role')
        if (role !== '"ADMIN"') {
            history('/404-not-found');
            return;
        }
        getUser();
    }, []);

    useEffect(() => {
        console.log(users);

    }, [users]);

    const getUser = async () => {
        await axios.get(`${api}/admin/users`).then((response) => {
            let count = 0;
            console.log(response.data.data);

            setTimeout(() => {
                setUsers(
                    response.data.data.map((user: any) => {
                        count += 1;
                        const formattedDate = moment(user.createTime).format('HH:MM:SS DD/MM/YYYY ');
                        const createTime = moment(user.createTime).format('DD/MM/YYYY ');
                        const formattedUpdateDate = moment(user.updateTime).format('DD/MM/YYYY HH:mm:ss');
                        return {
                            key: count,             // cột STT, cột đầu tiên của bảng
                            id: user.id,
                            // avatar: <Avatar size={30}> {user.fullName[0].toUpperCase()}</Avatar>,
                            name: user.name,
                            phone: user.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3"),
                            // address: user.address ? user.address : 'Chưa cập nhật',
                            email: user.email,
                            createTime: createTime ? createTime : 'Chưa cập nhật',
                            address: user.address ? user.address : 'Chưa cập nhật',
                            // updateDate: formattedUpdateDate,
                            // activityStatus: user.active,
                            operation: (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 5
                                    }}
                                >
                                    <div className="text-link" onClick={() => handleClickPen(user.id)}>
                                        <Tooltip title="Xem trang trại">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 24 24"><path fill="black" fill-rule="evenodd" d="M11.217 3.553a1.75 1.75 0 0 1 1.566 0l7 3.5c.592.296.967.902.967 1.565V20a.75.75 0 0 1-1.5 0V8.618a.25.25 0 0 0-.138-.224l-7-3.5a.25.25 0 0 0-.224 0l-7 3.5a.25.25 0 0 0-.138.224V20a.75.75 0 0 1-1.5 0V8.618c0-.663.375-1.269.967-1.565zM6.25 12c0-.966.784-1.75 1.75-1.75h8c.966 0 1.75.784 1.75 1.75v7A1.75 1.75 0 0 1 16 20.75H8A1.75 1.75 0 0 1 6.25 19zM8 11.75a.25.25 0 0 0-.25.25v1.25h8.5V12a.25.25 0 0 0-.25-.25zm8.25 3h-8.5v1.5h8.5zm0 3h-8.5V19c0 .138.112.25.25.25h8a.25.25 0 0 0 .25-.25z" clip-rule="evenodd" /></svg>                  </Tooltip>
                                    </div>
                                    <Tooltip title={user.blocked ? 'Mở khóa' : 'Khóa'}>
                                        {user.blocked ? <LockOutlined onClick={() => handleBlockClick(user)} rev={undefined} /> : <UnlockOutlined onClick={() => handleBlockClick(user)} rev={undefined} />}
                                    </Tooltip>
                                </div>
                            ),
                        };
                    }),
                );
            }, 1000);
        }).catch(() => {
            console.log('error fetching data!!')
        })
    }

    const handleClickPen = (id: string) => {
        history(`/farm-manage/${id}`)
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }
    const handleBlockClick = async (user: any) => {
        if (user?.blocked) {
            await axios.get(`${api}/admin/blockUser?userId=${user.id}`).then((response) => {
                notification.success({
                    message: 'Thành công',
                    description: 'Mở khóa người dùng thành công',
                })
                getUser();
            }).catch(() => {
                notification.error({
                    message: 'Thất bại',
                    description: 'Mở khóa người dùng thất bại',
                })
            })
        }
        else {
            console.log(`${api}/admin/blockUser?userId=${user.id}`);
            await axios.get(`${api}/admin/blockUser?userId=${user.id}`).then((response) => {
                notification.success({
                    message: 'Thành công',
                    description: 'Khóa người dùng thành công',
                })
                getUser();
            }).catch(() => {
                notification.error({
                    message: 'Thất bại',
                    description: 'Khóa người dùng thất bại',
                })
            })
        }

    }
    const handleViewWarehouseClick = (userId: any) => {
        // Fetch the warehouse data for the selected user
        fetch(`${api}/admin/warehouseOfUser?userId=${userId}`)
            .then((response) => response.json())
            .then((data) => {
                setWarehouseData(data);
            })
            .catch((error) => {
                console.error('Error fetching warehouse data: ', error);
            });
    };

    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            setLoading(false);

        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: users.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = (value: string) => {
        // // Mở loading
        // setLoading(true);
        // // Đẩy lên đầu trang
        // setTimeout(() => {
        //     // Load dữ liệu
        //     fetchData(value);
        //     // Tắt loading
        //     setLoading(false);
        // }, 1000);
    };

    const sortData: any[] = filters.length === 0 ? users : users.filter((item: any) => item?.email?.toLowerCase().includes(filters.toLocaleLowerCase()));
    const exportToExcel = async () => {
        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };

        // Add title row
        const today = new Date();
        const formattedDate = `ngày ${today.getDate()} tháng ${today.getMonth() + 1} năm ${today.getFullYear()}`;

        // Add title row
        const titleRow = worksheet.addRow([`Danh sách người dùng (tính đến ${formattedDate})`]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:F1'); // Adjust the range based on the number of columns

        // Add an empty row to separate the title from the headers
        worksheet.addRow([]);

        // Add first row of headers (merged cells)
        const headerRow1 = worksheet.addRow(['', '', '', '', '', '']);
        worksheet.mergeCells('A2:A3'); // STT
        worksheet.mergeCells('B2:B3'); // Họ và tên
        worksheet.mergeCells('C2:C3'); // Email
        worksheet.mergeCells('D2:D3'); // Số điện thoại
        worksheet.mergeCells('E2:E3'); // Địa chỉ
        worksheet.mergeCells('F2:F3'); // Ngày tạo tài khoản

        // Add second row of headers
        const headerRow2 = worksheet.addRow([
            'STT',
            'Họ và tên',
            'Email',
            'Số điện thoại',
            'Địa chỉ',
            'Ngày tạo tài khoản'
        ]);

        headerRow1.font = { bold: true };
        headerRow2.font = { bold: true };

        // Add columns (keys only, no headers)
        worksheet.columns = [
            { key: 'key', width: 10 },
            { key: 'name', width: 30 },
            { key: 'email', width: 30 },
            { key: 'phone', width: 30 },
            { key: 'address', width: 30 },
            { key: 'createTime', width: 30 },
        ];

        // worksheet.getRow(1).font = { bold: true };

        // Add data
        sortData.forEach((item: any, index: number) => {
            worksheet.addRow({
                key: item.key,
                name: item.name,
                email: item.email,
                phone: item.phone,
                address: item.address,
                createTime: item.createTime,
            });
        });

        worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber === 1 || rowNumber === 2 || rowNumber === 3) return; // Skip the title and header rows
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Danh sách người dùng.xlsx');
    };

    return (
        <div className="userList-container">
            <div className="button-userList">
                <p className="userList-h2">Quản lý người dùng</p>
                <Search
                    className='search-userList'
                    placeholder="Tìm kiếm theo email"
                    onChange={(event) => setFilters(event.target.value)}
                    onSearch={onSearch}
                    enterButton
                    allowClear
                />
            </div>
            <Spin spinning={users.length === 0} delay={500} tip="Đang lấy dữ liệu...">
                <div className="table-userList">
                    <Table
                        bordered
                        dataSource={sortData}
                        columns={columns}
                        rowClassName={getRowClassName}
                        pagination={paginationConfig}
                    />
                    <div className='button-excel'>
                        <Button disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                    </div>
                </div>
            </Spin >
        </div >
    )
}

export default UserManage