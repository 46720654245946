import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { LoginRequest } from '../../common/define-identity';
import { loginRequest } from '../../redux/controller/login.slice';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import Logo from '../../images/logo.png';
import './login.scss';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CFG_BASE_URL } from '../../constants/config';
interface MyProps {
    isOpenModal: boolean;
    toggleLoginModal: () => void;
    toggleRegisterModal: () => void;
    cancelLoginModal: () => void;
}

const LoginModal = (props: MyProps) => {
    const history = useNavigate();

    // const dispatch = useDispatchRoot();
    const dispatch = useDispatchRoot();
    const isSuccess = useSelectorRoot(state => (state.login.statusCode));
    const { tokenPublic } = useSelectorRoot(state => (state.fruit));
    const loginre: any = useSelectorRoot(state => (state.login));
    const [isOpenForgotPassword, setIsOpenForgotPassword] = useState(false);
    const [isOpenResetPassword, setIsOpenResetPassword] = useState(false);
    const [email, setEmail] = useState('');

    useEffect(() => {
        let userToken = sessionStorage.getItem('token');
        console.log(tokenPublic);
        if (isSuccess === "OK" && userToken !== null && userToken !== undefined) {

            if (tokenPublic) {
                setClientToken(tokenPublic);
            }
            else {
                props.toggleLoginModal();
                if (loginre.user.role === 'ADMIN') {
                    window.location.pathname = '/admin';
                }
                else {
                    window.location.pathname = '/home';
                }
            }


            // window.location.reload();
        }
    }, [isSuccess])

    const onFinish = async (account: LoginRequest): Promise<any> => {
        console.log(account);

        await axios.get(`${CFG_BASE_URL}/admin/users`)
            .then(res => {
                console.log(res.data.data);

                const currentAcc = res.data.data.find((item: any) => item.email === account.email);
                console.log(currentAcc);

                if (currentAcc) {
                    if (currentAcc.blocked) {
                        notification.error({
                            message: 'Tài khoản đã bị khóa',
                            description: 'Vui lòng liên hệ quản trị viên để biết thêm chi tiết',
                        });
                        return;
                    }
                    else {
                        dispatch(loginRequest(account));
                        // Thay đổi đường dẫn thành '/'
                        history('/');
                        props.cancelLoginModal();
                    }
                }

                else {
                    dispatch(loginRequest(account));
                    // Thay đổi đường dẫn thành '/'
                    history('/');
                    props.cancelLoginModal();
                }
            }).catch(err => {
                console.log(err);
            })
    }

    const toggleForgotPassword = async (values: any) => {
        console.log("value", values);
        axios.post(`${CFG_BASE_URL}/users/forgot`, {
            "email": values.email
        }).then((res) => {
            console.log(res.data);
            notification.success({
                message: 'Thành công',
                description: 'Vui lòng kiểm tra email để lấy lại mật khẩu',
                placement: 'topRight',
                duration: 3,
            });
            setEmail(values.email);
            setIsOpenResetPassword(true);
        }).catch((err) => {
            console.log(err);
            notification.error({
                message: 'Thất bại',
                description: 'Lấy lại mật khẩu thất bại',
                placement: 'topRight',
                duration: 3,
            })
        })
        setIsOpenForgotPassword(false);
    }

    const onResetPassword = async (values: any) => {
        console.log(values);
        axios.post(`${CFG_BASE_URL}/users/reset-password`, {
            "email": email,
            "OTPCode": values.OTP,
            "password": values.password,
            "confirmPassword": values.confirmPassword
        }).then((res) => {
            console.log(res.data);
            notification.success({
                message: 'Thành công',
                description: 'Bạn đã đặt lại mật khẩu',
                placement: 'topRight',
                duration: 3,
            });
        setIsOpenResetPassword(false);
        }).catch((err) => {
            console.log(err);
            notification.error({
                message: 'Thất bại',
                description: 'OTP không hợp lệ',
                placement: 'topRight',
                duration: 3,
            })

        })
    }

    const setClientToken = async (token: string) => {

        let userToken = sessionStorage.getItem('token');
        if (userToken) {
            userToken = userToken.replace(/"/g, '');
        }

        console.log('userToken', userToken);
        console.log('token', token);

        await axios.post(`${CFG_BASE_URL}/clientToken/import`, {
            clientToken: token,
        }, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            }
        })
            .then((response) => {
                console.log('client Tone', response.data);

                props.toggleLoginModal();
                if (loginre.user.role === 'ADMIN') {
                    window.location.pathname = '/admin';
                }
                else {
                    window.location.pathname = '/home';
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }

    return (
        <>
            <Modal title={
                <>
                    <div className={'logo'}>
                        <span className='logo-text'>AI FRUIT</span>
                    </div>
                    <div className='form-subtitle'>Chào mừng trở lại! Đăng nhập.</div>
                </>
            }
                open={props.isOpenModal}
                onOk={props.toggleLoginModal}
                onCancel={props.cancelLoginModal}
                footer={false}
            >
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={(item) => onFinish(item)}

                >
                    <div>
                        <span className='form-span-text'>Email <strong style={{ color: 'red' }}>(*)</strong></span>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Vui lòng nhập email' }]}
                        >
                            <Input type='email' className='form-input' placeholder="Nhập email" />
                        </Form.Item>
                    </div>
                    <div>
                        <span className='form-span-text'>Mật khẩu <strong style={{ color: 'red' }}>(*)</strong></span>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                        >
                            <Input.Password
                                className='form-input'
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                type="password"
                                placeholder="Nhập mật khẩu"
                            />
                        </Form.Item>
                    </div>
                    <Form.Item className='form-remeber'>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox className='form-span-text'>Ghi nhớ mật khẩu</Checkbox>
                        </Form.Item>

                    </Form.Item>

                    <Form.Item className='form-submit'>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Đăng nhập
                        </Button>
                        <div className='div-form'>
                            <div className='div-form-left' onClick={props.toggleRegisterModal}>
                                Bạn chưa có tài khoản?
                            </div>
                            <div className="div-form-left" onClick={() => setIsOpenForgotPassword(true)}>
                                Quên mật khẩu
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal >

            {/* Modal quên mật khẩu */}
            <Modal title={
                <>
                    {/* <img src={Logo} alt='' /> */}
                    <div className='form-subtitle'>Quên mật khẩu</div>
                </>
            }
                open={isOpenForgotPassword}
                onOk={toggleForgotPassword}
                onCancel={() => setIsOpenForgotPassword(false)}
                footer={false}
            >
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={toggleForgotPassword}

                >
                    <div>
                        <span className='form-span-text'>Email <strong style={{ color: 'red' }}>(*)</strong></span>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Vui lòng nhập email' }]}
                        >
                            <Input className='form-input' placeholder="Nhập email" />
                        </Form.Item>
                    </div>
                    <Form.Item className='form-submit'>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Gửi
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >
            <Modal title={
                <>
                    {/* <img src={Logo} alt='' /> */}
                    <div className='form-subtitle'>Đặt lại mật khẩu</div>
                </>
            }
                open={isOpenResetPassword}
                onOk={onResetPassword}
                onCancel={() => setIsOpenResetPassword(false)}
                footer={false}
            >
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onResetPassword}

                >
                    <div>
                        <span className='form-span-text'>OTP <strong style={{ color: 'red' }}>(*)</strong></span>
                        <Form.Item
                            name="OTP"
                            rules={[{ required: true, message: 'Vui lòng nhập OTP' }, { max: 6, min: 6, message: 'OTP phải là 6 số' }]}
                        >
                            <Input className='form-input' placeholder="Nhập OTP" />
                        </Form.Item>
                        <span className='form-span-text'>Mật khẩu <strong style={{ color: 'red' }}>(*)</strong></span>

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
                        >
                            <Input type='password' className='form-input' placeholder="Nhập mật khẩu" />
                        </Form.Item>
                        <span className='form-span-text'>Nhập lại mật khẩu <strong style={{ color: 'red' }}>(*)</strong></span>

                        <Form.Item
                            name="confirmPassword"
                            rules={[{ required: true, message: 'Vui lòng nhập lại mật khẩu' }]}
                        >
                            <Input  type='password' className='form-input' placeholder="Nhập lại mật khẩu" />
                        </Form.Item>
                    </div>
                    <Form.Item className='form-submit'>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Gửi
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >
        </>

    );
};
export function useLoginre() {
    const loginre: any = useSelectorRoot(state => (state.login));
    return loginre;
}

export default LoginModal;