import { Layout } from 'antd'
import 'antd/dist/reset.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { Header } from './components/Header/Header'
import Footer from './components/Footer/footer'
import SideBar from './components/SideBar/SideBar'
import Assist from './pages/Assist'
import CameraDetail from './pages/Camera/CameraDetail'
import CameraManager from './pages/Camera/CameraManager'
import OverView from './pages/Overview/OverView'
import PackManager from './pages/Pack/PackManager'
import StatisticTypeOfFruitByDate from './pages/StatisticTypeOfFruitByDate/StatisticTypeOfFruitByDate'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor, useDispatchRoot, useSelectorRoot } from './redux/store'
import Warehouse from './pages/Warehouse/Warehouse'
import { useEffect, useState } from 'react'

import Dashboard from './pages/admin/dashboard/dashboard'
import ModelManage from './pages/admin/model/modelManageClassify'
import UserManage from './pages/admin/user/userManagement'
import WarehouseManage from './pages/admin/allWarehouse/warehouseManagement'
// import AllFarmList from './pages/admin/allFarmList'
// import FarmList from './pages/admin/farmList'
import WarehouseList from './pages/admin/warehouse/warehouseList'
import { FC } from 'react'
// import ModalProfile from './pages/admin/modelPigDemo'
import WarehouseDetails from './pages/admin/warehouseDetails/warehouseDetails'
import NotFound from './components/common/not-found/not-found'
import { getAllNotificationByIdUserRequest } from './redux/controller'
import CameraHistory from './pages/Camera/CameraHistory'
import Home from './pages/Home/Home'
import Statistic from './pages/Statisctic/Statistics'
import DetailPackManager from './pages/Pack/DetailPackManager'
import { SidebarAdmin } from './pages/admin/HeaderAdmin'
import Crate from './pages/Crate/Crate'
import ModelManageDetect from './pages/admin/model/modelManageDetect'
import ModelManageClassify from './pages/admin/model/modelManageClassify'
import axios from 'axios'
import ActivePage from './pages/Active/ActivePage'
import FarmManagement from './pages/admin/farmmanagement/farmmanagement'
import PoliciesTerms from './pages/PoliciesTerms/PoliciesTerms'



const { Content } = Layout;
interface IRoute {
    exact: boolean;
    path: string;
    component: FC<any>;
    isLoginRequired: boolean;
}

interface IArrayRoutes extends IRoute {
    layout?: FC<any>;
    routes?: Array<IRoute>;
}
const allFarmRoutes: Array<IArrayRoutes> = [
    {
        exact: true,
        path: '/warehouse-manage/:userID',
        component: WarehouseList,
        isLoginRequired: true,
    },
    {
        exact: true,
        path: '/warehouse-details/:warehouseID',
        component: WarehouseDetails,
        isLoginRequired: true,
    },
]

const App = () => {
    const [isLogin, setIsLogin] = useState<string>('')
    const [clickedWareHouseId, setClickedWareHouseId] = useState<string>('')
    const [roleName, setRoleName] = useState<any>('')

    const dispatch = useDispatchRoot()

    useEffect(() => {
        console.log(isLogin);
    }, [isLogin])

    useEffect(() => {
        const checkLogin = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : ''
        if (checkLogin) {
            setIsLogin(checkLogin);
        }
        let userId = sessionStorage.getItem('userId') !== null ? sessionStorage.getItem('userId') : 'abc'
        let role = sessionStorage.getItem('role')
        setRoleName(role);
        console.log(role);
        if (userId) {
            userId = userId.slice(1);
            userId = userId.slice(0, userId.length - 1);
        }

        const req = {
            offset: 0,
            size: 100,
            additionalProp1: {},
        }

        dispatch(getAllNotificationByIdUserRequest(req))



    }, []);

    useEffect(() => {
        const checkLogin = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : ''
        if (checkLogin) {
            setIsLogin(checkLogin);
        }
        let userId = sessionStorage.getItem('userId') !== null ? sessionStorage.getItem('userId') : 'abc'
        if (userId) {
            userId = userId.slice(1);
            userId = userId.slice(0, userId.length - 1);
        }

        const req = {
            userId: userId,
            offset: 0,
            size: 10,
            additionalProp1: {},
        }

        // dispatch(getAllNotificationByIdUserRequest(req))
    }, []);

    useEffect(() => {
        // getAllNotification();

        const warehouseId = sessionStorage.getItem('warehouseId') ? sessionStorage.getItem('warehouseId') : '';
        if (warehouseId) {
            setClickedWareHouseId(warehouseId);
        }
    })

    useEffect(() => {
        let role = sessionStorage.getItem('role')
        if (role) {
            role = role.slice(1);
            role = role.slice(0, role.length - 1);
        }


        if (role === 'ADMIN') {
            if (window.location.pathname !== '/admin'
                && window.location.pathname !== '/warehouse-manage'
                && !window.location.pathname.includes('warehouse-details')
                && window.location.pathname !== '/user-manage'
                && window.location.pathname !== '/model-manage-detect'
                && window.location.pathname !== '/model-manage-classify'
                && !window.location.pathname.includes('/warehouse-manage')
            ) {
                window.location.pathname = '/admin';
            }
        }

        // if (role === 'OWNER') {
        //     console.log(window.location.pathname);
        //     if (
        //         window.location.pathname !== '/home'
        //         && window.location.pathname !== '/pack_manager'
        //         && !window.location.pathname.includes('crate_manager')
        //         && window.location.pathname !== '/camera_manager'
        //         && !window.location.pathname.includes('camera_detail')
        //         && window.location.pathname !== '/assist'
        //         && window.location.pathname !== '/statistic'
        //         && window.location.pathname !== '/warehouse'
        //         && window.location.pathname !== '/404-not-found'
        //     ) {
        //         window.location.pathname = '/home';
        //     }
        // }

        console.log(role);

    }, [window.location.pathname])


    // const getAllNotification = () => {
    //     setInterval(() => {
    //         console.log('hehehehe')
    //         let userId = sessionStorage.getItem('userId') !== null ? sessionStorage.getItem('userId') : 'abc'
    //         if (userId) {
    //             userId = userId.slice(1);
    //             userId = userId.slice(0, userId.length - 1);


    //         }
    //         const req = {
    //             userId: userId,
    //             offset: 0,
    //             size: 10,
    //             additionalProp1: {},
    //         }

    //         dispatch(getAllNotificationByIdUserRequest(req))
    //     }, 3000)
    // }
    // const loginre : any = useSelectorRoot(state => (state.login));
    // console.log(loginre)

    console.log(roleName);
    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* <SideBar /> */}
            <Layout style={{ background: '#edf1f5' }} className="site-layout" >
                <Header />
                {/* {(isLogin && !clickedWareHouseId && (roleName !== '"ADMIN"')) && <Warehouse />} */}
                {(!isLogin) ?
                    <Content style={{ margin: '0 16px' }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/active" element={<ActivePage />} />
                            <Route path="/policies-terms" element={<PoliciesTerms />} />
                        </Routes>
                    </Content>
                    :
                    <div style={{ display: 'flex', margin: '1em 0', width: '100%' }}>
                        {/* {(roleName !== '"ADMIN"' && clickedWareHouseId) ? <SideBar /> : 
                            (roleName == '"ADMIN"') ? <SidebarAdmin /> : <></>} */}

                        {/* {clickedWareHouseId && */}
                        <Content style={{ margin: '0 16px' }}>
                            <Routes>
                                <Route path='/home' element={<OverView />} />
                                <Route path='/pack_manager' element={<PackManager />} />
                                <Route path='/crate_manager/:warehouseId' element={<Crate />} />
                                <Route path='/pack_detail/:packId' element={<DetailPackManager />} />
                                <Route path='/camera_manager' element={<CameraManager />} />
                                <Route path='/assist' element={<Assist />} />
                                <Route path='/camera_detail/:cameraId' element={<CameraDetail />} />
                                <Route path='/camera_history/:cameraId' element={<CameraHistory />} />
                                <Route path='/statistic' element={<Statistic />} />
                                <Route path='/warehouse' element={<Warehouse />} />
                                <Route path='/404-not-found' element={<NotFound />} />
                                <Route path='/user-manage' element={<UserManage />}></Route>
                                <Route path='/warehouse-manage' element={<WarehouseManage />}></Route>
                                <Route path='/warehouse-manage/:userID' element={<WarehouseList />}></Route>
                                <Route path='/farm-manage/:userID' element={<FarmManagement />}></Route>
                                <Route path='/model-manage' element={<ModelManage />}></Route>
                                <Route path='/model-manage-detect' element={<ModelManageDetect />}></Route>
                                <Route path='/model-manage-classify' element={<ModelManageClassify />}></Route>
                                <Route path="/admin" element={<Dashboard />} />
                                <Route path='/404-not-found' element={<NotFound />} />
                                <Route path="/active" element={<ActivePage />} />
                            </Routes>
                        </Content>
                        {/* } */}
                        {/* {(roleName === '"ADMIN"') &&
                            <Routes>

                            </Routes>} */}

                        {/* {(roleName === '"ADMIN"') ? <Routes>
                            {allFarmRoutes.map(({
                                exact,
                                path,
                                component: Component,
                                layout: Layout,
                                routes: nestedRoutes,
                                isLoginRequired
                            }, i) => {
                                return (
                                    <Route
                                        key={i}
                                        path={path}
                                        element={<Component />}
                                    />
                                );
                            })}
                        </Routes> : <></>} */}
                    </div>
                }
                {/* <Footer />  */}

            </Layout>
            <Footer />
        </Layout>
    )
}

export default App
