import { Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CFG_BASE_URL } from "../../../constants/config";

const Container = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
`;

const VideoContainer = styled.video`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
`;

interface Prop {
    stream: MediaStream | undefined;
    muted?: boolean;
    autoPlay?: boolean;
    cameraId?: string;
}

const boundingBoxes = [
    { id: 1, x1: 100, y1: 50, x2: 120, y2: 70, label: 'Mango 1' },
    // { id: 2, x: 120, y: 70, width: 10, height: 10, label: 'Mango 2' },
];

const lstDragonFruitInfo = [
    {
        vitaminC: '(> 6mg/100g)',
        axit: '(>0.4%CMAE)',
    },
    {
        vitaminC: '(5-6mg/100g)',
        axit: '0.21-0.4%CMAE)',
    },
    {
        vitaminC: '(< 5mg/100g)',
        axit: '(<0.2%CMAE)',
    }
]

const lstMangoInfo = [
    {
        vitaminC: '(> 26mg/100g)',
        axit: '(0.3-0.7%CAE)',
    },
    {
        vitaminC: '(20-25mg/100g)',
        axit: '(0.15-0.29%CAE)',
    },
    {
        vitaminC: '(< 20mg/100g)',
        axit: '(<0.15%CAE)',
    }
]


const CameraStream = ({
    stream,
    muted,
    autoPlay = true,
    cameraId,
}: Prop): JSX.Element => {
    const ref = useRef<HTMLVideoElement>(null);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [hoveredMango, setHoveredMango] = React.useState(null);
    const [lstFruit, setLstFruit] = React.useState<any>([]);
    const [lstBackground, setLstBackground] = React.useState<any>([]);
    const colors = ['green', 'yellow', 'red']
    const [loading, setLoading] = useState<boolean>(true);

    console.log('camera id', cameraId);

    useEffect(() => {
        if (ref.current && stream && stream.active) {
            ref.current.srcObject = stream;
            setLoading(false);
        }

        if (muted) setIsMuted(muted);
    }, [stream, muted]);

    useEffect(() => {
        getDetailFruit();
    }, [cameraId]);

    useEffect(() => {
        if (lstFruit && lstFruit.length > 0) {
            // Tạo ra mảng có số phần tử bằng với lstFruit và giá trị mỗi phần tử là false
            let arrBackground = new Array(lstFruit.length).fill(false);
            console.log('arrBackground', arrBackground);

            setLstBackground(arrBackground);
        }
    }, [lstFruit]);


    const getDetailFruit = async () => {

        let token = sessionStorage.getItem('token');
        console.log('token', token);
        if (token) {
            token = token.replace(/"/g, '');
        }

        await axios.get(`${CFG_BASE_URL}/fruit?cameraId=${cameraId}&size=1000&offset=0`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                console.log('response', response.data.data.items);
                setLstFruit(response.data.data.items);

            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const handleMouseEnter = (box: any) => {
        setHoveredMango(box.description);
        let index = lstFruit.findIndex((item: any) => item._id === box._id);
        let arrBackground = new Array(lstFruit.length).fill(false);
        arrBackground[index] = true;
        setLstBackground(arrBackground);
    }

    const haneleMouseLeave = (box: any) => {
        setHoveredMango(null);
        let arrBackground = new Array(lstFruit.length).fill(false);
        setLstBackground(arrBackground);
    }

    const handleSetStatus = (box: any) => {
        switch (box.status) {
            case 0:
                return 'Quả tốt';
            case 2:
                return 'Quả đã hỏng';
            case 1:
                return 'Quả mới hỏng';
            default:
                return 'Trung bình';
        }
    }

    const handleSetAxit = (box: any) => {
        const fruitName = sessionStorage.getItem('selectedFruitType');
        switch (box.axit) {
            case 0:
                return `Độ axit cao ${fruitName === 'Thanh long' ? lstDragonFruitInfo[0].axit : lstMangoInfo[0].axit}`;
            case 1:
                return `Độ axit trung bình ${fruitName === 'Thanh long' ? lstDragonFruitInfo[1].axit : lstMangoInfo[1].axit}`;
            case 2:
                return `Độ axit thấp ${fruitName === 'Thanh long' ? lstDragonFruitInfo[2].axit : lstMangoInfo[2].axit}`;
            default:
                return `Độ axit trung bình`;
        }
    }

    const handleSetVitamin = (box: any) => {
        const fruitName = sessionStorage.getItem('selectedFruitType');
        switch (box.vitamin) {
            case 0:
                return `Lượng Vitamin C cao ${fruitName === 'Thanh long' ? lstDragonFruitInfo[0].vitaminC : lstMangoInfo[0].vitaminC}`;
            case 1:
                return `Lượng Vitamin trung bình ${fruitName === 'Thanh long' ? lstDragonFruitInfo[1].vitaminC : lstMangoInfo[1].vitaminC}`;
            case 2:
                return `Lượng Vitamin thấp ${fruitName === 'Thanh long' ? lstDragonFruitInfo[2].vitaminC : lstMangoInfo[2].vitaminC}`;
            default:
                return `Lượng Vitamin trung bình`;
        }
    }

    const getNumberDayFruit = (date: any) => {
        const currentDate = new Date();
        const dateFruit = new Date(date);
        const diffTime = Math.abs(currentDate.getTime() - dateFruit.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays + ' ngày';
    }

    const getOrginFruit = (box: any) => {
        const origin = sessionStorage.getItem('selectedFruitOrigin');
        if (origin) {
            return origin;
        }
    }

    return (
        <div className="contailer-video">
            <VideoContainer ref={ref} muted={isMuted} autoPlay={autoPlay} />
            {/* <div className="bounding-boxes"> */}

            {(lstFruit && lstFruit.length > 0 && !loading) && lstFruit.map((box: any, index: any) => (
                <Tooltip
                    title={
                        <div className="title-tooltip">
                            <div>{box.description}</div>
                            <div>Chất lượng: {handleSetStatus(box)}</div>
                            <div>Nguồn gốc: {getOrginFruit(box)}</div>
                            <div>{handleSetAxit(box)}</div>
                            <div>{handleSetVitamin(box)}</div>
                            <div>{`Quả đã được lưu trong kho ${getNumberDayFruit(box.createdAt)}`}</div>
                        </div>
                    }
                    key={box._id}
                    // color={colors[box.status]}
                >
                    <div
                        key={box._id}
                        className="bounding-box"
                        style={{
                            left: (box.loc.x1 + .7) + '%',
                            top: (box.loc.y1 + .4) + '%',
                            width: ((box.loc.x2 + .7) + - (box.loc.x1 + .7)) + '%',
                            height: ((box.loc.y2 + .4) - (box.loc.y1 + .4)) + '%',
                            border: lstBackground[index] ? '4px solid #a103fc' : 'none',
                            cursor: 'pointer'
                        }}
                        onMouseEnter={() => handleMouseEnter(box)}
                        onMouseLeave={() => haneleMouseLeave(box)}
                    />
                </Tooltip>
            ))}
            {/* </div> */}
            {/* {hoveredMango && <div className="mango-info">{hoveredMango}</div>} */}
        </div>
    );
};

export default CameraStream;
