import React, { FC, useEffect } from 'react';
// import { renderMenu } from '@components/common/header/header';
import { Link } from 'react-router-dom';
import appStore from '../../public/assets/app-store.png';
import googlePlay from '../../public/assets/google-play.png';
import Logo from '../../images/logo.png';

import './footer.scss';

const Footer: FC = () => {

    const [isLogin, setIsLogin] = React.useState<string>('');
    const [userEmail, setUserEmail] = React.useState<string>('');
    const [userName, setUserName] = React.useState<string>('');

    useEffect(() => {
        const checkLogin = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : ''
        if (checkLogin) {
            setIsLogin(checkLogin);
            const username = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '';
            setUserName(username ? username : '');
            console.log('username', username);
        }
        console.log('lstNotification')

    }, []);

    return (
        <div className={'footer' + (userName ? '' : ' not-user')}>
            <div id='footer-content' className="main-content">
                <div id="left-footer">
                    <div id="footer-logo">
                        <span> AI FRUIT </span>
                    </div>

                    <div id="footer-link">
                        <div id="row-link">
                            <div>TRUNG TÂM NGHIÊN CỨU VÀ PHÁT TRIỂN CÔNG NGHỆ CAO</div>
                            <div>Địa chỉ: Tòa nhà Ươm tạo công nghệ (A28), số 18 Hoàng Quốc Việt, phường Nghĩa Đô, quận Cầu Giấy, thành phố Hà Nội</div>
                            <div>Điện thoại: 024. 3791.6281</div>
                            <div>Fax: 024. 3791.6283</div>
                            <div>Email: vanthu@cretech.vast.vn</div>
                        </div>
                        {/* <div className={`nav-menu nav-menu-footer`}>
                            {renderMenu()}
                        </div> */}
                    </div>

                    {/* <div id="rights">
                        Copyright © 2022 Trung tâm Nghiên cứu và Chuyển giao công nghệ. All rights reserved
                    </div> */}

                </div>

                <div id="right-footer">
                    <p id='right-footer-title'> Get the app </p>
                    <div className="download-link"> <a href=''><img src={appStore} alt="" /></a> </div>
                    <div className="download-link"> <a href=''><img src={googlePlay} alt="" /></a> </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
