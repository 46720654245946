import React, { FC, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatchRoot, useSelectorRoot } from '../../../redux/store';
import './style.modalprofile.scss'
import axios from 'axios'
import moment from 'moment'
import { Button, Form, Image, Input, InputNumber, Modal, Select, Spin, Table, notification } from 'antd'
// import { useNavigate } from 'react-router-dom';
import { buffer } from 'rxjs/operators'
import IdentityApi from "../../../api/identity.api";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import temp1 from '../../../public/assets/F1_curve.png'
import temp2 from '../../../public/assets/PR_curve.png'
import temp3 from '../../../public/assets/P_curve.png'
import temp4 from '../../../public/assets/R_curve.png'
import temp5 from '../../../public/assets/confusion_matrix.png'
import temp6 from '../../../public/assets/results.png'
import track1 from '../../../public/assets/tracking_1.png'
import track2 from '../../../public/assets/tracking_1.png'
import track3 from '../../../public/assets/tracking_1.png'
import track4 from '../../../public/assets/tracking_1.png'
import track5 from '../../../public/assets/tracking_1.png'
const api = IdentityApi.host
const columns = [
    {
        title: 'Tên kho',
        dataIndex: 'farmName',
        key: 'farmName',
    },
    // {
    //     title: 'Tên chuồng',
    //     dataIndex: 'pigHouseName',
    //     key: 'pigHouseName',
    // },
    {
        title: 'Số lượng mẫu',
        dataIndex: 'sampleCount',
        key: 'sampleCount',
    },
    {
        title: 'Thao tác',
        dataIndex: 'action',
        key: 'action',
    },
];


const columnsTrain = [
    {
        title: 'Ngày',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Số lượng mẫu',
        dataIndex: 'sampleCount',
        key: 'sampleCount',
    },
    {
        title: 'Tình trạng',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Kết quả',
        dataIndex: 'result',
        key: 'result',
    },
    // {
    //     title: 'Thao tác',
    //     dataIndex: 'action',
    //     key: 'action',
    // }
]

const columnsEdit = [
    {
        title: 'Tên file',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Tên file',
        dataIndex: 'name1',
        key: 'name1',
    },
    {
        title: 'Tên file',
        dataIndex: 'name2',
        key: 'name2',
    },
    {
        title: 'Tên file',
        dataIndex: 'name3',
        key: 'name3',
    },
    {
        title: 'Tên file',
        dataIndex: 'name4',
        key: 'name4',
    },
    // {
    //     title: 'Thao tác',
    //     dataIndex: 'action',
    //     key: 'action',
    //     width: 100
    // }
]



const ModelManageDetect = () => {
    const [modalDetect, setModalDetect] = React.useState<any>(null)
    const [modalTracking, setModalTracking] = React.useState<any>(null)
    const [modalBehaviourTracking, setModalBehaviourTracking] = React.useState<any>(null)
    const [modalTemperatureTracking, setModalTemperatureTracking] = React.useState<any>(null)
    const [lstDataSetTracking, setLstDataSetTracking] = React.useState<any>(null)
    const [lstDataSetDetect, setLstDataSetDetect] = React.useState<any>(null)
    const [dataTableTracking, setDataTableTracking] = React.useState<any>(null)
    const [dataTableDetect, setDataTableDetect] = React.useState<any>(null)
    const [isShowModalTracking, setIsShowModalTracking] = React.useState<boolean>(false)
    const [isShowModalDetect, setIsShowModalDetect] = React.useState<boolean>(false)
    const [isShowModalAddSampleDetect, setIsShowModalAddSampleDetect] = React.useState<boolean>(false)
    const [isShowModalAddSampleDetectById, setIsShowModalAddSampleDetectById] = React.useState<boolean>(false)
    const [isShowModalAddSampleTracking, setIsShowModalAddSampleTracking] = React.useState<boolean>(false)
    const [isShowModalAddSampleTrackingById, setIsShowModalAddSampleTrackingById] = React.useState<boolean>(false)
    const [isShowModalEditSampleTracking, setIsShowModalEditSampleTracking] = React.useState<boolean>(false)
    const [isShowModalEditSampleDetect, setIsShowModalEditSampleDetect] = React.useState<boolean>(false)
    const [lstImageTracking, setLstImageTracking] = React.useState<any>(null)
    const [lstImageDetect, setLstImageDetect] = React.useState<any>(null)

    const [lstFarm, setLstFarm] = React.useState<any>(null)
    const [lstPen, setLstPen] = React.useState<any>(null)

    const [isTraningDETECTION, setIsTraningDETECTION] = React.useState<boolean>(true)
    const [isTraningTRACKING, setIsTraningTRACKING] = React.useState<boolean>(true)

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [isOpenLightBox, setIsOpenLightBox] = React.useState<boolean>(false);
    const [lstImageDetectLength, setLstImageDetectLength] = useState<any>(null)
    const [lstImageTrackingLength, setLstImageTrackingLength] = useState<any>(null)
    const openLightbox = () => {
        setIsOpenLightBox(true);
    }
    const history = useNavigate();
    useEffect(() => {
        const role = sessionStorage.getItem('role')
        if (role !== '"ADMIN"') {
            history('/404-not-found');
            return;
        }
        getAllFarm();
        getDetectionModel();
        getClassifyModel();
        // getBehaviourTrackingModel();
        getAllDataSet();
    }, [])

    const getAllFarm = async () => {
        await axios.get(`${api}/admin/warehouse`)
            .then((res: any) => {
                setLstFarm(res?.data?.data)
            })
            .catch((err: any) => {
                console.log(err);
            })
    }

    const [selectedWerehouseId, setSelectedWerehouseId] = useState('');
    // setSelectedWerehouseId(lstFarm[0]._id);
    const onChangeFarm = async (e: any) => {
        console.log(e);
        setSelectedWerehouseId(lstFarm[e]._id);
        if (!e) return;
        // await axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${e}?size=1000`)
        //     .then((res: any) => {
        //         setLstPen(res?.data?.data?.items)
        //     })
    }

    const getDetectionModel = async () => {
        await axios.get(`${api}/model`)
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                setModalDetect(res.data.data[1]);
            })
    }

    const getClassifyModel = async () => {
        await axios.get(`${api}/model`)
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                setModalTracking(res.data.data[0]);
            })
    }

    // const getBehaviourTrackingModel = async () => {
    //     await axios.get('https://sit.api.pigman.com.vn/behaviourTrackingModel')
    //         .then((res: any) => {
    //             if (res.data.data.length === 0) return;
    //             setModalBehaviourTracking(res.data.data);

    //             // const tmp = res.data.data;
    //             // tmp.map((item: any) => {
    //             //     // const originalPercentage = "20%";
    //             //     const percentageValue = parseFloat(item.eatThreshold); // Convert the percentage to a decimal
    //             //     const minutesValue = percentageValue * 30; // Calculate the equivalent minutes

    //             //     const modifiedString = `${percentageValue}% is ${minutesValue} minute`;

    //             // })
    //         })
    // }


    const getAllDataSet = async () => {
        await axios.get(`${api}/dataset/all`)
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                console.log(res.data.data);

                const tmp = res.data.data.filter((item: any) => item.model.type === 'CLASSIFICATION')
                setLstDataSetTracking(tmp);
                const tmp2 = res.data.data.filter((item: any) => item.model.type === 'DETECTION')
                setLstDataSetDetect(tmp2);
            })
            .catch((err: any) => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (!lstDataSetTracking) return;
        console.log(lstDataSetTracking);

        const tmp: any[] = []
        lstDataSetTracking.map((item: any, index: any) => {
            if (index === 0) {
                tmp.push({
                    farmName: 'Dữ liệu huấn luyện ban đầu',
                    // pigHouseName: '',
                    sampleCount: item.numpics,
                    action:
                        <div className='button-group'>
                            <Button
                                onClick={() => {
                                    setIsShowModalTracking(false)
                                    setIsShowModalAddSampleTrackingById(true)
                                    setSelectedSample(item)
                                    // setIsShowModalAddSampleTracking(true)
                                }}
                            >Thêm mẫu</Button>
                            <Button onClick={
                                () => {
                                    handleClickEditTracking(item)
                                }
                            }>Chỉnh sửa</Button>
                        </div >
                })
            }
            else {
                tmp.push({
                    farmName: item.warehouse.name,
                    // pigHouseName: item.pen.penName,
                    sampleCount: item.numpics,
                    action:
                        <div className='button-group'>
                            <Button
                                onClick={() => {
                                    setIsShowModalTracking(false)
                                    setIsShowModalAddSampleTrackingById(true)
                                    setSelectedSample(item)
                                    // setIsShowModalAddSampleTracking(true)
                                }}
                            >Thêm mẫu</Button>
                            <Button onClick={
                                () => {
                                    handleClickEditTracking(item)
                                }
                            }>Chỉnh sửa</Button>
                        </div >


                })
            }
            setDataTableTracking(tmp)
        })

    }, [lstDataSetTracking])

    useEffect(() => {
        if (!lstDataSetDetect) return;
        console.log(lstDataSetDetect);

        const tmp: any[] = []
        lstDataSetDetect.map((item: any, index: any) => {
            if (index === 0) {
                tmp.push({
                    farmName: 'Dữ liệu huấn luyện ban đầu',
                    // pigHouseName: '',
                    sampleCount: item.numpics,
                    action:
                        <div className='button-group'>
                            <Button onClick={() => {
                                setIsShowModalDetect(false)
                                setIsShowModalAddSampleDetectById(true)
                                setSelectedSample(item)
                            }}>Thêm mẫu</Button>
                            <Button
                                disabled={isLoading}
                                onClick={
                                    () => {
                                        setIsLoading(true)
                                        handleClickEditDetection(item)
                                    }
                                }
                            >Chỉnh sửa</Button>
                        </div>

                })
            }
            else {
                tmp.push({
                    farmName: item.warehouse?.name,
                    // pigHouseName: item.pen.penName,
                    sampleCount: item.numpics,
                    action:
                        <div className='button-group'>
                            <Button onClick={() => {
                                setIsShowModalDetect(false)
                                setIsShowModalAddSampleDetectById(true)
                                setSelectedSample(item)
                            }}>Thêm mẫu</Button>
                            <Button
                                disabled={isLoading}
                                onClick={
                                    () => {
                                        setIsLoading(true)
                                        handleClickEditDetection(item)
                                    }
                                }
                            >Chỉnh sửa</Button>
                        </div>

                })
            }
            console.log(tmp);
            setDataTableDetect(tmp)
        })
        // setDataTableTracking(tmp);
    }, [lstDataSetDetect])

    const handleClickEditTracking = async (item: any) => {
        setIsLoading(true)
        console.log(item);
        await axios.get(`${api}/dataset/dataset/${item.id}`)
            .then((res: any) => {
                // console.log(res.data);
                const rows = Math.ceil(res?.data?.length / 5);
                setLstImageTrackingLength(res.data.length)
                //console.log(rows);
                const tmp: any[] = [];
                for (let i = 0; i < rows; i++) {
                    const item = res.data[i * 5];
                    const item1 = res.data[i * 5 + 1];
                    const item2 = res.data[i * 5 + 2];
                    const item3 = res.data[i * 5 + 3];
                    const item4 = res.data[i * 5 + 4];
                    tmp.push({
                        name:
                            <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking(`${api}/dataset/images/${item.id}`)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item)}>Xóa</Button>
                                </div>
                                <div>{item?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div>,
                        name1:
                            item1 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item1.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking(`${api}/dataset/images/${item1.id}`)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item1)}>Xóa</Button>
                                </div>
                                <div>{item1?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div> : <div></div>,
                        name2:
                            item2 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item2.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking(`${api}/dataset/images/${item2.id}`)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item2)}>Xóa</Button>
                                </div>
                                <div>{item2?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div> : <div></div>,
                        name3:
                            item3 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item3.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking(`${api}/dataset/images/${item3.id}`)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item3)}>Xóa</Button>
                                </div>
                                <div>{item3?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div> : <div></div>,
                        name4:
                            item4 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item4.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking(`${api}/dataset/images/${item4.id}`)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item4)}>Xóa</Button>
                                </div>
                                <div>{item4?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div> : <div></div>,
                    })

                }
                console.log(tmp);
                console.log(tmp.length)
                setLstImageTracking(tmp)
                setIsShowModalTracking(false)
                setIsShowModalEditSampleTracking(true)
                setIsLoading(false)

            })
    }

    const handleClickEditDetection = async (item: any) => {

        setIsLoading(true)
        await axios.get(`${api}/dataset/dataset/${item.id}`)
            .then((res: any) => {
                console.log(res.data.length);
                setLstImageDetectLength(res.data.length)
                const rows = Math.ceil(res?.data?.length / 5);
                //console.log(rows);
                const tmp: any[] = [];
                for (let i = 0; i < rows; i++) {
                    const item = res.data[i * 5];
                    const item1 = res.data[i * 5 + 1];
                    const item2 = res.data[i * 5 + 2];
                    const item3 = res.data[i * 5 + 3];
                    const item4 = res.data[i * 5 + 4];
                    tmp.push({
                        name:
                            <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal(`${api}/dataset/images/${item.id}`)}
                                    />
                                    <div style={{
                                        marginTop: 5,
                                        display: 'flex',
                                        gap: 5,
                                    }}>
                                        <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item)}>Xóa</Button>
                                        <Button className='button-view-label' onClick={() => openViewLabel(`${api}/dataset/images/readLabel/${item.id}`)}>Xem nhãn</Button>
                                    </div>

                                </div>
                                <div>{item?.directoryImage?.replace(/^.*\//, '')}</div>

                            </div>,
                        name1:
                            item1 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item1.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal(`${api}/dataset/images/${item1.id}`)}
                                    />
                                    <div style={{
                                        marginTop: 5,
                                        display: 'flex',
                                        gap: 5,
                                    }}>
                                        <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item)}>Xóa</Button>
                                        <Button className='button-view-label' onClick={() => openViewLabel(`${api}/dataset/images/readLabel/${item.id}`)}>Xem nhãn</Button>
                                    </div>
                                </div>
                                <div>{item1?.directoryImage?.replace(/^.*\//, '')}</div>

                            </div> : <div></div>,
                        name2:
                            item2 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item2.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal(`${api}/dataset/images/${item2.id}`)}
                                    />
                                    <div style={{
                                        marginTop: 5,
                                        display: 'flex',
                                        gap: 5,
                                    }}>
                                        <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item)}>Xóa</Button>
                                        <Button className='button-view-label' onClick={() => openViewLabel(`${api}/dataset/images/readLabel/${item.id}`)}>Xem nhãn</Button>
                                    </div>
                                </div>
                                <div>{item2?.directoryImage?.replace(/^.*\//, '')}</div>

                            </div> : <div></div>,
                        name3:
                            item3 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item3.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal(`${api}/dataset/images/${item3.id}`)}
                                    />
                                    <div style={{
                                        marginTop: 5,
                                        display: 'flex',
                                        gap: 5,
                                    }}>
                                        <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item)}>Xóa</Button>
                                        <Button className='button-view-label' onClick={() => openViewLabel(`${api}/dataset/images/readLabel/${item.id}`)}>Xem nhãn</Button>
                                    </div>
                                </div>
                                <div>{item3?.directoryImage?.replace(/^.*\//, '')}</div>

                            </div> : <div></div>,
                        name4:
                            item4 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={`${api}/dataset/images/${item4.id}`}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal(`${api}/dataset/images/${item4.id}`)}
                                    />
                                    <div style={{
                                        marginTop: 5,
                                        display: 'flex',
                                        gap: 5,
                                    }}>
                                        <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item)}>Xóa</Button>
                                        <Button className='button-view-label' onClick={() => openViewLabel(`${api}/dataset/images/readLabel/${item.id}`)}>Xem nhãn</Button>
                                    </div>
                                </div>
                                <div>{item4?.directoryImage?.replace(/^.*\//, '')}</div>

                            </div> : <div></div>,
                    })

                }
                // const tmp = res.data.map((item: any) => {
                //     const name = item?.directoryImage?.replace(/^.*\//, '');
                //     return {
                //         name:
                //             <div>
                //                 <div ><Image src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id} width={200} preview={false} /></div>
                //                 <div>{name}</div>
                //             </div>,
                //         action: <Button onClick={() => handleDeleteDetectImage(item)}>Xóa</Button>
                //     }
                // })
                setLstImageDetect(tmp)
                setIsShowModalDetect(false)
                setIsShowModalEditSampleDetect(true)
                setIsLoading(false)
            })

    }

    const [selectedImages, setSelectedImages] = useState<any[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [inputKey, setInputKey] = useState(Date.now()); // Key to reset the input field
    const [selectedImageNames, setSelectedImageNames] = useState<any>([]);
    const [selectedFileNames, setSelectedFileNames] = useState<any>([]);
    const [selectedSample, setSelectedSample] = useState<any>(null);

    const [formTracking] = Form.useForm();
    const [formDetect] = Form.useForm();
    const [formTrackingById] = Form.useForm();
    const [formDetectById] = Form.useForm();

    const handleFileChange = (e: any) => {
        const files: any = Array.from(e.target.files);
        const newFiles = files.filter((file: any) => !selectedFileNames.includes(file.name));

        if (newFiles.length > 0) {
            setSelectedFiles([...selectedFiles, ...newFiles]);
            const newFileNames = newFiles.map((file: any) => file.name);
            setSelectedFileNames([...selectedFileNames, ...newFileNames]);
        }

        setInputKey(Date.now());
    };

    const handleRemoveFile = (index: any) => {
        const updatedFiles = [...selectedFiles];
        const updatedFileNames = [...selectedFileNames];
        const removedFile = updatedFiles.splice(index, 1)[0];
        updatedFileNames.splice(index, 1);

        setSelectedFiles(updatedFiles);
        setSelectedFileNames(updatedFileNames);
    };
    // Function to handle file input change


    // Function to handle file removal


    const renderSelectedFile = () => {
        return selectedFiles.map((file, index) => (
            <div key={index} className="image-container">
                <div>
                    {/* <img src={URL.createObjectURL(file)} alt={`File ${index}`} width="100" /> */}
                    <p>{file.name}</p>
                </div>
                <Button onClick={() => handleRemoveFile(index)}>Xóa file</Button>
            </div>
        ));
    }

    // Function to render the selected image

    const handleFinishTracking = async (values: any) => {

        // if (!selectedImages || selectedImages.length === 0) {
        //     notification.error({
        //         message: 'Lỗi',
        //         description: 'Vui lòng chọn mẫu mô hình',
        //     })
        //     return;
        // }
        if (!selectedFiles || selectedFiles.length === 0) {
            notification.error({
                message: 'Lỗi',
                description: 'Vui lòng chọn nhãn mô hình',
            })
            return;
        }
        const formData = new FormData();

        formData.append("warehouseId", selectedWerehouseId);
        formData.append("modelType", 'CLASSIFICATION');
        selectedFiles.forEach((image: any) => {
            formData.append("profilePhoto", image);
        });
        console.log(values.farmName);
        try {
            const res = await fetch(`${api}/dataset/newDataset`, {
                method: 'POST',
                body: formData
            })

            console.log(res);
            const data = await res.json();
            console.log(data);
            if (data.message === 'error') {
                if (data.statusCode.code === 'EEXIST') {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Mẫu đã tồn tại',
                    } as any)
                }
            }
            else {
                notification.success({
                    message: 'Thành công',
                    description: 'Thêm mẫu thành công',
                } as any)

                setIsShowModalAddSampleTracking(false)
                setIsShowModalTracking(true)
                formTracking.resetFields();
                setSelectedImages([]);
                getAllDataSet()
            }

        } catch (err) {
            console.log(err);
        }
    }
    const handleFinishDetect = async (values: any) => {

        if (!selectedFiles || selectedFiles.length === 0) {
            notification.error({
                message: 'Lỗi',
                description: 'Vui lòng chọn nhãn mô hình',
            })
            return;
        }

        const formData = new FormData();
        formData.append("warehouseId", selectedWerehouseId);
        formData.append("modelType", 'DETECTION');

        selectedFiles.forEach((file: any) => {
            formData.append("profilePhoto", file);
        });
        try {
            const res = await fetch(`${api}/dataset/newDataset`, {
                method: 'POST',
                body: formData
            })

            console.log(res);
            const data = await res.json();
            console.log(data);
            if (data.message === 'error') {
                if (data.statusCode.code === 'EEXIST') {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Mẫu đã tồn tại',
                    } as any)
                }
            }
            else {
                notification.success({
                    message: 'Thành công',
                    description: 'Thêm mẫu thành công',
                } as any)

                setIsShowModalAddSampleDetect(false)
                setIsShowModalDetect(true)
                formDetect.resetFields();
                setSelectedImages([]);
                setSelectedFiles([]);
                getAllDataSet()

            }

        } catch (err) {
            console.log(err);
        }

    }
    const handleFinishTrackingById = async (values: any) => {

        console.log(selectedSample);


        // if (!selectedImages || selectedImages.length === 0) {
        //     notification.error({
        //         message: 'Lỗi',
        //         description: 'Vui lòng chọn mẫu mô hình',
        //     })
        //     return;
        // }

        if (!selectedFiles || selectedFiles.length === 0) {
            notification.error({
                message: 'Lỗi',
                description: 'Vui lòng chọn nhãn mô hình',
            })
            return;
        }

        const formData = new FormData();
        formData.append("datasetId", selectedSample.id);
        // selectedImages.forEach((image: any) => {
        //     formData.append("profilePhoto", image);
        // });
        selectedFiles.forEach((file: any) => {
            formData.append("profilePhoto", file);
        });
        try {
            const res = await fetch(`${api}/dataset/addImage`, {
                method: 'POST',
                body: formData
            })

            console.log(res);
            const data = await res.json();
            console.log(data);
            if (data.message === 'error') {
                if (data.statusCode.code === 'EEXIST') {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Mẫu đã tồn tại',
                    } as any)
                }
            }
            else {
                notification.success({
                    message: 'Thành công',
                    description: 'Thêm mẫu thành công',
                } as any)

                setIsShowModalAddSampleTrackingById(false)
                setIsShowModalTracking(true)
                formTrackingById.resetFields();
                setSelectedImages([]);
                getAllDataSet()
            }

        } catch (err) {
            console.log(err);
        }
    }

    const handleFinishDetectById = async (values: any) => {
        if (!selectedFiles || selectedFiles.length === 0) {
            notification.error({
                message: 'Lỗi',
                description: 'Vui lòng chọn nhãn mô hình',
            })
            return;
        }

        const formData = new FormData();
        formData.append("datasetId", selectedSample.id);
        selectedFiles.forEach((file: any) => {
            formData.append("profilePhoto", file);
        });
        try {
            const res = await fetch(`${api}/dataset/addImage`, {
                method: 'POST',
                body: formData
            })

            console.log(res);
            const data = await res.json();
            console.log(data);
            if (data.message === 'error') {
                if (data.statusCode.code === 'EEXIST') {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Mẫu đã tồn tại',
                    } as any)
                }
            }
            else {
                notification.success({
                    message: 'Thành công',
                    description: 'Thêm mẫu thành công',
                } as any)

                setIsShowModalAddSampleDetectById(false)
                setIsShowModalDetect(true)
                formDetectById.resetFields();
                setSelectedImages([]);
                setSelectedFiles([]);
                getAllDataSet()

            }

        } catch (err) {
            console.log(err);
        }

    }

    const customValidation = (_: any, value: any) => {
        // Define your custom validation logic here
        // console.log(value);

        if (!selectedImages || selectedImages.length === 0) {
            return Promise.reject('Vui lòng chọn mẫu mô hình');
        }
        return Promise.resolve();
    };

    const customValidationFile = (_: any, value: any) => {
        // Define your custom validation logic here
        // console.log(value);

        if (!selectedFiles || selectedFiles.length === 0) {
            return Promise.reject('Vui lòng chọn nhãn mô hình');
        }
        return Promise.resolve();
    }

    const [isShowDeleteTracking, setIsShowDeleteTracking] = React.useState<boolean>(false)
    const [isShowDeleteDetect, setIsShowDeleteDetect] = React.useState<boolean>(false)
    const [idDelete, setIdDelete] = React.useState<any>(null)

    const handleDeleteTrackingImage = async (item: any) => {
        console.log(item);
        setIdDelete(item.id)
        setIsShowDeleteTracking(true)
        setIsShowModalEditSampleTracking(false)
    }

    const handleDeleteDetectImage = async (item: any) => {
        console.log(item);
        setIdDelete(item.id)
        setIsShowDeleteDetect(true)
        setIsShowModalEditSampleDetect(false)
    }

    const handleDeleteTrackingImageModal = async () => {
        console.log(idDelete);
        await axios.delete(`${api}/dataset/${idDelete}`)
            .then((res: any) => {
                console.log(res);
                notification.success({
                    message: 'Thành công',
                    description: 'Xóa mẫu thành công',
                } as any)
                setIsShowDeleteTracking(false)
                setIsShowModalTracking(true)
                getAllDataSet()
            })
    }

    const handleDeleteDetectImageModal = async () => {
        console.log(idDelete);
        await axios.delete(`${api}/dataset/${idDelete}`)
            .then((res: any) => {
                console.log(res);
                notification.success({
                    message: 'Thành công',
                    description: 'Xóa mẫu thành công',
                } as any)
                setIsShowDeleteDetect(false)
                setIsShowModalDetect(true)
                getAllDataSet()
            })
    }

    const [lstDateSetByType, setLstDateSetByType] = React.useState<any>(null)
    const [isTrainingTracking, setIsTrainingTracking] = React.useState<boolean>(false)
    const [isTrainingDetection, setIsTrainingDetection] = React.useState<boolean>(false)
    const [typeClick, setTypeClick] = React.useState<any>(null)

    useEffect(() => {
        fetchData();
        // Set up an interval to call the API every 1 minute
        const intervalId = setInterval(fetchData, 60000); // 60000 milliseconds = 1 minute
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [])

    const fetchData = async () => {
        checkTrainTracking();
        checkTrainDetection();
    }

    const checkTrainTracking = async () => {
        await axios.get(`${api}/trainHistory/check/haveTraining?modelId=653f21eac8450a4938034411`)
            .then((res: any) => {
                console.log(res?.data?.data);

                setIsTrainingTracking(res?.data?.data)
            })
    }

    const checkTrainDetection = async () => {
        await axios.get(`${api}/trainHistory/check/haveTraining?modelId=653f2200c8450a4938034414`)
            .then((res: any) => {
                console.log(res?.data?.data);
                setIsTrainingDetection(res?.data?.data)
            })
    }

    const handleTrainModal = async (id: any) => {
        fetchData();
        setTypeClick(id)
        handleCheckTrain(id)
        // getAllDateSetByType(type)
    }

    const handleCheckTrain = async (id: any) => {
        await axios.get(`${api}/trainHistory/check/haveTraining?modelId=${id}`)
            .then((res: any) => {
                console.log(res.data.data);
                if (res.data.data === true) {
                    notification.warning({
                        message: 'Thông báo',
                        description: 'Mô hình đang được huấn luyện',
                    } as any)
                    return;
                }
                else if (res.data.data === false) {
                    getAllDateSetByType(id)
                }
            })
    }

    const getAllDateSetByType = async (id: any) => {
        await axios.get(`${api}/dataset/all?modelId=${id}`)
            .then((res: any) => {
                console.log(res.data.data);
                if (id === '653f21eac8450a4938034411') {
                    setLstDateSetByType(res.data.data)
                }
                else {
                    setLstDateSetByType(res.data.data)
                }
            })
    }

    useEffect(() => {
        if (!lstDateSetByType) return;
        const lstDatesetId = lstDateSetByType.map((item: any) => item.id)
        if (typeClick === '653f21eac8450a4938034411') {
            const tmp = {
                traningAt: new Date(),
                datasetId: lstDatesetId,
                batchSize: 0,
                epochs: 0,
                learningRate: 0,
                isTraining: true,
                modelId: '653f21eac8450a4938034411',
            }

            axios.post(`${api}/trainHistory`, tmp)
                .then((res: any) => {
                    // console.log(res);
                    notification.success({
                        message: 'Thành công',
                        description: 'Bắt đầu huấn luyện',
                    } as any)
                })
                .catch((err: any) => {
                    console.log(err);
                })
            axios.put(`${api}/model/653f21eac8450a4938034411`, {
                lastUpdateAt: new Date(),
                isTraining: true
            })
        }
        else if (typeClick === '653f2200c8450a4938034414') {
            const tmp = {
                traningAt: new Date(),
                datasetId: lstDatesetId,
                batchSize: 0,
                epochs: 0,
                learningRate: 0,
                isTraining: true,
                modelId: '653f2200c8450a4938034414',
            }

            axios.post(`${api}/trainHistory`, tmp)
                .then((res: any) => {
                    // console.log(res);
                    notification.success({
                        message: 'Thành công',
                        description: 'Bắt đầu huấn luyện',
                    } as any)
                })
                .catch((err: any) => {
                    console.log(err);
                })
        }
    }, [lstDateSetByType])

    const [lstTrainHistory, setLstTrainHistory] = React.useState<any>(null)
    const [isShowTrainHistory, setIsShowTrainHistory] = React.useState<boolean>(false)
    const [dataTableTrain, setDataTableTrain] = React.useState<any>(null)
    const viewTrain = async (id: any) => {
        await axios.get(`${api}/trainHistory?modelId=${id}`)
            .then((res: any) => {
                // console.log(res.data.data);
                setLstTrainHistory(res.data.data)
                setIsShowTrainHistory(true)
            })
    }

    useEffect(() => {
        console.log(lstTrainHistory);
        if (!lstTrainHistory) return;
        const tmp: any[] = []
        lstTrainHistory.map((item: any) => {
            tmp.push({
                date: moment(item.traningAt).format('DD/MM/YYYY'),
                sampleCount: item.numpics,
                status: item.isTraining ? 'Đang huấn luyện' : 'Hoàn thành',
                result: item.result == 0 ? 'Đang xử lý' : `${item.result}%`
            })
        })
        setDataTableTrain(tmp)
    }, [lstTrainHistory])


    useEffect(() => {
        setSelectedFiles([]);
        setSelectedFileNames([]);
        setSelectedImages([]);
        setSelectedImageNames([]);
    }, [
        isShowModalTracking,
        isShowModalDetect,
        isShowModalAddSampleDetect,
        isShowModalAddSampleTracking,
        isShowModalAddSampleDetectById,
        isShowModalAddSampleTrackingById,
        isShowModalEditSampleTracking,
        isShowModalEditSampleDetect,
        isShowDeleteTracking,
        isShowDeleteDetect,
        isShowTrainHistory,
    ])


    const paramDetection: any[] = [
        {
            id: 1,
            name: 'Số vòng (Epochs)',
            details: 'Số lần huấn luyện lại cả bộ dữ liệu',
            rangeValue: '10 - 1000',
            value: 300
        },
        {
            id: 2,
            name: 'Kích thước lô (Batch size)',
            details: 'Số ảnh được đưa vào trong một lần tính toán',
            rangeValue: '2^n (3<=n<=1010)',
            value: 16
        },
        {
            id: 3,
            name: 'Số tham số (Parameters)',
            details: 'Số lượng tham số của mô hình',
            rangeValue: '37,620,125',
            value: '37,620,125'
        },
        {
            id: 4,
            name: 'Hàm tối ưu (Optimizer)',
            details: 'Thuật toán tối ưu',
            rangeValue: 'adam',
            value: 'adam'
        },
        {
            id: 5,
            name: 'Số lớp (Classes)',
            details: 'Số lượng đối tượng cần được phân loại',
            rangeValue: '4',
            value: 3
        },
    ]

    const paramTracking: any[] = [
        {
            id: 1,
            name: 'Số vòng (Epochs)',
            details: 'Số lần huấn luyện lại cả bộ dữ liệu',
            rangeValue: '10 - 200',
            value: 50
        },
        {
            id: 2,
            name: 'Kích thước lô (Batch size)',
            details: 'Số dữ liệu được đưa vào trong một lần tính toán',
            rangeValue: '2^n (3<=n<=1010)',
            value: 32
        },
        {
            id: 3,
            name: 'Hàm mất mát (Loss)',
            details: 'Loss function',
            rangeValue: 'softmax',
            value: 'softmax'
        },
        {
            id: 4,
            name: '(Số tham số) Parameters',
            details: 'Số lượng tham số của mô hình',
            rangeValue: '2,193,616',
            value: '2,193,616'
        },
        {
            id: 5,
            name: 'Tốc độ học (Learning rate)',
            details: 'Learning rate',
            rangeValue: '0.1',
            value: '0.1'
        },
    ]

    const [showImage, setShowImage] = useState(false);

    const handleToggleImage = () => {
        setShowImage(!showImage); // Toggle the state (show/hide)
    };

    const [showTracking, setShowTracking] = useState(false);

    const handleToggleImageTracking = () => {
        setShowTracking(!showTracking); // Toggle the state (show/hide)
    };

    console.log(isTrainingDetection);

    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalOpenTracking, setModalOpenTracking] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedLabel, setSelectedLabel] = useState<string[]>([]);
    const [isModalLabelOpen, setModalLabelOpen] = useState(false);


    const openModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setModalOpen(true);
    };


    const openViewLabel = async (url: string) => {
        // setSelectedImage(url);
        await axios.get(url).then((res: any) => {
            const out: string[] = res.data.split(/\n/g);
            const updatedOut = out.map((item) => item.toString().replace(/ /g, '  '));
            setSelectedLabel(updatedOut);
        });
        setModalLabelOpen(true);
    };
    const openModalTracking = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setModalOpenTracking(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const closeModalTracking = () => {
        setModalOpenTracking(false);
    };

    const closeModalLabel = () => {
        setModalLabelOpen(false);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // Set the number of records per page
    const [numberPagination, setNumberPagination] = useState(``);
    useEffect(() => {
        setCurrentPage(1);
        if (lstImageTrackingLength > 0) {
            if (1 === Math.ceil(lstImageTrackingLength / (pageSize * 5))) {
                // Lấy ra số ảnh còn lại của trang cuối
                const tmp = lstImageTrackingLength - (1 - 1) * pageSize * 5
                setNumberPagination(`Trang ${1}: ${tmp} ảnh (${((1 - 1) * pageSize * 5) + 1}-${lstImageTrackingLength})`)
            }
            else {
                setNumberPagination(`Trang ${1}: ${pageSize * 5} ảnh (${((1 - 1) * pageSize * 5) + 1}-${1 * pageSize * 5})`)
            }
        }
    }, [lstImageTrackingLength])

    useEffect(() => {
        setCurrentPage(1);
        if (lstImageDetectLength > 0) {
            if (1 === Math.ceil(lstImageDetectLength / (pageSize * 5))) {
                // Lấy ra số ảnh còn lại của trang cuối
                const tmp = lstImageDetectLength - (1 - 1) * pageSize * 5
                setNumberPagination(`Trang ${1}: ${tmp} ảnh (${((1 - 1) * pageSize * 5) + 1}-${lstImageDetectLength})`)
            }
            else {
                setNumberPagination(`Trang ${1}: ${pageSize * 5} ảnh (${((1 - 1) * pageSize * 5) + 1}-${1 * pageSize * 5})`)
            }
        }
    }, [lstImageDetectLength])
    const handleTableChange = (page: any, pageSize: any) => {
        console.log(page);
        console.log(pageSize);
        console.log(currentPage);
        setCurrentPage(page);
        console.log(lstImageTrackingLength);
        console.log(Math.ceil(lstImageDetectLength / (pageSize * 5)));

        if (lstImageDetectLength > 0) {
            if (page === Math.ceil(lstImageDetectLength / (pageSize * 5))) {
                console.log(page);
                const tmp = lstImageDetectLength - (page - 1) * pageSize * 5
                setNumberPagination(`Trang ${formatNumber(page)}: ${tmp} ảnh (${formatNumber(((page - 1) * pageSize * 5) + 1)}-${formatNumber(lstImageDetectLength)})`)
                return
            }
            else {
                console.log(page);
                setNumberPagination(`Trang ${formatNumber(page)}: ${pageSize * 5} ảnh (${formatNumber(((page - 1) * pageSize * 5) + 1)}-${formatNumber(page * pageSize * 5)})`)
                return

            }
        }

        if (lstImageTrackingLength > 0) {
            console.log(page)
            if (page === Math.ceil(lstImageTrackingLength / (pageSize * 5))) {
                const tmp = lstImageTrackingLength - (page - 1) * pageSize * 5
                console.log(page);
                console.log(lstImageTrackingLength)
                setNumberPagination(`Trang ${formatNumber(page)}: ${tmp} ảnh (${formatNumber(((page - 1) * pageSize * 5) + 1)}-${formatNumber(lstImageTrackingLength)})`)
                return

            }
            else {
                setNumberPagination(`Trang ${formatNumber(page)}: ${pageSize * 5} ảnh (${formatNumber(((page - 1) * pageSize * 5) + 1)}-${formatNumber(page * pageSize * 5)})`)
                return

            }
        }
    };
    const paginationConfigImage = {
        current: currentPage,
        pageSize: pageSize,
        onChange: handleTableChange,
    };
    const formatNumber = (num: any) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <div className='body-container'>
            <div className='profile-modal-main'>
                {modalDetect &&
                    <div className='container-modal'>
                        <div className="container-title">MÔ HÌNH PHÁT HIỆN QUẢ TRONG ẢNH</div>
                        <div className="container-content">
                            <div className='lst-container-content-name'>
                                <div className="container-content-name">Tên mô hình: <b>{modalDetect.name}</b> </div>
                                <div className="container-content-date">Ngày cập nhật: <b>{moment(modalDetect.lastUpdateAt).format("DD/MM/YYYY")}</b> </div>
                                <div className="container-content-match">Độ chính xác (mAP): <b>{modalDetect.mAP}%</b> </div>
                                <div className="container-content-match">Độ chính xác (accuracy): <b>{modalDetect.accuracy}%</b> </div>
                                <div className="container-content-match">Độ chính xác (precision): <b>{modalDetect.precision}%</b> </div>
                                <div className="container-content-match">Độ chính xác (recall): <b>{modalDetect.recall}%</b> </div>
                                <div className="container-content-match">Độ chính xác (F1 Score): <b>{modalDetect.f1Score}%</b> </div>
                                <div className='container-content-buttons'>
                                </div>
                            </div>
                            <div className='lst-container-content-button'>
                                <Button className='container-content-button' onClick={() => setIsShowModalDetect(true)}>Dữ liệu huấn luyện</Button>
                                {isTrainingDetection ?
                                    <Button disabled loading className='container-content-button' onClick={() => handleTrainModal('653f2200c8450a4938034414')}>Đang huấn luyện</Button>
                                    :
                                    <Button className='container-content-button' onClick={() => handleTrainModal('653f2200c8450a4938034414')}>Huấn luyện mô hình</Button>
                                }
                                <Button className='container-content-button' onClick={() => viewTrain('653f2200c8450a4938034414')}>Lịch sử huấn luyện</Button>
                            </div>
                            <div className='image-container' style={{ marginTop: 50 }}>
                                <div className='image-block'>
                                    <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                        <img src={temp5} alt="Image 5" className='image' />
                                    </a>
                                    <div className='image-name'>Biếu đồ Confusion Matrix của mô hình sau khi huấn luyện</div>
                                    {/* {isOpenLightBox && (
                                        <Lightbox
                                            mainSrc={temp5}
                                            onCloseRequest={() => setIsOpenLightBox(false)}
                                        />
                                    )} */}
                                </div>
                                <div className='image-block'>
                                    <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                        <img src={temp1} alt="Image 1" className='image' />
                                    </a>
                                    <div className='image-name'>Biểu đồ kết quả F1 Curve của mô hình sau khi huấn luyện</div>
                                    {/* {isOpenLightBox && (
                                        <Lightbox
                                            mainSrc={temp1}
                                            onCloseRequest={() => setIsOpenLightBox(false)}
                                        />
                                    )} */}
                                </div>
                                <div className='image-block'>
                                    <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                        <img src={temp2} alt="Image 2" className='image' />
                                    </a>
                                    <div className='image-name'>Biểu đồ kết quả PR Curve của mô hình sau khi huấn luyện</div>
                                    {/* {isOpenLightBox && (
                                        <Lightbox
                                            mainSrc={temp2}
                                            onCloseRequest={() => setIsOpenLightBox(false)}
                                        />
                                    )} */}
                                </div>
                                <div className='image-block'>
                                    <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                        <img src={temp3} alt="Image 3" className='image' />
                                    </a>
                                    <div className='image-name'>Biểu đồ kết quả Precision Curve của mô hình sau khi huấn luyện</div>
                                    {/* {isOpenLightBox && (
                                        <Lightbox
                                            mainSrc={temp3}
                                            onCloseRequest={() => setIsOpenLightBox(false)}
                                        />
                                    )} */}
                                </div>
                                <div className='image-block'>
                                    <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                        <img src={temp4} alt="Image 4" className='image' />
                                    </a>
                                    <div className='image-name'>Biểu đồ kết quả Recall Curve của mô hình sau khi huấn luyện</div>
                                    {/* {isOpenLightBox && (
                                        <Lightbox
                                            mainSrc={temp4}
                                            onCloseRequest={() => setIsOpenLightBox(false)}
                                        />
                                    )} */}
                                </div>
                                <div className='image-block'>
                                    <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                        <img src={temp6} alt="Image 6" className='image' />
                                    </a>
                                    <div className='image-name'>Bảng kết quả huấn luyện của mô hình</div>
                                    {/* {isOpenLightBox && (
                                        <Lightbox
                                            mainSrc={temp6}
                                            onCloseRequest={() => setIsOpenLightBox(false)}
                                        />
                                    )} */}
                                </div>
                            </div>
                            <div className='image-block' style={{width: '100%'}}>
                                <div className='table-detect' style={{}}>
                                    <table className="table-detect-param" >
                                        <thead className='table-detect-param-header'>
                                            <tr>
                                                <th className='headerGrid'>STT</th>
                                                <th className='headerGrid'>Tên tham số</th>
                                                <th className='headerGrid'>Giá trị</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-detect-param-body'>
                                            {paramDetection.map(emp => (
                                                <tr key={emp.id}>
                                                    <td className='bodyGrid'>{emp.id}</td>
                                                    <td className='bodyGrid'>{emp.name}</td>
                                                    <td className='bodyGrid'>{emp.value}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className='table-model-name'>Bảng tham số của mô hình cho quá trình huấn luyện</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* {modalTracking &&
                    <div className='container-modal'>
                        <div className="container-title">MÔ HÌNH PHÂN LOẠI CHẤT LƯỢNG QUẢ</div>
                        <div className="container-content">
                            <div className="container-content-name">Tên mô hình: <b>{modalTracking.name}</b> </div>
                            <div className="container-content-date">Ngày cập nhật: <b>{moment(modalTracking.lastUpdateAt).format("DD/MM/YYYY")}</b> </div>
                            <div className="container-content-match">Độ chính xác (mAP): <b>{modalTracking.mAP}%</b> </div>
                            <div className="container-content-match">Độ chính xác (accuracy): <b>{modalTracking.accuracy}%</b> </div>
                            <div className="container-content-match">Độ chính xác (precision): <b>{modalTracking.precision}%</b> </div>
                            <div className="container-content-match">Độ chính xác (recall): <b>{modalTracking.recall}%</b> </div>
                            <div className="container-content-match">Độ chính xác (F1 Score): <b>{modalTracking.f1Score}%</b> </div>
                            <div className='container-content-buttons'>
                                <Button className='container-content-button' onClick={() => setIsShowModalTracking(true)}>Dữ liệu huấn luyện</Button>
                                {isTrainingTracking ?
                                    <Button disabled loading className='container-content-button' onClick={() => handleTrainModal('653f21eac8450a4938034411')}>Đang huấn luyện</Button>
                                    :
                                    <Button className='container-content-button' onClick={() => handleTrainModal('653f21eac8450a4938034411')}>Huấn luyện mô hình</Button>
                                }
                                <Button className='container-content-button' onClick={() => viewTrain('653f21eac8450a4938034411')}>Lịch sử huấn luyện</Button>
                            </div>
                            <div>
                                <div className='image-container' style={{ marginTop: 50 }}>
                                    <div className='image-block'>
                                        <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                            <img src={track1} alt="Tracking 1" className='image' />
                                        </a>
                                        <div className='image-name'>Biểu đồ Loss</div>
                                        {isOpenLightBox && (
                                            <Lightbox
                                                mainSrc={track1}
                                                onCloseRequest={() => setIsOpenLightBox(false)}
                                            />
                                        )}
                                    </div>
                                    <div className='image-block'>
                                        <div className='table-detect' style={{}}>
                                            <table className="table-detect-param" >
                                                <thead className='table-detect-param-header'>
                                                    <tr>
                                                        <th className='headerGrid'>STT</th>
                                                        <th className='headerGrid'>Tên tham số</th>
                                                        <th className='headerGrid'>Giá trị</th>
                                                    </tr>
                                                </thead>
                                                <tbody className='table-detect-param-body'>
                                                    {paramTracking.map(emp => (
                                                        <tr key={emp.id}>
                                                            <td className='bodyGrid'>{emp.id}</td>
                                                            <td className='bodyGrid'>{emp.name}</td>
                                                            <td className='bodyGrid'>{emp.value}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className='table-model-name'>Tham số mô hình theo dõi</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='imageModel'>
                                <Button className='container-content-button' onClick={handleToggleImageTracking} style={{ borderRadius: 10, marginLeft: 10, marginTop: 20, marginBottom: 20 }}>
                                    {showTracking ? 'Ẩn' : 'Xem chi tiết'}
                                </Button>
                                {showTracking && (
                                    <div>
                                        <div className='image-container'>
                                            <div className='image-block'>
                                                <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                                    <img src={track2} alt="Tracking 2" className='image' />
                                                </a>
                                                <div className='image-name'>Kết quả test tập dữ liệu 1 - DeepSort</div>
                                                {isOpenLightBox && (
                                                    <Lightbox
                                                        mainSrc={track2}
                                                        onCloseRequest={() => setIsOpenLightBox(false)}
                                                    />
                                                )}
                                            </div>
                                            <div className='image-block'>
                                                <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                                    <img src={track3} alt="Tracking 3" className='image' />
                                                </a>
                                                <div className='image-name'>Kết quả test tập dữ liệu 2 - DeepSort</div>
                                                {isOpenLightBox && (
                                                    <Lightbox
                                                        mainSrc={track3}
                                                        onCloseRequest={() => setIsOpenLightBox(false)}
                                                    />
                                                )}
                                            </div>
                                            <div className='image-block'>
                                                <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                                    <img src={track4} alt="Tracking 4" className='image' />
                                                </a>
                                                <div className='image-name'>Kết quả test tập dữ liệu 1 - DeepSort cải tiến</div>
                                                {isOpenLightBox && (
                                                    <Lightbox
                                                        mainSrc={track4}
                                                        onCloseRequest={() => setIsOpenLightBox(false)}
                                                    />
                                                )}
                                            </div>
                                            <div className='image-block'>
                                                <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightbox(); }}>
                                                    <img src={track5} alt="Tracking 5" className='image' />
                                                </a>
                                                <div className='image-name'>Kết quả test tập dữ liệu 2 - DeepSort cải tiến</div>
                                                {isOpenLightBox && (
                                                    <Lightbox
                                                        mainSrc={track5}
                                                        onCloseRequest={() => setIsOpenLightBox(false)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                } */}

                <Modal
                    title="Dữ liệu huấn luyện"
                    open={isShowModalTracking}
                    onOk={() => {
                        setIsShowModalTracking(false)
                        setLstImageTrackingLength(0)
                    }}
                    onCancel={() => {
                        setIsShowModalTracking(false)
                        setLstImageTrackingLength(0)
                    }
                    }
                    width={1000}
                    style={{ maxWidth: '1000px' }}
                    className='modal-tracking'
                    footer={false}
                >
                    <div className='modal-tracking-main'>
                        <div className='modal-tracking-main-title'>MÔ HÌNH PHÂN LOẠI CHẤT LƯỢNG QUẢ</div>
                        <div style={{ textAlign: 'end', marginBottom: 10 }}>
                            <Button
                                onClick={() => {
                                    setIsShowModalTracking(false)
                                    setIsShowModalAddSampleTracking(true)
                                }}
                            >Thêm tập dữ liệu</Button>
                        </div>
                        <Table columns={columns} dataSource={dataTableTracking} scroll={{ y: 250 }} />
                    </div>
                </Modal>
                <Modal
                    title="Dữ liệu huấn luyện"
                    open={isShowModalDetect}
                    onOk={() => {
                        setIsShowModalDetect(false)
                        setLstImageDetectLength(0)
                        setCurrentPage(1)
                    }}
                    onCancel={() => {
                        setIsShowModalDetect(false)
                        setLstImageDetectLength(0)
                        setCurrentPage(1)
                    }}
                    width={1000}
                    style={{ maxWidth: '1000px' }}
                    className='modal-tracking'
                    footer={false}
                >
                    <div className='modal-tracking-main'>
                        <div className='modal-tracking-main-title'>MÔ HÌNH PHÁT HIỆN QUẢ TRONG ẢNH</div>
                        <div style={{ textAlign: 'end', marginBottom: 10 }}>
                            <Button
                                onClick={() => {
                                    setIsShowModalDetect(false)
                                    setIsShowModalAddSampleDetect(true)
                                }}
                            >Thêm tập dữ liệu</Button>
                        </div>
                        <Table columns={columns} dataSource={dataTableDetect} scroll={{ y: 250 }} />
                    </div>
                </Modal>

                <Modal
                    title="Thêm tập dữ liệu phát hiện quả"
                    open={isShowModalAddSampleDetect}
                    onOk={() => {
                        setIsShowModalAddSampleDetect(false)
                        setIsShowModalDetect(true)

                    }}
                    onCancel={() => {
                        setIsShowModalAddSampleDetect(false)
                        setIsShowModalDetect(true)

                    }}
                    footer={false}
                    width={600}
                    style={{ maxWidth: '600px' }}
                    className='modal-tracking'>
                    <div className='modal-tracking-main tracking'>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            form={formDetect}
                            onFinish={handleFinishDetect}
                        >
                            <Form.Item
                                label="Tên kho"
                                name="farmName"
                                rules={[{ required: true, message: 'Vui lòng chọn kho!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn kho"
                                    optionFilterProp="children"
                                    className='select-farm'
                                    onChange={onChangeFarm}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {lstFarm && lstFarm.map((item: any, index: any) => {
                                        return (
                                            <Select.Option value={item._id} key={index}>{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>

                            {/* {lstPen &&
                            <Form.Item
                                label="Tên chuồng"
                                name="penName"
                                rules={[{ required: true, message: 'Vui lòng chọn chuồng!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn chuồng"
                                    optionFilterProp="children"
                                    className='select-farm'
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {lstPen && lstPen.map((item: any, index: any) => {
                                        return (
                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        } */}

                            <Form.Item
                                label="Mẫu và nhãn mô hình"
                                name="modelFile"
                                rules={[
                                    // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                    { validator: customValidationFile },
                                ]}
                            >
                                <div className='action-tracking'>
                                    <label className="custom-file-upload">
                                        <input
                                            type="file"
                                            multiple
                                            key={inputKey}
                                            onChange={handleFileChange}
                                            style={{ position: 'absolute', left: '0', top: '0', opacity: '0', cursor: 'pointer' }}
                                        />
                                        <span>Tải tài liệu lên</span>
                                    </label>
                                </div>
                                <div className="selected-images file">
                                    {selectedFiles.length > 0 && (
                                        renderSelectedFile()
                                    )}
                                </div>
                            </Form.Item>
                            <Form.Item >
                                <div className='lst-btn-submit'>
                                    <Button key="back"
                                        onClick={() => {
                                            setIsShowModalAddSampleDetect(false)
                                            setIsShowModalDetect(true)
                                        }}
                                    >
                                        Hủy bỏ
                                    </Button>
                                    <Button type="primary" htmlType="submit"
                                    >
                                        Lưu
                                    </Button>
                                </div>

                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    title="Thêm tập dữ liệu phân loại chất lượng quả"
                    visible={isShowModalAddSampleTracking}
                    onOk={() => {
                        setIsShowModalAddSampleTracking(false)
                        setIsShowModalTracking(true)
                    }}
                    onCancel={() => {
                        setIsShowModalAddSampleTracking(false)
                        setIsShowModalTracking(true)
                    }}
                    width={500}
                    style={{ maxWidth: '500px' }}
                    className='modal-tracking'
                    footer={false}

                >
                    <div className='modal-tracking-main tracking'>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            form={formTracking}
                            onFinish={handleFinishTracking}
                        >
                            <Form.Item
                                label="Tên kho"
                                name="farmName"
                                rules={[{ required: true, message: 'Vui lòng chọn kho!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn kho"
                                    optionFilterProp="children"
                                    className='select-farm'
                                    onChange={onChangeFarm}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {lstFarm && lstFarm.map((item: any, index: any) => {
                                        return (
                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>

                            {/* {lstPen &&
                            <Form.Item
                                label="Tên chuồng"
                                name="penName"
                                rules={[{ required: true, message: 'Vui lòng chọn chuồng!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn chuồng"
                                    optionFilterProp="children"
                                    className='select-farm'
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {lstPen && lstPen.map((item: any, index: any) => {
                                        return (
                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        } */}
                            <Form.Item
                                label="Mẫu và nhãn mô hình"
                                name="modelFile"
                                rules={[
                                    // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                    { validator: customValidationFile },
                                ]}
                            >
                                <div className='action-tracking'>
                                    <label className="custom-file-upload">
                                        <input
                                            type="file"
                                            accept='image/*'
                                            multiple
                                            key={inputKey}
                                            onChange={handleFileChange}
                                        />
                                        <span>Tải tài liệu lên</span>
                                    </label>
                                </div>
                                <div className="selected-images file">
                                    {selectedFiles.length > 0 && (
                                        renderSelectedFile()
                                    )}
                                </div>
                            </Form.Item>
                            {/* <Form.Item
                            label="Mẫu mô hình"
                            name="modelType"
                            rules={[
                                // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                { validator: customValidation },
                            ]}
                        >
                            <div className='action-tracking'>
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        key={inputKey}
                                        onChange={handleFileInputChange}
                                    />
                                    <span>Tải ảnh lên</span>
                                </label>
                            </div>
                            <div className="selected-images">
                                {selectedImages.length > 0 && (
                                    renderSelectedImages()
                                )}
                            </div>
                        </Form.Item> */}

                            <Form.Item >
                                <div className='lst-btn-submit'>
                                    <Button key="back"
                                        onClick={() => {
                                            setIsShowModalAddSampleTracking(false)
                                            setIsShowModalTracking(true)
                                        }}
                                    >
                                        Hủy bỏ
                                    </Button>
                                    <Button type="primary" htmlType="submit"
                                    // onClick={() => {
                                    //     setIsShowModalAddSampleTracking(false)
                                    //     setIsShowModalTracking(true)
                                    //     handleFileUpload()
                                    // }}
                                    >
                                        Lưu
                                    </Button>
                                </div>

                            </Form.Item>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    title="Thêm mẫu phân loại"
                    visible={isShowModalAddSampleTrackingById}
                    onOk={() => {
                        setIsShowModalAddSampleTrackingById(false)
                        setIsShowModalTracking(true)
                    }}
                    onCancel={() => {
                        setIsShowModalAddSampleTrackingById(false)
                        setIsShowModalTracking(true)
                    }}
                    width={500}
                    style={{ maxWidth: '500px' }}
                    className='modal-tracking'
                    footer={false}
                >
                    <div className='modal-tracking-main tracking'>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            form={formTrackingById}
                            onFinish={handleFinishTrackingById}
                        >
                            {/* <Form.Item
                            label="Mẫu mô hình"
                            name="modelType"
                            rules={[
                                // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                { validator: customValidation },
                            ]}
                        >
                            <div className='action-tracking'>
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        key={inputKey}
                                        onChange={handleFileInputChange}
                                    />
                                    <span>Tải ảnh lên</span>
                                </label>
                            </div>
                            <div className="selected-images">
                                {selectedImages.length > 0 && (
                                    renderSelectedImages()
                                )}
                            </div>
                        </Form.Item> */}
                            <Form.Item
                                label="Mẫu và nhãn mô hình"
                                name="modelFile"
                                rules={[
                                    // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                    { validator: customValidationFile },
                                ]}
                            >
                                <div className='action-tracking'>
                                    <label className="custom-file-upload">
                                        <input
                                            type="file"
                                            accept='image/*'
                                            multiple
                                            key={inputKey}
                                            onChange={handleFileChange}
                                        />
                                        <span>Tải tài liệu lên</span>
                                    </label>
                                </div>
                                <div className="selected-images file">
                                    {selectedFiles.length > 0 && (
                                        renderSelectedFile()
                                    )}
                                </div>
                            </Form.Item>
                            <Form.Item >
                                <div className='lst-btn-submit'>
                                    <Button key="back"
                                        onClick={() => {
                                            setIsShowModalAddSampleTrackingById(false)
                                            setIsShowModalTracking(true)
                                        }}
                                    >
                                        Hủy bỏ
                                    </Button>
                                    <Button type="primary" htmlType="submit"
                                    >
                                        Lưu
                                    </Button>
                                </div>

                            </Form.Item>

                        </Form>
                    </div>
                </Modal>

                <Modal
                    title="Thêm mẫu phát hiện"
                    visible={isShowModalAddSampleDetectById}
                    onOk={() => {
                        setIsShowModalAddSampleDetectById(false)
                        setIsShowModalDetect(true)
                    }}
                    onCancel={() => {
                        setIsShowModalAddSampleDetectById(false)
                        setIsShowModalDetect(true)
                    }}
                    width={500}
                    style={{ maxWidth: '500px' }}
                    className='modal-tracking'
                    footer={false}
                >
                    <div className='modal-tracking-main tracking'>
                        <Form
                            name="basic"
                            initialValues={{ remember: true }}
                            form={formDetectById}
                            onFinish={handleFinishDetectById}
                        >
                            <Form.Item
                                label="Mẫu và nhãn mô hình"
                                name="modelFile"
                                rules={[
                                    // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                    { validator: customValidationFile },
                                ]}
                            >
                                <div className='action-tracking'>
                                    <label className="custom-file-upload">
                                        <input
                                            type="file"
                                            multiple
                                            key={inputKey}
                                            onChange={handleFileChange}
                                        />
                                        <span>Tải tài liệu lên</span>
                                    </label>
                                </div>
                                <div className="selected-images file">
                                    {selectedFiles.length > 0 && (
                                        renderSelectedFile()
                                    )}
                                </div>
                            </Form.Item>
                            <Form.Item >
                                <div className='lst-btn-submit'>
                                    <Button key="back"
                                        onClick={() => {
                                            setIsShowModalAddSampleDetectById(false)
                                            setIsShowModalDetect(true)
                                        }}
                                    >
                                        Hủy bỏ
                                    </Button>
                                    <Button type="primary" htmlType="submit"
                                    >
                                        Lưu
                                    </Button>
                                </div>

                            </Form.Item>

                        </Form>
                    </div>
                </Modal>



                <Modal
                    title="Chỉnh sửa mẫu phát hiện"
                    visible={isShowModalEditSampleDetect}
                    onOk={() => {
                        setIsShowModalEditSampleDetect(false)
                        setIsShowModalDetect(true)
                    }}
                    onCancel={() => {
                        setIsShowModalEditSampleDetect(false)
                        setIsShowModalDetect(true)
                    }}
                    width={1000}
                    style={{ maxWidth: '1000px' }}
                    className='modal-tracking'
                    footer={[
                        <Button key="back" onClick={() => {
                            setIsShowModalEditSampleDetect(false)
                            setIsShowModalDetect(true)
                        }}>
                            Quay lại
                        </Button>,
                        <Button key="submit" type="primary" onClick={() => {
                            setIsShowModalEditSampleDetect(false)
                            setIsShowModalDetect(true)
                        }}>
                            Thêm mẫu
                        </Button>,
                    ]}
                >
                    <div className='modal-tracking-main tracking'>
                        <Table
                            className='table-image'
                            columns={columnsEdit}
                            dataSource={lstImageDetect}
                            scroll={{ y: 350 }}
                            pagination={paginationConfigImage}
                        />
                        <div className='pagination-number'>
                            <span>
                                {numberPagination}
                            </span>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="Chỉnh sửa mẫu theo dõi"
                    visible={isShowModalEditSampleTracking}
                    onOk={() => {
                        setIsShowModalEditSampleTracking(false)
                        setIsShowModalTracking(true)
                    }}
                    onCancel={() => {
                        setIsShowModalEditSampleTracking(false)
                        setIsShowModalTracking(true)
                    }}
                    width={1000}
                    style={{ maxWidth: '1000px' }}
                    className='modal-tracking'
                    footer={[
                        <Button key="back" onClick={() => {
                            setIsShowModalEditSampleTracking(false)
                            setIsShowModalTracking(true)
                        }}>
                            Quay lại
                        </Button>,
                        // <Button key="submit" type="primary" onClick={() => {
                        //     setIsShowModalEditSampleTracking(false)
                        //     setIsShowModalTracking(true)
                        //     handleFileUpload()
                        // }}>
                        //     Thêm mẫu
                        // </Button>,
                    ]}
                >
                    <div className='modal-tracking-main tracking'>
                        <Table
                            className='table-image'
                            columns={columnsEdit}
                            dataSource={lstImageTracking}
                            scroll={{ y: 350 }}
                            pagination={paginationConfigImage}
                        />
                        <div className='pagination-number'>
                            <span>
                                {numberPagination}
                            </span>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="Xóa mẫu"
                    visible={isShowDeleteTracking}
                    onOk={() => {
                        setIsShowDeleteTracking(false)
                        setIsShowModalEditSampleTracking(true)
                    }}
                    onCancel={() => {
                        setIsShowDeleteTracking(false)
                        setIsShowModalEditSampleTracking(true)
                    }}
                    footer={false}
                >
                    <div className='modal-delete'>
                        <div className='modal-delete-title'>Bạn có chắc chắn muốn xóa mẫu này?</div>
                        <div className='modal-delete-buttons'>
                            <Button onClick={() => {
                                setIsShowDeleteTracking(false)
                                setIsShowModalEditSampleTracking(true)
                            }}>Hủy bỏ</Button>
                            <Button type='primary' onClick={() => handleDeleteTrackingImageModal()}>Xóa</Button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="Xóa mẫu"
                    visible={isShowDeleteDetect}
                    onOk={() => {
                        setIsShowDeleteDetect(false)
                        setIsShowModalEditSampleDetect(true)
                    }}
                    onCancel={() => {
                        setIsShowDeleteDetect(false)
                        setIsShowModalEditSampleDetect(true)
                    }}
                    footer={false}
                >
                    <div className='modal-delete'>
                        <div className='modal-delete-title'>Bạn có chắc chắn muốn xóa mẫu này?</div>
                        <div className='modal-delete-buttons'>
                            <Button onClick={() => {
                                setIsShowDeleteDetect(false)
                                setIsShowModalEditSampleDetect(true)
                            }}>Hủy bỏ</Button>
                            <Button type='primary' onClick={() => handleDeleteDetectImageModal()}>Xóa</Button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title="Lịch sử huấn luyện"
                    visible={isShowTrainHistory}
                    onOk={() => setIsShowTrainHistory(false)}
                    onCancel={() => setIsShowTrainHistory(false)}
                    footer={false}
                    width={1000}
                    style={{ maxWidth: '1000px' }}
                    className='modal-tracking'
                >
                    <div className='modal-tracking-main'>
                        <Table columns={columnsTrain} dataSource={dataTableTrain} scroll={{ y: 250 }} />
                    </div>
                </Modal>
            </div>
            <Modal
                open={isModalOpen}
                onCancel={closeModal}
                width={600}
                footer={false}
                style={{ maxWidth: 600 }}
            >
                <div>
                    <img
                        src={selectedImage}
                        alt="Large Image"
                        style={{ width: '100%', height: 'auto' }}
                    />
                    {/* <Button onClick={closeModal}>Đóng</Button> */}
                </div>
            </Modal>
            <Modal
                open={isModalOpenTracking}
                onCancel={closeModalTracking}
                footer={false}
                style={{ maxHeight: 600, }}
            >
                <img
                    src={selectedImage}
                    alt="Large Image"
                    style={{ width: '100%', height: '600px' }}
                />
                {/* <Button onClick={closeModal}>Đóng</Button> */}
            </Modal>

            <Modal
                open={isModalLabelOpen}
                onCancel={closeModalLabel}
                onOk={closeModalLabel}
                // footer={true}
                className='render-label'
            // style={{ top: 80, height: '450px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', zIndex: 9999 }}
            >
                {selectedLabel.map((label: string, index) => (
                    <div key={index}>{label}</div>
                ))}
                {/* <Button onClick={closeModal}>Đóng</Button> */}
            </Modal>
        </div>
    )
}

export default ModelManageDetect
