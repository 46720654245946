import { MoreOutlined } from '@ant-design/icons';
import { Avatar, Badge, Form, List, Menu, MenuProps, Modal } from 'antd';
import VirtualList from 'rc-virtual-list';
import { useEffect, useState } from 'react';
// import 'react-pro-sidebar/dist/css/styles.css';
import Sider from 'antd/es/layout/Sider';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CFG_BASE_URL, WEBRTC_STREAMING } from '../../constants/config';
import UnreadIcon from '../../images/icon_unread.png';
import './notification.css';
import { useSelectorRoot } from '../../redux/store';
import { getAllNotificationByIdUserRequest } from '../../redux/controller';
import { useDispatch } from 'react-redux';
interface MyProps {
    isOpenModal: boolean;
    toggleNotification: () => void;
}
const items: MenuProps['items'] = [
    {
        label: (
            <div className='notification-button' >Tất cả</div>
        ),
        key: -1,
    }
    , {
        label: (
            <div className='notification-button' >Đã xem</div>
        ),
        key: 1,
    },
    {
        label: (
            <div className='notification-button' >Chưa xem</div>
        ),
        key: 0,
    },

];
const PAGE_SIZE = 100;
const ContainerHeight = 400;
const Notification = (props: MyProps) => {

    const { lstNotification, lstSeenNotification, lstUnSeenNotification } = useSelectorRoot((state) => state.notification);
    // const [lstNotification, setLstNotification] = useState<any[]>([]);
    // const [lstSeenNotification, setLstSeenNotification] = useState<any[]>([]);
    // const [lstUnSeenNotification, setLstUnSeenNotification] = useState<any[]>([]);
    const [statusNotification, setStatusNotification] = useState<any>(-1);
    // const [data, setData] = useState<any[]>();

    const [notifications, setNotifications] = useState<any[]>([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const appendData = () => {
    //     fetch(fakeDataUrl)
    //         .then((res) => res.json())
    //         .then((body) => {
    //             setData(data.concat(body.results));
    //         });
    // };

    useEffect(() => {
        console.log('statusNotification', statusNotification);
        // getAllNotification();
    }, [])

    useEffect(() => {
        console.log('statusNotification', statusNotification);
    }, [statusNotification])

    const getAllNotification = async () => {

        // if (loading || !hasMore) return;

        setLoading(true);

        let userId = sessionStorage.getItem('userId') !== null ? sessionStorage.getItem('userId') : ''
        if (userId) {
            userId = userId.slice(1);
            userId = userId.slice(0, userId.length - 1);
        }

        let token = sessionStorage.getItem('token') !== null ? sessionStorage.getItem('token') : ''
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
        }


        let body;
        if (statusNotification === -1) {
            body = {
                offset: offset,
                size: PAGE_SIZE,
                additionalProp1: {}
            }
        }
        else {
            body = {
                offset: offset,
                size: PAGE_SIZE,
                status: statusNotification,
                additionalProp1: {}
            }
        }
        console.log('body', body);



        await axios.post(`${CFG_BASE_URL}/notification/ownerId`, body, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {

            console.log(res.data.data.items);

            const newNotifications = res.data.data.items.map((item: any) => {

                let typeFruit = '';

                if (item.content.includes('Tốt')) {
                    typeFruit = 'good';
                }
                if (item.content.includes('Sắp hỏng')) {
                    typeFruit = 'bad';
                }
                if (item.content.includes('Hỏng')) {
                    typeFruit = 'rotten';
                }
                if (item.content.includes('Xuất kho')) {
                    typeFruit = 'move';
                }

                return {
                    ...item,
                    typeFruit: typeFruit
                }

            });
            // setLstNotification(prev => [...prev, ...newNotifications]);
            // setLstNotification(newNotifications);
            // setLstSeenNotification(newNotifications.filter((item: any) => item.status === 1));
            // setLstUnSeenNotification(newNotifications.filter((item: any) => item.status === 0));

            setOffset(prev => prev + PAGE_SIZE);
            setHasMore(newNotifications.length === PAGE_SIZE);
        })
            .catch((err) => {
                console.log(err);
            })

    }

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
            // getAllNotification();
        }
    };

    // useEffect(() => {
    //     appendData();
    // }, [lstNotification,]);

    const changeNotificationStatus = (key: string) => {
        setStatusNotification(parseInt(key));
        // setLstNotification([]);
        setOffset(0);
    }
    const handleOnClickNotification = async (item: any) => {
        // dispatch(setStatusOfNotificationRequest(item.id));
        // console.log(item);
        await axios.put(`${CFG_BASE_URL}/notification/changeStatus/${item.id}`)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            })

        navigate('/camera_detail/' + item.camera.id);
        props.toggleNotification();

        const stringNotification = statusNotification.toString();

        changeNotificationStatus(stringNotification);

        const req = {
            offset: 0,
            size: 100,
            additionalProp1: {},
        }

        dispatch(getAllNotificationByIdUserRequest(req))
    }

    const formatTime = (item: any) => {
        // function timeSince(date) {

        const currentTime = new Date(); //Ngay gio hien tai
        const createdTime = new Date(item.time); //Ngay gio thong bao duoc tao ra
        console.log(item);
        console.log("Thời gian hiện tại: ", currentTime);
        console.log("Thoi gian thong bao duoc tao: ", createdTime)

        const timeDifference = currentTime.getTime() - createdTime.getTime(); // Sự khác biệt thời gian tính bằng milliseconds

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        let timeAgo;

        if (days > 0) {
            timeAgo = `${days} ngày trước`;
        } else if (hours > 0) {
            timeAgo = `${hours} giờ trước`;
        } else if (minutes > 0) {
            timeAgo = `${minutes} phút trước`;
        } else {
            timeAgo = `${seconds} giây trước`;
        }

        console.log(item);
        console.log("Thời gian hiện tại: ", currentTime);
        console.log("Thời gian thông báo được tạo: ", createdTime);
        console.log("Khoảng thời gian trước: ", timeAgo);

        return timeAgo;

        //   }

    }


    return (
        <Modal
            className='notification-modal'
            title=""
            open={props.isOpenModal}
            onOk={props.toggleNotification}
            onCancel={props.toggleNotification}
            footer={false}
        >
            <Form
                name="normal_login"
                className="notification-form"
                initialValues={{ remember: true }}
            >
                <div className='notification-title'>
                    <div className='notification-title-text'>Thông báo</div>
                    <MoreOutlined style={{ cursor: 'pointer' }} />
                </div>
                <Sider className='notification-slider'>
                    <Menu
                        defaultSelectedKeys={['-1']}
                        mode="inline"
                        items={items}
                        onClick={({ key }) => { changeNotificationStatus(key) }}
                    />
                </Sider>
                <div className='notification-list notification-new'>
                    {/* <div className='notification-list-title'>Mới nhất</div> */}
                    <div className='notification-list-item'>
                        <List>
                            <VirtualList
                                data={
                                    statusNotification === -1 ? lstNotification : statusNotification === 0 ? lstUnSeenNotification : lstSeenNotification
                                } // Lan dau vao se set luon cho la bang lst tong hop thong bao. Cac lan sau se co ham set lai 
                                height={ContainerHeight}
                                itemHeight={47}
                                itemKey="email"
                                onScroll={onScroll}
                            >
                                {(item: any) => (
                                    <List.Item key={item.email} onClick={() => handleOnClickNotification(item)}>
                                        <List.Item.Meta
                                            avatar={
                                                <Badge
                                                    count={
                                                        <div style={{
                                                            backgroundColor: 'white',
                                                            borderRadius: '50%',
                                                        }}>
                                                            {item.typeFruit === 'good' && <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024"><path fill="#52c41a" d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896m-55.808 536.384l-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.27 38.27 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336z" /></svg>}
                                                            {item.typeFruit === 'bad' && <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#faad14" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" /></svg>}
                                                            {item.typeFruit === 'rotten' && <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32"><path fill="#ff4d4f" d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m5.4 21L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z" /></svg>}
                                                            {item.typeFruit === 'move' && <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 26 26"><g fill="none"><defs><mask id="pepiconsPopMoveXCircleFilled0"><path fill="#fff" d="M0 0h26v26H0z" /><g fill="#000" fill-rule="evenodd" clip-rule="evenodd"><path d="M16.026 8.232a1 1 0 0 1 1.409.128l3.333 4a1 1 0 1 1-1.536 1.28l-3.334-4a1 1 0 0 1 .128-1.408" /><path d="M16.026 17.768a1 1 0 0 1-.128-1.408l3.334-4a1 1 0 1 1 1.536 1.28l-3.333 4a1 1 0 0 1-1.408.128" /><path d="M19 13a1 1 0 0 1-1 1h-8a1 1 0 1 1 0-2h8a1 1 0 0 1 1 1m-9.026 4.768a1 1 0 0 1-1.409-.128l-3.333-4a1 1 0 1 1 1.536-1.28l3.334 4a1 1 0 0 1-.128 1.408" /><path d="M9.974 8.232a1 1 0 0 1 .128 1.408l-3.334 4a1 1 0 1 1-1.536-1.28l3.333-4a1 1 0 0 1 1.409-.128" /><path d="M7 13a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1" /></g></mask></defs><circle cx="13" cy="13" r="13" fill="#1677ff" mask="url(#pepiconsPopMoveXCircleFilled0)" /></g></svg>}
                                                        </div>
                                                    }
                                                    offset={[-10, 50]}
                                                >
                                                    <Avatar src={`${WEBRTC_STREAMING}/rtc/noti/${item.id}`} />
                                                </Badge>}
                                            title={item.status === 0 ? <div>{item.title}</div> : <div style={{ color: "#858585" }}>{item.title}</div>}
                                            description={<div>
                                                <div className='notification-des'>{item.content}</div>
                                                {item.status === 0 ? <div className='notification-time'>{formatTime(item)}</div> : <div className='notification-time' style={{ color: "#858585" }}>{formatTime(item)}</div>}
                                                {/* <div className='notification-time'>{formatTime(item)}</div> */}
                                            </div>}
                                        />
                                        {item.status === 0 ? <img alt='' src={UnreadIcon} /> : <></>}
                                    </List.Item>
                                )}
                            </VirtualList>
                        </List>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

export default Notification