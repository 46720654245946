import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
interface LineChartProps {
    lstUser: any[]
    yearSelect: any
}

const LineChart = (props: LineChartProps) => {
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [numberUser, setNumberUser] = useState<any>();
    const [series, setSeries] = useState<any>();
    const [options, setOptions] = useState<any>();

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        const data = props.lstUser;
        if (data && data.length > 0) {
            // Lọc ra những người dùng đăng ký trong năm yearSelect
            const dataFilter = data.filter((item: any) => {
                return new Date(item.createTime).getFullYear() === parseInt(props.yearSelect);
            });

            console.log(dataFilter);

            // Khởi tạo mảng để lưu số lượng user trong mỗi tháng
            const monthlyUserCount = Array(12).fill(0);

            console.log(monthlyUserCount);
            

            // // Duyệt qua dữ liệu và tăng số lượng user tương ứng với tháng
            dataFilter.forEach(user => {
                const month = new Date(user.createTime).getMonth(); // Lấy tháng từ 0-11
                monthlyUserCount[month]++;
            });

            console.log(monthlyUserCount);

            setSeries([
                {
                    name: "Số lượng người dùng",
                    data: monthlyUserCount
                }
            ])

            const chartOptions = {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    zoom: {
                        enabled: true
                    },
                    toolbar: {
                        show: true
                    }
                },
                colors: ['#6366f1'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    display: false,

                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
                    // title: {
                    //     text: 'Month'
                    // }
                },
                yaxis: {
                    // title: {
                    //     text: 'Số lượng người dùng',
                    // },
                    min: 0,
                    stepSize: 1,
                    // tickAmount: 5,
                    max: data.length > 0 ? Math.max(...monthlyUserCount) + 1 : 0
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    floating: true,
                    offsetY: 0,
                    offsetX: 0
                }
            }

            setOptions(chartOptions);

        }
    }, [props.lstUser, props.yearSelect]);


    // const series = [
    //     {
    //         name: "Số lượng người dùng",
    //         data: [10, 29, 33, 36, 32, 32, 33]
    //     },
    // ]

    // const options: any = {
    //     chart: {
    //         height: 350,
    //         type: 'line',
    //         dropShadow: {
    //             enabled: true,
    //             color: '#000',
    //             top: 18,
    //             left: 7,
    //             blur: 10,
    //             opacity: 0.2
    //         },
    //         zoom: {
    //             enabled: true
    //         },
    //         toolbar: {
    //             show: true
    //         }
    //     },
    //     colors: ['#6366f1'],
    //     dataLabels: {
    //         enabled: true,
    //     },
    //     stroke: {
    //         curve: 'smooth'
    //     },
    //     title: {
    //         display: false,

    //     },
    //     grid: {
    //         borderColor: '#e7e7e7',
    //         row: {
    //             colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
    //             opacity: 0.5
    //         },
    //     },
    //     markers: {
    //         size: 1
    //     },
    //     xaxis: {
    //         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    //         // title: {
    //         //     text: 'Month'
    //         // }
    //     },
    //     yaxis: {
    //         // title: {
    //         //     text: 'Số lượng người dùng',
    //         // },
    //         min: 5,
    //         max: 40
    //     },
    //     legend: {
    //         position: 'top',
    //         horizontalAlign: 'center',
    //         floating: true,
    //         offsetY: 0,
    //         offsetX: 0
    //     }
    // }

    return <div>
        <div className='bar-chart'>
            {series && options &&
                <ReactApexChart options={options} series={series} type="line" height={350} width={(windowSize[0] / 2) - 100} />
            }
        </div>
        <div id="html-dist"></div>
    </div>;
};

export default LineChart;
