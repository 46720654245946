/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Avatar, Badge, Button, DatePicker, Dropdown, Form, Input, MenuProps, Modal, notification, Tooltip } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { WarehouseRequest } from '../../common/define-fruit'
import Utils from '../../common/utils'
import NotificationIcon from '../../images/Notification_icon.png'
import AddCamera from '../../images/add_video.png'
import UserIcon from '../../images/user_icon.png'
import AddCameraActive from '../../images/video_active_icon.png'
import AddCameraModel from '../../pages/Camera/AddCameraModel'
import LoginModal from '../../pages/Login/LoginModal'
import Notification from '../../pages/Notification/Notification'
import RegisterModal from '../../pages/Registration/RegisterModal'
import { getAllWarehouseByUserIdRequest, setSelectStatistics, setTokenPublic } from '../../redux/controller/fruit.slice'
import { useDispatchRoot, useSelectorRoot } from '../../redux/store'
import './header.scss'
import { FaUserCircle } from "react-icons/fa";
import { useForm } from 'antd/es/form/Form'
import moment from 'moment'
import axios from 'axios'
import { CFG_BASE_URL } from '../../constants/config'
import { fetchToken, onMessageListener } from '../../firebase';

// import CRegisterModal from './CRegisterModal';

interface MyProps {
    // setIsLogout: React.Dispatch<React.SetStateAction<boolean>>
}


export const Header = (props: MyProps) => {

    const [isLogin, setIsLogin] = useState<string>('')
    const [isOpenLoginModal, setIsOpenLoginModal] = useState<boolean>(false) // Biến kiểm tra đang mở modal login hay chưa
    const [isOpenRegisterModal, setIsOpenRegisterModal] = useState<boolean>(false) // Biến kiểm tra đang mở modal registration hay chưa
    const [isOpenAddCameraModal, setIsOpenAddCameraModal] = useState<boolean>(false) // Biến kiểm tra đang mở modal add camera hay chưa
    const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false) // Biến kiểm tra đang mở notification hay không
    const [cameraIcon, setCameraIcon] = useState<string>(AddCamera) // Camera icon
    const [userName, setUserName] = useState<string>('')
    const [userEmail, setUserEmail] = useState<string>('')
    const [clickedWareHouseId, setClickedWareHouseId] = useState<string>('')
    // const [numberOfUnseenNotification,setNumberOfUnseenNotification] = useState(9);

    const { lstNotification, numberOfUnseenNotification } = useSelectorRoot((state) => state.notification);
    const { tokenPublic } = useSelectorRoot((state) => state.fruit);
    const [roleName, setRoleName] = useState<any>('')


    const dispatch = useDispatchRoot();
    const navigate = useNavigate();

    const [buttonActive, setButtonActive] = useState<any>(1)
    const [isClickEditInfo, setIsClickEditInfo] = useState<boolean>(false)
    const [openModalChangePassword, setOpenModalChangePassword] = useState<boolean>(false)
    const [formEdit] = useForm();
    const [formChangePassword] = useForm();
    const [userInfo, setUserInfo] = useState<any>({})
    const [isTokenFound, setTokenFound] = useState<string>('');
    const [notificationBrowser, setNotificationBrowser] = useState({ title: '', body: '' });
    const [show, setShow] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (userInfo) {
            fetchToken(setTokenFound);
        }
    }, [userInfo]);

    onMessageListener()
        .then(payload => {
            if (!userInfo) return;
            setNotificationBrowser({ title: payload.notification.title, body: payload.notification.body })
            setShow(true);
            console.log(payload);
            // notification.open({
            //     message: payload.data.title,
            //     description: payload.data.body,
            // } as any);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 5000);
        })
        .catch(err => console.log('failed: ', err));

    useEffect(() => {
        console.log(isTokenFound);
        if (isTokenFound && userInfo) {
            dispatch(setTokenPublic(isTokenFound))
        }

    }, [isTokenFound])

    useEffect(() => {
        if (tokenPublic && userInfo) {
            console.log(tokenPublic);

            // postClientToken(tokenPublic);
        }
    }, [tokenPublic]);

    // const postClientToken = async (token: string) => {
    //     const req = {
    //         token: token,
    //         userId: userInfo?.id,
    //         additionalProp1: {}
    //     }
    //     await axios.post(`https://sit.api.pigman.com.vn/clientToken`, req)
    //         .then(res => {
    //             console.log(res);

    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // };


    useEffect(() => {

        // getAllNotification();

        const warehouseId = sessionStorage.getItem('warehouseId') ? sessionStorage.getItem('warehouseId') : '';
        console.log(warehouseId);

        if (warehouseId) {
            setClickedWareHouseId(warehouseId);
        }
    })

    useEffect(() => {
        const checkLogin = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : ''
        if (checkLogin) {
            setIsLogin(checkLogin);
            const usermail = sessionStorage.getItem('userEmail') ? sessionStorage.getItem('userEmail') : '';
            const username = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : '';
            const userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '';
            setUserEmail(usermail ? usermail : '');
            setUserName(username ? username : '');
        }
        console.log('lstNotification')

    });

    // const countUnseenNotification = ()=>{
    //     let countInter = 0;
    //     lstNotification?.forEach(item => {
    //         if(item.status===0) countInter +=1
    //     })
    //     return countInter
    // }

    useEffect(() => {
        console.log(numberOfUnseenNotification)
    }, [lstNotification])



    useEffect(() => {
        if (isLogin) {
            let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
            console.log(userId ? userId : '');
            if (userId) {
                userId = userId.slice(1);
                userId = userId.slice(0, userId.length - 1);
                const req: WarehouseRequest = {
                    "userId": userId,
                    "additionalProp1": {},
                };
                dispatch(getAllWarehouseByUserIdRequest(req))
            }
            let role = sessionStorage.getItem('role')

            setRoleName(role);
            getInfoUser();
        }
    }, [isLogin])

    const handleFormChange = () => {
        const hasErrors = formChangePassword.getFieldsError().some(({ errors }) => errors.length > 0);
        const allFieldsTouched = formChangePassword.isFieldsTouched(true);
        setIsFormValid(!hasErrors && allFieldsTouched);
        console.log(!hasErrors && allFieldsTouched);
        
    };


    useEffect(() => {
        if (window.location.pathname === '/home') {
            setButtonActive(1)
        }
        else if (window.location.pathname === '/feature') {
            setButtonActive(2)
        }
        else if (window.location.pathname === '/about') {
            setButtonActive(3)
        }
        else if (window.location.pathname === '/camera_manager' || window.location.pathname.includes('/camera_detail') || window.location.pathname.includes('/camera_history')) {
            setButtonActive(4)
        }
        else if (window.location.pathname === '/pack_manager' || window.location.pathname.includes('/crate_manager')) {
            setButtonActive(5)
        }
        else if (window.location.pathname === '/statistic') {
            setButtonActive(6)
        }
        else if (window.location.pathname === '/admin') {
            setButtonActive(7)
        }

        else if (window.location.pathname === '/user-manage') {
            setButtonActive(8)
        }
        else if (window.location.pathname === '/warehouse-manage') {
            setButtonActive(9)
        }
        else if (window.location.pathname === '/model-manage' || window.location.pathname === '/model-manage-detect' || window.location.pathname === '/model-manage-classify') {
            setButtonActive(10)
        }

        else if (window.location.pathname === '/policies-terms') {
            setButtonActive(11)
        }


    }, [window.location.pathname])

    const getInfoUser = async () => {
        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
        }
        await axios.get(`${CFG_BASE_URL}/users/get-user`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data.data);
            setUserInfo(res.data.data);

        }).catch(err => {
            console.log(err);
        })
    }


    const onClickChooseWareHouse = () => {
        navigate("/");
        window.location.reload();
        Utils.removeItemsessionStorage('warehouseId');
    }
    const onClickLogout = () => {
        sessionStorage.clear();
        localStorage.clear();
        setIsLogin('')
        navigate("/");
        // deleteClientToken();
        window.location.reload();
    }


    // const deleteClientToken = async () => {
    //     await axios.delete(`https://sit.api.pigman.com.vn/clientToken?userId=${userInfo?.id}&clientToken=${tokenPublic}`)

    // }

    const onEditInfo = () => {
        formEdit.setFieldsValue({
            name: userName.replace(/['"]+/g, ''),
            address: userInfo?.address,
            phone: userInfo?.phone,
            dob: moment(userInfo?.dob),
            farmName: userInfo?.farmName,
            defaultWarehouse: '',
        });

        setIsClickEditInfo(true);
    }

    const onChangePassword = () => {
        setOpenModalChangePassword(true)
    }

    const onFinishChangePassword = async (values: any) => {
        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
        }
        const req = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword
        }
        try {
            await axios.put(`${CFG_BASE_URL}/users/change-password`, req, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res.data.data);
                notification.success({
                    message: 'Thay đổi mật khẩu thành công'
                });
                setOpenModalChangePassword(false)
            }).catch(err => {
                console.log(err);
                notification.error({
                    message: 'Thay đổi mật khẩu thất bại',
                })
            })
        } catch (error) {
            console.log(error);
        }
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div onClick={onEditInfo}>
                    Cập nhật thông tin
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div onClick={onChangePassword}>
                    Đổi mật khẩu
                </div>
            ),
        },
        // {
        //     key: '2',
        //     label: (
        //         <div >
        //             Email: {userEmail}
        //         </div>
        //     ),
        // },
        // {
        //     key: '3',
        //     label: (
        //         (roleName !== '"ADMIN"') ?
        //             <Link to='/' onClick={onClickChooseWareHouse}>
        //                 Chọn kho
        //             </Link> : <div >
        //                 Quyền: ADMIN
        //             </div>
        //     ),
        // },
        {
            key: '4',
            label: (
                <Link to='/' onClick={onClickLogout}>
                    Đăng xuất
                </Link>
            ),
        },
    ];

    const menuItems: ItemType[] = [
        {
            key: '1',
            label: 'Mô hình phát hiện quả',
            onClick: () => {
                navigate('/model-manage-detect');
            },
        },
        {
            key: '2',
            label: 'Mô hình phân loại chất lượng',
            onClick: () => {
                navigate('/model-manage-classify');
            },
        },
    ];



    // Hàm chuyển đổi trạng thái đóng mở modal login
    const toggleLoginModal = () => {
        setIsOpenLoginModal(!isOpenLoginModal);
        setIsOpenRegisterModal(false);

    };
    // Hàm chuyển đổi trạng thái đóng mở modal registration
    const toggleRegisterModal = () => {
        setIsOpenLoginModal(false);
        setIsOpenRegisterModal(!isOpenRegisterModal);
        console.log('chay vao day');

    };

    const cancelLoginModal = () => {
        setIsOpenLoginModal(false);
    }

    const cancelRegisterModal = () => {
        setIsOpenRegisterModal(false);
    }

    // Hàm chuyển đổi trạng thái đóng mở modal add camera
    const toggleAddCameraModal = () => {
        setIsOpenAddCameraModal(false);
    };

    // Hàm chuyển đổi trạng thái đóng mở notification
    const toggleNotification = () => {
        setIsOpenNotification(false);
    };

    const onFinishEditInfo = async (values: any) => {
        console.log(values);

        let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (userId) {
            userId = userId.slice(1);
            userId = userId.slice(0, userId.length - 1);
        }

        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
        }

        console.log(userId);
        console.log(token);


        const req = {
            name: values.name,
            address: values.address,
            phone: values.phone,
            dob: values.dob,
            farmName: values.farmName || 'string',
            defaultWarehouse: 'string',
            additionalProp1: {},
        }

        await axios.put(`${CFG_BASE_URL}/users/update-user/${userId}`, req, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data);

            notification.success({
                message: 'Cập nhật thông tin thành công',
                placement: 'topRight',
            } as any);

            setUserName(values.name);

            sessionStorage.setItem('userName', JSON.stringify(values.name));

            setIsClickEditInfo(false);

            getInfoUser();

        }).catch(err => {
            console.log(err);
        })

    }

    const handleClickLogo = () => {
        if (roleName === '"ADMIN"')
            navigate('/admin');
        else navigate('/home');
    }


    return (
        <div className={'k-main-header' + (!userName ? ' not-user' : '')}>
            <div className={'k-header-content'}>
                <div className='k-header-content-left'>
                    <div className='header-logo' onClick={handleClickLogo}>
                        <span> AI FRUIT </span>
                    </div>


                    {(!userName)
                        &&
                        <div className='header-content-menu'>
                            <div className={'header-content-menu-item ' + (buttonActive === 1 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(1);
                                    navigate('/');
                                }}
                            >
                                Trang chủ
                            </div>
                            <div className={'header-content-menu-item ' + (buttonActive === 2 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(2);
                                    navigate('/feature');
                                }}
                            >
                                Tính năng
                            </div>
                            <div className={'header-content-menu-item ' + (buttonActive === 3 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(3);
                                    navigate('/about');
                                }}
                            >
                                Về chúng tôi
                            </div>

                            <div className={'header-content-menu-item ' + (buttonActive === 11 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(11);
                                    navigate('/policies-terms');
                                }}
                            >
                                Điều khoản và chính sách
                            </div>
                        </div>
                    }

                    {(userName && roleName !== '"ADMIN"')
                        &&
                        <div className='header-content-menu'>
                            <div className={'header-content-menu-item ' + (buttonActive === 1 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(1);
                                    navigate('/home');
                                }}
                            >
                                Tổng quan
                            </div>
                            <div className={'header-content-menu-item ' + (buttonActive === 5 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(5);
                                    navigate('/pack_manager');
                                }}
                            >
                                Quản lý kho
                            </div>
                            <div className={'header-content-menu-item ' + (buttonActive === 4 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(4);
                                    navigate('/camera_manager');
                                }}
                            >
                                Camera
                            </div>


                            {/* <Dropdown menu={{ items: menuItems }} placement="bottomLeft" arrow> */}
                            <div className={'header-content-menu-item ' + (buttonActive === 6 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(6);
                                    navigate('/statistic');
                                }}
                            >
                                Quản lý nhập xuất
                            </div>
                            {/* </Dropdown> */}

                        </div>
                    }
                    {(userName && roleName === '"ADMIN"')
                        &&
                        <div className='header-content-menu'>
                            <div className={'header-content-menu-item ' + (buttonActive === 7 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(7);
                                    navigate('/admin');
                                }}
                            >
                                Tổng quan
                            </div>
                            <div className={'header-content-menu-item ' + (buttonActive === 8 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(8);
                                    navigate('/user-manage');
                                }}
                            >
                                Quản lý người dùng
                            </div>
                            <div className={'header-content-menu-item ' + (buttonActive === 9 ? 'active' : '')}
                                onClick={() => {
                                    setButtonActive(9);
                                    navigate('/warehouse-manage');
                                }}
                            >
                                Quản lý trang trại
                            </div>

                            <Dropdown menu={{ items: menuItems }} placement="bottomLeft" arrow>
                                <div className={'header-content-menu-item ' + (buttonActive === 10 ? 'active' : '')}
                                    onClick={() => {
                                        setButtonActive(10);
                                        navigate('/model-manage-detect');
                                    }}
                                >
                                    Quản lý mô hình
                                </div>
                            </Dropdown>

                        </div>
                    }

                </div>

                <div className='header-content-info'>
                    {/* Kiểm tra nếu đã login chưa */}
                    {isLogin ?
                        // Nếu login rồi thì hiển thị giao diện đã login và ngược lại
                        <>
                            {/* {(roleName !== '"ADMIN"') ? <Button className='header-button'
                                onMouseEnter={() => setCameraIcon(AddCameraActive)}
                                onMouseLeave={() => setCameraIcon(AddCamera)}
                                onClick={() => setIsOpenAddCameraModal(true)}
                            >
                                <span className='header-button-text'>Thêm camera</span>
                            </Button> : <></>} */}
                            {/* <AddCameraModel
                                isOpenModal={isOpenAddCameraModal}
                                toggleAddCameraModal={toggleAddCameraModal}
                            /> */}
                            {
                                roleName !== '"ADMIN"' &&
                                <Tooltip title={userInfo?.farmName + ' - ' + userInfo?.address}>
                                    <div className='header-farmInfo'>
                                        {userInfo?.farmName ? userInfo?.farmName : ''}
                                        {' - '}
                                        {userInfo?.address ? userInfo?.address : ''}
                                    </div>
                                </Tooltip>

                            }
                            {roleName !== '"ADMIN"' &&
                                <div className='header-notification' onClick={() => setIsOpenNotification(!isOpenNotification)}>

                                    <Badge count={numberOfUnseenNotification}>
                                        <img style={{ width: '20px' }} src={NotificationIcon} />
                                    </Badge>
                                </div>}

                            <Notification
                                isOpenModal={isOpenNotification}
                                toggleNotification={toggleNotification}

                            />
                            <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                                <div className='header-avatar-info'>
                                    <FaUserCircle size={24} style={{ cursor: 'pointer' }} />
                                    {userName.replace(/['"]+/g, '')}
                                </div>
                            </Dropdown>

                        </>
                        :
                        <>
                            <Button className='header-button' style={{ marginRight: 15 }} onClick={() => setIsOpenLoginModal(true)}>
                                <span className='header-button-text-home'>Đăng nhập</span>
                            </Button>
                            <Button className='header-button' onClick={() => setIsOpenRegisterModal(true)}>
                                <span className='header-button-text-home'>Đăng ký</span>
                            </Button>
                            <LoginModal
                                isOpenModal={isOpenLoginModal}
                                toggleLoginModal={toggleLoginModal}
                                toggleRegisterModal={toggleRegisterModal}
                                cancelLoginModal={cancelLoginModal}
                            />
                            <RegisterModal
                                isOpenModal={isOpenRegisterModal}
                                toggleLoginModal={toggleLoginModal}
                                toggleRegisterModal={toggleRegisterModal}
                                cancelRegisterModal={cancelRegisterModal}
                            />
                        </>

                    }
                </div>
            </div>

            <Modal
                visible={isClickEditInfo}
                title="Chỉnh sửa thông tin"
                onCancel={() => setIsClickEditInfo(false)}
                footer={null}
                width={400}
                className='add-camera-modal'
            >
                <Form
                    form={formEdit}
                    layout="vertical"
                    onFinish={onFinishEditInfo}
                >
                    <Form.Item
                        label={
                            <div className="custom-label">
                                Họ và tên <span className="required-asterisk">*</span>
                            </div>
                        }
                        name="name"
                        rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                    >
                        <Input placeholder='Nhập họ tên' />
                    </Form.Item>
                    <Form.Item
                        label={
                            <div className="custom-label">
                                Địa chỉ <span className="required-asterisk">*</span>
                            </div>
                        }
                        name="address"
                        rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]} // Use rules for validation
                    >
                        <Input
                            placeholder='Nhập địa chỉ'
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <div className="custom-label">
                                Số điện thoại <span className="required-asterisk">*</span>
                            </div>
                        } name="phone"
                        rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]} // Add validation rule if phone is required
                    >
                        <Input
                            placeholder='Nhập số điện thoại'
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <div className="custom-label">
                                Ngày sinh <span className="required-asterisk">*</span>
                            </div>
                        } name="dob"
                        rules={[{ required: true, message: 'Vui lòng chọn ngày sinh' }]} // Add validation rule if dob is required
                    >
                        <DatePicker
                            placeholder='Chọn ngày sinh'
                            format={'DD/MM/YYYY'}
                            disabledDate={(current) => current && current > moment().endOf('day')}
                        />
                    </Form.Item>
                    {roleName !== '"ADMIN"' &&
                        <Form.Item
                            label={
                                <div className="custom-label">
                                    Tên trang trại <span className="required-asterisk">*</span>
                                </div>
                            }
                            name="farmName"
                            rules={[{ required: true, message: 'Vui lòng nhập tên trang trại' }]} // Add validation rule if farmName is required
                        >
                            <Input
                                placeholder='Nhập tên trang trại'
                            />
                        </Form.Item>
                    }
                    <Form.Item>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 10,
                        }}>
                            <Button type="default" onClick={() => setIsClickEditInfo(false)}>Hủy</Button>
                            <Button type="primary" htmlType="submit">Lưu</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={openModalChangePassword}
                title="Thay đổi mật khẩu"
                onCancel={() => setOpenModalChangePassword(false)}
                footer={null}
                width={400}
                className='add-camera-modal'
            >
                <Form
                    form={formChangePassword}
                    layout="vertical"
                    onFinish={onFinishChangePassword}
                    onFieldsChange={handleFormChange}  // Trigger validation on form field change
                >
                    <Form.Item
                        label={
                            <div className="custom-label">
                                Mật khẩu cũ <span className="required-asterisk">*</span>
                            </div>
                        }
                        name="oldPassword"
                        rules={[
                            { required: true, message: 'Mật khẩu cũ không được để trống và tối thiểu 6 ký tự', min: 6 },
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder='Nhập mật khẩu cũ'
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <div className="custom-label">
                                Mật khẩu mới <span className="required-asterisk">*</span>
                            </div>
                        }
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Mật khẩu mới không được để trống và tối thiểu 6 ký tự', min: 6 },
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder='Nhập mật khẩu mới'
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <div className="custom-label">
                                Nhập lại mật khẩu <span className="required-asterisk">*</span>
                            </div>
                        }
                        name="reNewPassword"
                        dependencies={['newPassword']}
                        rules={[
                            { required: true, message: 'Nhập lại mật khẩu không được để trống' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Mật khẩu xác nhận không khớp!'));
                                },
                            }),
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder='Nhập lại mật khẩu'
                        />
                    </Form.Item>
                    <Form.Item>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 10,
                        }}>
                            <Button type="default" onClick={() => setOpenModalChangePassword(false)}>Hủy</Button>
                            <Button type="primary" htmlType="submit" disabled={!isFormValid}>Lưu</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>


        </div>

    )
}