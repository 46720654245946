import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import IdentityApi from "../../../api/identity.api";
import '../user/style.userManage.scss';
import { Button, Select, Spin, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { CFG_BASE_URL } from '../../../constants/config';
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'Tên kho',
        dataIndex: 'farmName',
    },
    {
        title: 'Loại quả',
        dataIndex: 'typeFruit',
    },
    {
        title: 'Ngày hệ thống bắt đầu giám sát',
        dataIndex: 'createdDate',
    },
    {
        title: 'Số lượng camera',
        dataIndex: 'camera',
    },
    {
        title: 'Tổng số sọt hệ thống phát hiện',
        dataIndex: 'totalCage',
    }


];
const api = IdentityApi.host
const WarehouseList: FC = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [user, setUser] = useState<any>(null);
    const [lstFruitType, setLstFruitType] = useState<any>([]);
    const history = useNavigate();
    // routers/route-names.tsx có: profile_farmList: '/profile/quan-ly-trang-trai/:userID'
    // Phần sau dấu hai chấm là tên biến (có thể thay đổi được), ở đây được đặt là "userID",
    // thế nên useParams() trả về một object có field "userID" với value là ID của user
    const id: any = useParams();

    useEffect(() => {
        const role = sessionStorage.getItem('role')
        if (role !== '"ADMIN"') {
            history('/404-not-found');
            return;
        }
        getDataUser();
        fetchData();
        getTypeFruit();
    }, []);

    useEffect(() => {
        if (lstFruitType.length > 0) {
            fetchData();
        }
    }, [lstFruitType]);

    useEffect(() => {
        console.log(data);

    }, [data]);

    const fetchData = () => {
        axios
            .get(`${api}/admin/warehouseOfUser?userId=${id.userID}`)
            .then((response) => {
                console.log(response?.data?.data);
                let count = 0;
                const lst = response.data.data.map(async (farm: any) => {
                    console.log(farm.ownerEmail);
                    let typeFruit = lstFruitType.find((item: any) => item.id === farm.fruitTypeId);
                    console.log(typeFruit);
                    count += 1;
                    let lstCamera = 0;
                    await axios.get(`${api}/camera/warehouse?warehouseId=${farm._id}`).then((res) => {
                        console.log(res.data.data);
                        lstCamera = res.data.data.length;

                    })
                    console.log(lstCamera);


                    return {
                        key: count, // STT (cột đầu tiên)
                        farmName: farm.name, // Tên trang trại 
                        typeFruit: typeFruit?.name, // Loại quả
                        createdDate: moment(farm.updatedAt).format('DD/MM/YYYY HH:mm:ss'), // Địa chỉ
                        camera: lstCamera || 0, // Số lượng camera
                        totalCage: farm.crates?.length || 0,
                    };
                })

                Promise.all(lst).then((values: any) => {
                    console.log(values);
                    setData(values);
                })


            })

            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));
    }

    const getDataUser = () => {
        axios.get(`${api}/admin/users`).then((response) => {
            const users: any[] = response.data.data;
            console.log(users)
            const u: any = users.filter((item) => item.id.toString() == id.userID.toString())
            // console.log(u);
            setUser(u[0] || null);
            // console.log(user);
        })
    }

    const getTypeFruit = async () => {
        await axios.get(`${CFG_BASE_URL}/fruitTypes/`)
            .then((res) => {
                console.log(res.data.data);
                setLstFruitType(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: data.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };
    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.farmName.toLowerCase().includes(filter));
    const exportToExcel = async () => {
        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };
        // Add columns
        worksheet.columns = [
            { header: 'STT', key: 'key', width: 10 },
            { header: 'Tên kho', key: 'name', width: 30 },
            { header: 'Địa chỉ kho', key: 'address', width: 30 },
            { header: 'Ngày tạo', key: 'createdDate', width: 30 },
            { header: 'Chủ kho', key: 'scale', width: 30 },
            { header: 'Tổng số sọt', key: 'totalCage', width: 30 },
        ];

        worksheet.getRow(1).font = { bold: true };

        // Add data
        sortData.forEach((item: any, index: number) => {
            worksheet.addRow({
                key: item.key,
                name: item.farmName,
                address: item.address,
                createdDate: item.createdDate,
                scale: item.scale,
                totalCage: item.totalCage,
            });
        });

        // worksheet.getColumn('key').eachCell((cell) => {
        //   cell.alignment = { horizontal: 'center' };
        // });

        // worksheet.getColumn('address').eachCell((cell) => {
        //   cell.alignment = { wrapText: true };
        // });

        worksheet.eachRow((row: any, rowNumber: any) => {
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `Danh sách kho của ${user?.name}.xlsx`);
    };
    return (
        <div className="farmList-body">
            <div className="button-farmList">
                <p className="farmList-h2">Danh sách kho của {user?.name}</p>
                <Search
                    className='search-and-sort'
                    placeholder="Tìm kiếm tên kho"
                    onChange={(event) => setFilter(event.target.value)}
                    onSearch={onSearch}
                    enterButton
                    allowClear
                />
            </div>

            <Spin spinning={sortData.length === 0} tip="Đang tải dữ liệu...">
                <div className="table-farmList">
                    <Table
                        bordered
                        dataSource={sortData}
                        columns={columns}
                        rowClassName={getRowClassName}
                        pagination={paginationConfig}
                    />
                    {/* <div className='button-excel'>
                        <Button disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                    </div> */}
                </div>
            </Spin>
        </div>
    );
};

export default WarehouseList;