// import './StatisticStyle.css'
import axios from 'axios';
import moment from "moment";
import React, { useEffect, useState } from "react";
// import React, { useState } from 'react';
import { DatePicker, Table, ConfigProvider, Select, Spin, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import IdentityApi from "../../api/identity.api";
import viVN from 'antd/lib/locale/vi_VN';
import 'moment/locale/vi'
import './style.statistics.scss';
import { DownloadOutlined } from '@ant-design/icons';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
moment.locale('vi');

const statusFruit = [
    {
        value: -1,
        text: 'Đã xuất kho'
    },
    {
        value: 0,
        text: 'Tốt'
    },
    {
        value: 1,
        text: 'Mới hỏng'
    },
    {
        value: 2,
        text: 'Đã hỏng'
    }
]

const columnsImport: ColumnsType<any> = [
    {
        title: 'STT',
        dataIndex: 'No',
    },
    {
        title: 'Mã sọt',
        dataIndex: 'codeBasket',
    },
    {
        title: 'Tên kho',
        dataIndex: 'warehouseName',
    },
    {
        title: 'Ngày nhập kho',
        dataIndex: 'importedTime',
    },
    {
        title: 'Ngày xuất kho',
        dataIndex: 'estimatedExportedTime',
    },
    {
        title: 'Tình trạng',
        dataIndex: 'status',
    },
    {
        title: 'Camera',
        dataIndex: 'camera',
    },
    {
        title: 'Mô tả',
        dataIndex: 'note',
    }

];


const customLocale = {
    filterReset: 'Xóa',
    filterConfirm: 'Lọc',
};
const Statistic = () => {
    const api = IdentityApi.host
    const [warehouseId, setWarehouseId] = useState<string>('');
    const [dataImport, setDataImport] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(1000); // Số bệnh lợn trên một trang 
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState(false);
    const [lstWarehouse, setLstWarehouse] = useState<any>([]);
    const [selectedFruit, setSelectedFruit] = useState<string>('');
    const [selectDate, setSelectDate] = useState<any>({});
    useEffect(() => {
        getWarehouseName();
    }, []);

    useEffect(() => {
        if (lstWarehouse.length > 0) {
            fetchDataImport();
        }
    }, [lstWarehouse]);

    useEffect(() => {
        fetchDataImport();
    }, [selectedFruit, warehouseId, selectDate]);

    const getWarehouseName = async () => {
        let token = sessionStorage.getItem('token');
        if (!token) return;
        token = token.replace(/"/g, '');
        await axios.get(`${api}/warehouses`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            }
        })
            .then((response) => {
                console.log(response);

                const data = response.data.data;
                const lstWarehouse = data.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })
                setLstWarehouse(lstWarehouse);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const fetchDataImport = async () => {
        setLoading(true);
        let token = sessionStorage.getItem('token');
        console.log(token);
        if (!token) return;

        token = token.replace(/"/g, '');

        let url = `${api}/crates?size=${pageSize}&offset=${(currentPage - 1) * pageSize}`;

        if (selectedFruit) {
            url = `${api}/crates?status=${selectedFruit}&size=${pageSize}&offset=${(currentPage - 1) * pageSize}`;
        }

        if (warehouseId) {
            url = `${api}/crates?warehouseId=${warehouseId}&size=${pageSize}&offset=${(currentPage - 1) * pageSize}`;
        }

        if (selectedFruit && warehouseId) {
            url = `${api}/crates?status=${selectedFruit}&warehouseId=${warehouseId}&size=${pageSize}&offset=${(currentPage - 1) * pageSize}`;
        }

        await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            }
        })
            .then((response) => {
                console.log(response);

                let data = response.data.data.items;


                if (data.length === 0) {
                    setDataImport([]);
                    setLoading(false);
                    return;
                };


                if (selectDate.month && selectDate.year) {
                    data = data.filter((item: any) => {
                        const importedDate = moment(item.importedDate);
                        return importedDate.month() + 1 === selectDate.month && importedDate.year() === selectDate.year;
                    });
                }

                const dataImport = data.map((item: any, index: number) => {
                    return {
                        key: index,
                        No: index + 1,
                        codeBasket: item.crateAIId,
                        warehouseName: lstWarehouse.find((warehouse: any) => warehouse.value === item.warehouseId)?.label,
                        importedTime: moment(item.importedDate).format('DD/MM/YYYY HH:mm'),
                        estimatedExportedTime: moment(item.exportedDate).format('DD/MM/YYYY HH:mm'),
                        status: statusFruit.find((status) => status.value === item.status)?.text,
                        camera: item.camera.name,
                        note: item.note || 'Chưa có mô tả'
                    }
                })
                setDataImport(dataImport);
                setTotal(response.data.total);

                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleDateChange = (date: any) => {
        if (date) {
            const selectedMonth = date.month() + 1; // month() is zero-based
            const selectedYear = date.year();

            console.log(selectedMonth, selectedYear);
            setSelectDate({
                month: selectedMonth,
                year: selectedYear,
            });

            setCurrentPage(1);
        }
        else {
            setSelectDate({});
        }
    };

    const handleChangeWarehouse = (value: string) => {
        console.log(value);
        setWarehouseId(value);
        setCurrentPage(1);
    }

    const handleSelectFruitType = (value: string) => {
        setSelectedFruit(value.toString());
        setCurrentPage(1);
    }


    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            setLoading(false);

        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,

        total: total,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    const customFormat = (value: any) => `Tháng ${value.format('M')} năm ${value.format('YYYY')}`;

    const exportToExcel = async () => {
        // Create a workbook
        const workbook = new ExcelJS.Workbook();
        // Add a worksheet
        const worksheet = workbook.addWorksheet('My Sheet');
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const evenRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF' }
        };

        const oddRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f1f2f3' }
        };

        // Add title row
        const today = new Date();
        const formattedDate = `ngày ${today.getDate()} tháng ${today.getMonth() + 1} năm ${today.getFullYear()}`;

        // Add title row
        const titleRow = worksheet.addRow([`Danh sách nhập xuất (tính đến ${formattedDate})`]);
        titleRow.font = { bold: true, size: 16 };
        titleRow.alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.mergeCells('A1:H1'); // Adjust the range based on the number of columns

        // Add an empty row to separate the title from the headers
        worksheet.addRow([]);

        // Add first row of headers (merged cells)
        const headerRow1 = worksheet.addRow(['', '', '', '', '', '']);
        worksheet.mergeCells('A2:A3'); // STT
        worksheet.mergeCells('B2:B3'); // Họ và tên
        worksheet.mergeCells('C2:C3'); // Email
        worksheet.mergeCells('D2:D3'); // Số điện thoại
        worksheet.mergeCells('E2:E3'); // Địa chỉ
        worksheet.mergeCells('F2:F3'); // Ngày tạo tài khoản
        worksheet.mergeCells('G2:G3'); // Trạng thái
        worksheet.mergeCells('H2:H3'); // Quyền hạn


        // Add second row of headers
        const headerRow2 = worksheet.addRow([
            'STT',
            'Mã sọt',
            'Tên kho',
            'Ngày nhập kho',
            'Ngày xuất kho',
            'Tình trạng',
            'Camera',
            'Mô tả'
        ]);

        headerRow1.font = { bold: true };
        headerRow2.font = { bold: true };

        // Add columns (keys only, no headers)
        worksheet.columns = [
            { key: 'No', width: 10 },
            { key: 'codeBasket', width: 20 },
            { key: 'warehouseName', width: 20 },
            { key: 'importedTime', width: 20 },
            { key: 'estimatedExportedTime', width: 20 },
            { key: 'status', width: 20 },
            { key: 'camera', width: 20 },
            { key: 'note', width: 20 }
        ];

        // worksheet.getRow(1).font = { bold: true };

        // Add data
        dataImport.forEach((item: any, index: number) => {
            worksheet.addRow({
                No: item.No,
                codeBasket: item.codeBasket,
                warehouseName: item.warehouseName,
                importedTime: item.importedTime,
                estimatedExportedTime: item.estimatedExportedTime,
                status: item.status,
                camera: item.camera,
                note: item.note
            });
        });

        worksheet.eachRow((row: any, rowNumber: any) => {
            if (rowNumber === 1 || rowNumber === 2 || rowNumber === 3) return; // Skip the title and header rows
            const fill = rowNumber % 2 === 0 ? evenRowFill : oddRowFill;
            row.eachCell((cell: any) => {
                cell.border = borderStyle;
                cell.fill = fill;
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });

        // Save to file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Danh sách nhập xuất.xlsx');
    };

    return (
        <div className='statistic-main' >
            <div className='header-statistic'>
                <div className='title'>
                    Danh sách nhập xuất hoa quả
                </div>

                <div className='filters'>
                    <div className='filter'>
                        <ConfigProvider locale={viVN}>
                            <DatePicker
                                picker="month"
                                placeholder='Chọn tháng nhập kho'
                                format={customFormat}
                                onChange={handleDateChange}
                            />
                        </ConfigProvider>
                    </div>

                    <div className='filter'>
                        <Select
                            placeholder='Chọn kho'
                            style={{ width: 200 }}
                            onChange={handleChangeWarehouse}
                            allowClear
                        >
                            {lstWarehouse.map((warehouse: any, index: number) => {
                                return <Select.Option key={index} value={warehouse.value}>{warehouse.label}</Select.Option>
                            })}
                        </Select>
                    </div>

                    <div className='filter'>
                        <Select
                            placeholder='Chọn tình trạng hoa quả'
                            style={{ width: 200 }}
                            onChange={handleSelectFruitType}
                            allowClear
                        >
                            {statusFruit.map((status, index) => {
                                return <Select.Option key={index} value={status.value}>{status.text}</Select.Option>
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <Spin spinning={loading} tip="Đang tải dữ liệu...">
                <div className='table-statistic' >
                    <Table bordered dataSource={dataImport} columns={columnsImport} pagination={paginationConfig} locale={customLocale} />
                    <div className='button-export' style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            disabled={(dataImport && dataImport.length > 0) ? false : true}
                            onClick={exportToExcel}
                            type="primary"
                            icon={<DownloadOutlined />}>
                            Xuất báo cáo
                        </Button>
                    </div>
                </div>
            </Spin>
        </div >

    )
}

export default Statistic