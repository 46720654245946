import { DatePicker } from 'antd';
import axios from 'axios';
import moment from "moment";
import { useEffect, useState } from "react";
import IdentityApi from "../../api/identity.api";
// Add tooltip antd but name is different
import { FaWarehouse, FaShoppingBasket, FaAppleAlt, FaWeightHanging, FaCamera } from "react-icons/fa";

import { ColumnsType } from "antd/es/table";
// import 'antd/dist/antd.css';
import { useNavigate } from "react-router-dom";
import './overview.scss';
import PieChart from '../../components/PieChart/PieChart';
import MultiBarChart from '../../components/MultiBarChart/MultiBarChart';
import { CFG_BASE_URL } from '../../constants/config';
import MultiBarVerticalChart from '../../components/MultiBarChart/MultiBarChartVertical';
import CameraTotalTable from '../../components/Overview/CameraTotalTable';

interface DataType {
    key: React.Key;
    name: string;
    importedTime: Date;
    estimatedExportTime: Date;
}
const columns: ColumnsType<DataType> = [
    {
        title: 'ID lô',
        dataIndex: 'name',
    },
    {
        title: 'Ngày nhập kho',
        dataIndex: 'createDate',
    },
    {
        title: 'Ngày xuất kho do Ai đề xuất ',
        dataIndex: 'expectedExportDate',
    },
]

const dataBartChart = [
    {
        name: '01/01/2024 - 07/01/2024',
        'Bình thường': 5,
        'Hơi hỏng': 3,
        'Hỏng hẳn': 2,
    },
    {
        name: '08/01/2024 - 14/01/2024',
        'Bình thường': 5,
        'Hơi hỏng': 3,
        'Hỏng hẳn': 2,
    },
    {
        name: '15/01/2024 - 21/01/2024',
        'Bình thường': 5,
        'Hơi hỏng': 3,
        'Hỏng hẳn': 2,
    },
    // {
    //   name: 'Kho C',
    //   'Xoài': 1982,
    //   'Thanh long': 4800,
    // },
    // {
    //   name: 'Kho D',
    //   'Xoài': 1780,
    //   'Thanh long': 3908,
    // },

];
const { RangePicker } = DatePicker;

const OverView = () => {
    const navigation = useNavigate();
    const api = IdentityApi.host
    const [warehouseId, setWarehouseId] = useState<string>('');
    const [dataOverview, setDataOverview] = useState<any>(null);
    const [startDate, setStartDate] = useState<string>('Chưa xác định');
    const [fruitType, setFruitType] = useState<any>([]);
    const [consignmentData, setConsignmentData] = useState<[any]>([null]);
    const [exportConsignmentData, setExportConsignmentData] = useState<any>([]);
    const [cameraNumber, setCameraNumber] = useState<number>(0);
    const [warning, setWarning] = useState<boolean>(false)
    const [dataPieChart, setDataPieChart] = useState([
        { name: 'Tốt', value: 40 },
        { name: 'Mới hỏng', value: 20 },
        { name: 'Đã hỏng', value: 35 },
    ])
    const [total, setTotal] = useState<number>(0)
    const [userInfo, setUserInfo] = useState<any>(null)
    const [lstWarehouse, setLstWarehouse] = useState<any>([]);
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    useEffect(() => {
        // const role = sessionStorage.getItem('role')
        getInfoUser();
        getAllInfoWarehouse();
        getOverView();
    }, [warehouseId]);


    const getInfoUser = async () => {
        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
        }
        await axios.get(`${CFG_BASE_URL}/users/get-user`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data.data);
            setUserInfo(res.data.data);

        }).catch(err => {
            console.log(err);
        })
    }

    const getAllInfoWarehouse = async () => {
        try {

            let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";

            if (token) {
                token = token.slice(1);
                token = token.slice(0, token.length - 1);
            }

            await axios.get(`${CFG_BASE_URL}/warehouses?size=100&offset=0`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res.data.data);
                if (res.data.data) {
                    setLstWarehouse(res.data.data);
                }
            }).catch(err => {
                console.log(err);
            })

        } catch (error) {
            console.error(error);
        }
    }

    const getOverView = async () => {
        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
        }

        await axios.get(`${CFG_BASE_URL}/users/overview`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then((res) => {
                console.log(res.data.data);
                setDataOverview(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
    }

    const getLatestWarehouseToday = () => {
        if (lstWarehouse && lstWarehouse.length > 0) {
            let numberWarehouseToday = lstWarehouse.map((warehouse: any) => {
                return moment(warehouse.createdDate).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY');
            })

            console.log(numberWarehouseToday);

            numberWarehouseToday = numberWarehouseToday.filter((item: any) => item === true);

            console.log(numberWarehouseToday);


            return numberWarehouseToday.length;
        }
        return 0;
    }
    return (
        <div className="overview" style={OverViewStyle}>
            <div className="container-overview">
                <div className='containers'>
                    <div className="info-container"
                        onClick={() => navigation('/pack_manager')}
                    >
                        <div className="info-title">
                            Số lượng kho
                        </div>
                        <div className="info-item">
                            <div className="info-item-content">
                                {lstWarehouse.length || 0}
                            </div>
                            <div className='info-item-note'>
                                <span>{getLatestWarehouseToday()} kho </span>
                                được thêm hôm nay
                            </div>
                        </div>

                        <div className="info-icon">
                            <FaWarehouse />
                        </div>
                    </div>

                    <div className="info-container">
                        <div className="info-title">
                            Tổng sọt do AI phát hiện
                        </div>

                        <div className="info-item">
                            <div className="info-item-content">
                                {dataOverview?.totalCrate || 0}
                            </div>
                            <div className='info-item-note'>
                                <span>{dataOverview?.fruits[0]?.numOfCrates} sọt </span>
                                xoài, {'  '}
                                <span>{dataOverview?.fruits[1]?.numOfCrates} sọt </span>
                                thanh long
                            </div>
                        </div>
                        <div className="info-icon">
                            <FaShoppingBasket />
                        </div>
                    </div>

                    <div className="info-container">
                        <div className="info-title">
                            Tổng số quả ước tính
                        </div>

                        <div className="info-item">
                            <div className="info-item-content">
                                {dataOverview?.totalFruits || 0}
                            </div>
                            <div className='info-item-note'>
                                <span>{dataOverview?.fruits[0]?.numOfFruits} quả </span>
                                xoài, {'  '}
                                <span>{dataOverview?.fruits[1]?.numOfFruits} quả </span>
                                thanh long
                            </div>
                        </div>
                        <div className="info-icon">
                            <FaAppleAlt />
                        </div>
                    </div>

                    <div className="info-container">
                        <div className="info-title">
                            Khối lượng quả ước tính (kg)
                        </div>

                        <div className="info-item">
                            <div className="info-item-content">
                                {dataOverview?.totalCrateWeight || 0} kg
                            </div>
                            <div className='info-item-note'>
                                <span>{dataOverview?.fruits[0]?.numOfCrateWeight} kg </span>
                                xoài, {'  '}
                                <span>{dataOverview?.fruits[1]?.numOfCrateWeight} kg </span>
                                thanh long
                            </div>
                        </div>
                        <div className="info-icon">
                            <FaWeightHanging />
                        </div>
                    </div>

                    <div className="info-container">
                        <div className="info-title">
                            Số sọt có quả bị hỏng được AI phát hiện
                        </div>

                        <div className="info-item">

                            <div className="info-item-content">
                                {dataOverview?.totalSpoilCrate || 0}
                            </div>
                            <div className='info-item-note'>
                                <span>{dataOverview?.fruits[0]?.numOfSpoilCrate} sọt </span>
                                xoài, {'  '}
                                <span>{dataOverview?.fruits[1]?.numOfSpoilCrate} sọt </span>
                                thanh long
                            </div>
                        </div>
                        <div className="info-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#9E9E9E" d="M267.6 21.32h-1.1c-7.2.4-14.6 3.51-20.1 9.59l-2.6 84.59l13.2.6l25.8-90.49c-3.7-2.67-8.7-4.21-14-4.33h-1.2zM227 74.88c-40.2 1.1-78.3 9.54-107.1 25.62c-32.88 18.4-53.76 45.5-54.82 85.4c38.22-9.9 70.42-17.5 101.32-16.1c31.7 1.3 61.8 12.5 94.2 38.2c27-34.8 59.1-49.7 94.1-48.7c33.2.9 54.1 5.6 92.2 25.3c-6.4-31.2-15-48.1-47.9-68.1c-31.2-19-71.1-32.11-112.5-38.09l-10.7 37.49c7.5-1.2 15.1-2.6 22.9-4l3.2 17.8c-17 3-34.8 6.8-52.5 6.9c-17.8 0-36-4.1-51.5-17.1l11.6-13.8c5.2 4.3 10.6 7.4 16.2 9.4zM342 177.6c-26.9 2.4-50.1 16.1-72.4 48.2l-5.7 8.2l-7.5-6.4c-33.2-28.2-60.5-38.5-90.7-39.8c32.3 43.9 43.8 96.7 41.6 144.3c-1.8 38.8-12 74.5-28.6 100c9 3.8 18.3 7.1 27.2 8.8c18.5 3.7 34.6 1.9 48.6-13.2l5.5-5.9l6.5 4.8c17.3 12.9 34.7 12.5 53.8 6.1c8.9-3 18-7.3 27-12.3c-38.8-79.2-41.2-160.9-5.3-242.8m-57.3 93.3s19.2 23.7 17 37.2c-.8 5.2-5.5 11.2-10.7 11.5c-7 .4-14.1-6.9-16.2-13.6c-3.5-11.6 9.9-35.1 9.9-35.1m-41.1 3.2s13.7 26.2 9 38.6c-1.9 4.8-7.5 9.6-12.5 8.8c-6.7-.9-12.1-9.3-12.7-16.1c-1.1-11.7 16.2-31.3 16.2-31.3m135.6 148.2c-15.8 9.9-33.8 21.1-53.3 27.5c-20.3 6.7-43 7.6-63.8-4.7c-17.7 15.2-39.7 17.5-59.7 13.5c-20.7-4.1-40.2-13.9-56.2-22.2c-20.2 7.9-42.2 11.2-73.91 10.8c16.3 11.7 38.71 21.4 64.91 28.5c37.2 10 81.6 15 125.4 15c43.7-.1 86.9-5.2 121.7-14.9c25.3-7 45.8-16.7 59.2-27.6c-10.8-.2-19.9.1-29.1-1.7c-12.3-2.3-23.6-9.3-35.2-24.2" /></svg>
                        </div>
                    </div>

                    <div className="info-container"
                        onClick={() => navigation('/camera_manager')}
                    >
                        <div className="info-title">
                            Tổng số camera
                        </div>

                        <div className="info-item">

                            <div className="info-item-content">
                                {dataOverview?.totalCamera || 0}
                            </div>

                        </div>
                        <div className="info-icon">
                            <FaCamera />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                gap: 10
            }}>
                <div className="container-overview warehouse-overview">
                    <div className="info-container">
                        <div className="info-title">
                            Tình trạng chất lượng quả
                        </div>
                        <div className="info-item">
                            <MultiBarChart
                                lstWarehouse={lstWarehouse}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-overview warehouse-overview">
                    <div className="info-container">
                        <div className="info-title">
                            Thống kê số lượng camera theo kho
                        </div>
                        <div className="info-item">
                            {/* <MultiBarVerticalChart /> */}
                            <CameraTotalTable />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
const OverViewStyle = {
    fontFamily: 'Inter, sans-serif !important',
    marginTop: '30px',
}
const TitleStyle = {
    fontSize: '20px',
    fontWeight: '700',
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'center',
    alignItems: 'center'
}
const TopLeftStyle = {
    height: '90px',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: 20,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    alignItems: 'center'
}


const LeftTopValueStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: '700',
    display: 'flex'
}

const SmallTitleStyle = {
    fontSize: '18px',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const PieChartStyle = {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: 20,
    // margin: '3% 4.3% 3% 4.3%',
    borderRadius: 10,
    height: 580,
    boxShadow: '0 3px 5px #00000005,0 0 2px #0000000d,0 1px 4px #00000014'
}

const RightStyle = {
    // display: 'grid',
    // gridTemplateColumns: '1fr',
    // gridTemplateRows: '40% 60%',
    // gridColumnGap: '20px',
    // gridRowGap: '20px',
    flex: 1,
    margin: '0% 0% 1% 0%'

}


export default OverView