import React from 'react';
import { Table } from 'antd';

interface TableComponentProps {
    data: any,
    columns: any,
    pageSize?: number
}

const TableComponent = (props: TableComponentProps) => {
    const { data, columns } = props;

    console.log('data', data);
    

    const handlePagination = {
        showSizeChanger: false,
        // showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ['10', '20', '50', '100'],
        defaultPageSize: data.length > 0 ? data.length : 10,
    }


    return <div>
        {data && columns &&
            <Table dataSource={data} columns={columns} pagination={handlePagination} scroll={{y: 300}} />
        }
    </div>
};

export default TableComponent;