import { Button, Card, Form, Image, Input, List, Modal, notification, Select } from "antd";
import Meta from "antd/es/card/Meta";
import Title from "antd/es/typography/Title";
import { Link } from "react-router-dom";
import CameraImg1 from '../../images/camera_img_1.png';
import CameraImg10 from '../../images/camera_img_10.png';
import CameraImg11 from '../../images/camera_img_11.png';
import CameraImg12 from '../../images/camera_img_12.png';
import CameraImg13 from '../../images/camera_img_13.png';
import CameraImg14 from '../../images/camera_img_14.png';
import CameraImg15 from '../../images/camera_img_15.png';
import CameraImg16 from '../../images/camera_img_16.png';
import CameraImg2 from '../../images/camera_img_2.png';
import CameraImg3 from '../../images/camera_img_3.png';
import CameraImg4 from '../../images/camera_img_4.png';
import CameraImg5 from '../../images/camera_img_5.png';
import CameraImg6 from '../../images/camera_img_6.png';
import CameraImg7 from '../../images/camera_img_7.png';
import CameraImg8 from '../../images/camera_img_8.png';
import CameraImg9 from '../../images/camera_img_9.png';
import "./style/cameraManager.scss";
import Video from "../../components/video/video";
import { useEffect, useState } from "react";
import axios from "axios";
import { CFG_BASE_URL, WEBRTC_STREAMING } from "../../constants/config";


const CameraManager = () => {

    const [lstWarehouse, setLstWarehouse] = useState<any>([]);
    const [isOpenAddCamera, setIsOpenAddCamera] = useState<boolean>(false);
    const [formAdd] = Form.useForm();
    const [formEdit] = Form.useForm();

    useEffect(() => {
        getAllInfoWarehouse();
    }, []);

    useEffect(() => {
        // startAllWarehouse();
    }, [lstWarehouse]);

    // const startAllWarehouse = async () => {
    //     console.log(lstWarehouse);

    //     lstWarehouse.forEach(async (item: any) => {
    //         await axios.post(`${WEBRTC_STREAMING}/rtc/start_link`, {
    //             link: item.link
    //         }).then((res) => {
    //             console.log(res.data);
    //         }).catch((err) => {
    //             console.log(err);
    //         });
    //     });
    // }

    const getAllInfoWarehouse = async () => {
        try {

            let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";

            if (token) {
                token = token.slice(1);
                token = token.slice(0, token.length - 1);
            }

            await axios.get(`${CFG_BASE_URL}/warehouses?size=100&offset=0`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res.data.data);
                if (res.data.data) {
                    setLstWarehouse(res.data.data);
                }
            }).catch(err => {
                console.log(err);
            })

        } catch (error) {
            console.error(error);
        }
    }

    const onOpenAddCamera = () => {
        setIsOpenAddCamera(true);
        formAdd.resetFields();
    }

    const onFinishAddCamera = async (values: any) => {
        console.log(values);

        const body = {
            name: values.name,
            warehouseId: values.warehouse,
            link: values.link,
            note: values.note,
            createdDate: new Date(),
            additionalProp1: {}
        }

        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (token) {
            token = token.replace(/"/g, '');
        }

        try {
            await axios.post(`${CFG_BASE_URL}/camera`, body, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then(async (res) => {
                console.log(res.data.data);
                notification.success({
                    message: 'Thêm camera thành công',
                    description: 'Thêm thông tin camera thành công',
                    duration: 2,
                } as any);

                formAdd.resetFields();

                await axios.post(`${WEBRTC_STREAMING}/rtc/start_link?link=${values.link}`).then((res) => {
                    console.log(res.data);
                }).catch((err) => {
                    console.log(err);
                });

                // setIsOpenAddCamera(false);
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1000);

            }).catch((err) => {
                console.log(err);
            });

        } catch (error) {
            console.error(error);
        }
    }


 
    return (
        <div className="camera-manager-main">
            <div className="camera-manager-title">
                <div className="camera-manager-text">
                    Danh sách camera
                </div>
                <div className="camera-manager-button">
                    <Button type="primary" onClick={onOpenAddCamera}>Thêm camera</Button>
                </div>
            </div>
            {
                (lstWarehouse && lstWarehouse.length > 0) && lstWarehouse.map((item: any, index: any) => (
                    <div key={item.id}>
                        <Video
                            warehouse={item}
                        />
                    </div>
                ))
            }

            <Modal
                visible={isOpenAddCamera}
                title='Thêm camera'
                className='modal-add-pack'
                footer={null}
                onCancel={() => setIsOpenAddCamera(false)}

            >
                <Form
                    form={formAdd}
                    layout='vertical'
                    name='form_add_camera'
                    onFinish={onFinishAddCamera}
                >
                    <Form.Item
                        label='Kho'
                        name='warehouse'
                        rules={[{ required: true, message: 'Vui lòng chọn kho' }]}
                    >
                        <Select placeholder='Chọn kho'>
                            {
                                lstWarehouse.map((item: any) => (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Tên camera'
                        name='name'
                        rules={[{ required: true, message: 'Vui lòng nhập tên camera' }]}
                    >
                        <Input placeholder='Nhập tên camera' />
                    </Form.Item>
                    <Form.Item
                        label='Link camera'
                        name='link'
                        rules={[{ required: true, message: 'Vui lòng nhập link camera' }]}
                    >
                        <Input placeholder='Nhập link camera' />
                    </Form.Item>

                    <Form.Item
                        label='Mô tả'
                        name='note'
                        rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}
                    >
                        <Input.TextArea placeholder='Nhập mô tả' rows={4} />
                    </Form.Item>

                    <Form.Item
                        label='Ghi chú'
                        name='description'
                    >
                        <Input placeholder='Nhập ghi chú' />
                    </Form.Item>

                    <Form.Item>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px'
                        }}>
                            <Button type='default' onClick={
                                () => setIsOpenAddCamera(false)
                            }>Hủy</Button>
                            <Button type='primary' htmlType='submit'>Thêm</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
            
        </div>
    )
}

export default CameraManager