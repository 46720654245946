// export const CFG_BASE_URL = 'http://178.128.19.31:3002';
export const CFG_BASE_URL = 'https://api.ai-fruits.org';
// export const CFG_BASE_URL = 'http://localhost:3003';
export const PORT = '';
export const STREAMING = ':4600';
// export const CFG_BASE_URL = 'http://localhost:3002';
export const CFG_SIZE_PAGINATION = 10;
export const DATE_FORMAT = 'DD/MM/YYYY'
export const WEBRTC_STREAMING = 'https://streaming.ai-fruits.org'
// export const WEBRTC_STREAMING = 'http://192.168.100.160:3009'
// export const WEBRTC_STREAMING = 'http://localhost:4600'