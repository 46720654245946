import React from 'react';
import './home.scss';

const Home: React.FC = () => {

    return (
        <div className='home-page'>
            <div className='home-page-content'>
                <div className='biggest-text-content'>
                    Tự động giám sát chất lượng nông sản với AI ngay tại đây

                </div>
                <div className='small-text-content'>
                    Phần mềm AI Fruit cung cấp cho bạn một giải pháp hữu ích và tiết kiệm giúp bạn chủ động giám sát chất lượng nông sản dù ở bất kì đâu
                </div>
            </div>
        </div>
    );
};

export default Home;
