/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { WritableDraft } from "immer/dist/internal";
import { catchError, filter, map, mergeMap, switchMap } from "rxjs/operators";
import IdentityApi from "../../api/identity.api";
import { ActiveAccountRequest, IUser, LoginRequest, RegisterRequest, ResponseDeparment } from "../../common/define-identity";
import { RootEpic } from "../../common/define-type";
import Utils from "../../common/utils";
import { log } from "console";

type MessageLogin = {
    content: string;
    errorCode?: number
}
type MessageForgot = {
    ErrorCode?: number,
    Message: string
}
interface LoginState {
    loading: boolean;
    isSuccess: boolean;
    user: IUser | undefined;
    message: MessageLogin | undefined;
    messageForgot: MessageForgot | undefined;
    departmentId: number;
    refresh_token: string;
    statusCode: string | undefined
}

const initState: LoginState = {
    loading: false,
    isSuccess: true,
    user: undefined,
    departmentId: 1,
    message: undefined,
    messageForgot: undefined,
    refresh_token: "",
    statusCode: undefined,
}

const loginSlice = createSlice({
    name: 'login',
    initialState: initState,
    reducers: {
        loginRequest(state, action: PayloadAction<LoginRequest>) {
            state.loading = true;
            // console.log("da chui vao",state.loading)
        },
        loginSuccess(state, action: PayloadAction<{ user: IUser, token: string }>) {
            Utils.setsessionStorage('token', action.payload.token);
            Utils.setsessionStorage('userId', action.payload.user.id)
            Utils.setsessionStorage('userEmail', action.payload.user.email)
            Utils.setsessionStorage('userName', action.payload.user.name)
            Utils.setsessionStorage('role', action.payload.user.role)

            // state.loading = false;
            // Utils.setsessionStorage("email", action.payload.user.email);
            // Utils.setsessionStorage("id", action.payload.user.id);
            // Utils.setsessionStorage("password", action.payload.user.password);
            // Utils.setsessionStorage("refresh_token", action.payload.refresh_token)
            // Utils.setsessionStorage("email", action.payload.user.)
            // Utils.setsessionStorage("expires", action.payload.expires_token)
            state.user = action.payload.user
            state.loading = false
            state.isSuccess = true;
            notification.open({
                message: 'Đăng nhập thành công',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                style: {
                    marginTop: 40
                }
            });
        },
        loginFail(state, action: any) {
            console.log(action);
            notification.open({
                message: 'Đăng nhập không thành công',
                description:
                    'Hãy kiểm tra lại thông tin đăng nhập.',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
                style: {
                    marginTop: 40
                }
            });
            state.message = action.payload.message
        },
        checkAbleToLogin(state, action: PayloadAction<string>) {
            state.statusCode = action.payload;
        },
        getInfoUser(state, action: PayloadAction<IUser>) {
            state.user = action.payload
        },
        forgotRequest(state, action: PayloadAction<string>) {
            state.loading = true
        },
        sendMailSuccess(state, action: PayloadAction<{ message: WritableDraft<MessageLogin> | undefined }>) {
            state.message = action.payload.message
            state.loading = false
            state.isSuccess = true;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        getDepartmentRequest(state, action: PayloadAction<string>) {
            state.loading = true;
        },
        getDepartmentSuccess(state, action: PayloadAction<ResponseDeparment>) {
            state.isSuccess = true;
            state.departmentId = action.payload.departmentId;
        },
        message(state, action: PayloadAction<MessageLogin>) {
            state.message = action.payload;
            state.loading = false
        },
        messageForgot(state, action: PayloadAction<MessageForgot>) {
            state.messageForgot = action.payload;
            state.loading = false
        },
        clearMessageResquest(state) {
            state.loading = true
        },
        clearMessage(state) {
            state.messageForgot = undefined;
            state.message = undefined;
            state.loading = false
        },
        setStatusCode(state, action: PayloadAction<string>) {
            state.statusCode = action.payload;
        },
        clearAllRequest(state) {
            state.loading = true;
            state.statusCode = undefined;
            state.user = undefined;
        },
        registerRequest(state, action: PayloadAction<any>) {
            state.loading = true;
            console.log('Da chui vao voi action: ', action);
        },
        registerSuccess(state, action: PayloadAction<any>) {
            notification.open({
                message: 'Đăng ký tài khoản thành công',
                description: 'Vui lòng kiểm tra email để kích hoạt tài khoản',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            // openNotification(NotificationType.Info, 'topRight', `Đăng ký tài khoản mới thành công!`, ``);

            state.user = action.payload.user
            state.isSuccess = true;
        },
        registerFail(state, action: PayloadAction<any>) {
            console.log(action.payload);

            notification.open({
                message: 'Đăng ký không thành công',
                description:
                    action.payload.response.message || 'Vui lòng kiểm tra lại thông tin đăng ký',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            state.loading = false
        },
        activeAccountRequest(state, action: PayloadAction<any>) {
            state.loading = true;
        },
        activeAccountSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            console.log(action.payload);

            // notification.open({
            //     message: 'Kích hoạt tài khoản thành công',
            //     onClick: () => {
            //         console.log('Notification Clicked!');
            //     },
            // });
        },
        activeAccountFail(state, action: PayloadAction<any>) {
            state.loading = false;
            // notification.open({
            //     message: 'Kích hoạt tài khoản không thành công',
            //     description:
            //         action.payload.response.message,
            //     onClick: () => {
            //         console.log('Notification Clicked!');
            //     },
            // });
        },
        checkActiveAccountRequest: (state, action: PayloadAction<ActiveAccountRequest>) => {
            state.loading = true;
        },
        checkActiveAccountSuccess: (state, action: PayloadAction<any>) => {
            console.log(action.payload);
            if (action.payload.statusCode === "OK") {
                notification.open({
                    message: action.payload.data,
                    description: 'Tài khoản đã có thể đăng nhập',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    style: {
                        // marginTop: 20
                    }
                });
            }
            if (action.payload.statusCode === "AccountActivated") {
                notification.open({
                    message: action.payload.data,
                    description: 'Vui lòng không xác nhận lại',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    style: {
                        // marginTop: 20
                    }
                });
            }
            if (action.payload.statusCode === "UserNotFound") {
                notification.open({
                    message: action.payload.data,
                    description: 'Vui lòng đăng ký / đăng nhập lại với tài khoản khác đã xác nhận',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                    style: {
                        // marginTop: 20
                    }
                });
            }

        },
        checkActiveAccountFailed(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },

    }
})
var response: any;
const login$: RootEpic = (action$) => action$.pipe(
    filter(loginRequest.match),
    switchMap((re) => {
        // IdentityApi.login(re.payload) ?
        console.log(re.payload);
        const body: LoginRequest = {
            "email": re.payload.email,
            "password": re.payload.password,
            "remember": re.payload.remember,
            "additionalProp1": {},
        };

        return IdentityApi.login(body).pipe(
            mergeMap((res: any) => {
                console.log(res);
                response = res;
                console.log(res.data.accessToken);
                const token = res.data.accessToken
                const user = {
                    id: res.data.id,
                    email: res.data.email,
                    name: res.data.name,
                    blocked: res.data.blocked,
                    role: res.data.role || '',
                };

                console.log(user);
                return [
                    loginSlice.actions.loginSuccess({ user, token: token }),
                    loginSlice.actions.setLoading(false),
                    loginSlice.actions.setStatusCode(res.statusCode)
                ];
            }),
            catchError(err =>
                [loginSlice.actions.loginFail(err)]
            )
        )
    })
)


const forgot$: RootEpic = (action$) => action$.pipe(
    filter(forgotRequest.match),
    switchMap((re) => {
        return IdentityApi.forgotPassword(re.payload).pipe(
            map((res: any) => {
                return loginSlice.actions.messageForgot({ Message: "success" });
            }), catchError(err => [loginSlice.actions.messageForgot(err.response)])
        )
    })
)

const clearMessage$: RootEpic = (action$) => action$.pipe(
    filter(clearMessageResquest.match),
    map(() => { return loginSlice.actions.clearMessage() })
)

const logOut$: RootEpic = (action$) => action$.pipe(
    filter(clearAllRequest.match),
    mergeMap(() => {
        return [
            loginSlice.actions.clearAllRequest(),
            loginSlice.actions.setLoading(false)
        ]
    })
)

const register$: RootEpic = (action$) => action$.pipe(
    filter(registerRequest.match),
    switchMap((re) => {
        console.log(re.payload);
        const body: any = {
            "email": re.payload.email,
            "dob": re.payload.dob,
            "name": re.payload.name,
            "phone": re.payload.phone,
            "password": re.payload.password,
            "confirmPassword": re.payload.confirmPassword,
            "address": re.payload.address,
            "farmName": re.payload.farmName,
            "additionalProp1": {},
        };
        return IdentityApi.register(body).pipe(
            mergeMap((res: any) => {
                return [
                    loginSlice.actions.setLoading(false),
                    loginSlice.actions.setStatusCode(res.statusCode),
                    loginSlice.actions.registerSuccess(res)
                ];
            }),
            catchError(err =>
                [
                    loginSlice.actions.setStatusCode('UniqueEmail'),
                    loginSlice.actions.registerFail(err)
                ]
            )
        )
    })
)

const ableToLogin$: RootEpic = (action$) => action$.pipe(
    filter(checkAbleToLogin.match),
    mergeMap(() => {
        return [
            loginSlice.actions.checkAbleToLogin("OK"),
        ]
    })
)

const activeAccount$: RootEpic = (action$) => action$.pipe(
    filter(activeAccountRequest.match),
    switchMap((re) => {
        return IdentityApi.activeAccount(re.payload).pipe(
            map((res: any) => {
                return loginSlice.actions.activeAccountSuccess(res);
            }), catchError(err => [loginSlice.actions.activeAccountFail(err)])
        )
    })
)
const checkActiveAccount$: RootEpic = (action$) => action$.pipe(
    filter(checkActiveAccountRequest.match),
    switchMap((re) => {
        console.log(re);
        return IdentityApi.checkActiveAccount(re.payload).pipe(
            mergeMap((res: any) => {
                console.log(res);
                return [

                    loginSlice.actions.checkActiveAccountSuccess(res),
                ];
            }),
            catchError(err =>
                [loginSlice.actions.checkActiveAccountFailed(err)]
            )
        )
    })
)

export const LoginEpics = [
    login$,
    forgot$,
    clearMessage$,
    logOut$,
    register$,
    ableToLogin$,
    activeAccount$,
    checkActiveAccount$,
]
export const {
    // getDepartmentRequest,
    loginRequest,
    forgotRequest,
    clearMessageResquest,
    getInfoUser,
    clearAllRequest,
    registerRequest,
    checkAbleToLogin,
    activeAccountRequest,
    checkActiveAccountRequest,
} = loginSlice.actions
export const loginReducer = loginSlice.reducer

console.log(response);
