import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { useDispatchRoot } from '@src/src/app/redux/store';
// import { notification } from 'antd';

const firebaseConfig = {
    apiKey: 'AIzaSyD92ohnSJs5gFaUuIAoK5LwrqIP_xz9vTg',
    authDomain: 'fruitai-ad520.firebaseapp.com',
    projectId: 'fruitai-ad520',
    storageBucket: 'fruitai-ad520.appspot.com',
    messagingSenderId: '379123864441',
    appId: '1:379123864441:android:7061216ddfe0205efe55a8',
    measurementId: "G-XPT209GZFK",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, {
        vapidKey: 'BKXmubq_iNGfuiVaLPoLZGh2vK62UC3XE6c643U5KZlZWNr73XyBTCXNj9TpHbJ6fQsGATFh294qBokzzy5r774',
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                setTokenFound(currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
                setTokenFound(null);
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            setTokenFound(null);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
