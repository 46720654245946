import Icon, { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { IWareHouse } from '../../common/define-fruit';
import DetailsIcon from '../../images/default_icon.png';
import { setSelectWarehouse, setWareHouse } from '../../redux/controller';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import AddWarehouseModel from './AddWarehouseModel';
import EditWarehouseModal from './EditWarehouseModal';
import './warehouse.scss';

const { Title } = Typography;

interface DataType {
    id: string;
    key: React.Key;
    orderByNumber: number;
    name: string;
    address: string;
}

const Warehouse = () => {
    const [lstWareHouse, setLstWareHouse] = useState<DataType[]>([]);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
    const { sliceLstWarehouses, selectedWarehouse } = useSelectorRoot(item => item.fruit);
    const dispatch = useDispatchRoot();

    useEffect(() => {
        const warehouses = [];
        console.log('sliceLstWarehouses: ', sliceLstWarehouses);
        for (let i = 0; i < sliceLstWarehouses.length; i++) {
            warehouses.push({
                id: sliceLstWarehouses[i].id,
                key: i,
                orderByNumber: i + 1,
                name: sliceLstWarehouses[i].name,
                address: sliceLstWarehouses[i].address,
                note: sliceLstWarehouses[i].note,
            });
        }
        setLstWareHouse(warehouses);
    }, [sliceLstWarehouses]);

    const rowSelection = (selectedRows: any) => {
        const warehouseSelect: IWareHouse = {
            id: selectedRows.id,
            name: selectedRows.name,
            address: selectedRows.address,
        };
        sessionStorage.removeItem('selectedWarehouseId');
        sessionStorage.setItem('selectedWarehouseId', warehouseSelect.id);

        dispatch(setWareHouse(warehouseSelect));
        console.log('selectedRows: ', warehouseSelect);
        window.location.reload();
    };

    const toggleAddWarehouseModal = () => {
        setIsOpenModal(!isOpenModal);
    };

    const toggleEditWarehouseModal = () => {
        setIsOpenEditModal(!isOpenEditModal);
    };

    const handleDeleteModal = (warehouse: IWareHouse) => {
        Modal.confirm({
            title: 'Xóa kho',
            content: `Bạn có chắc chắn muốn xóa kho ${warehouse.name} không?`,
            okText: 'Xóa',
            cancelText: 'Hủy',
            onOk() {
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'orderByNumber',
            key: 'orderByNumber',
        },
        {
            title: 'Tên kho',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Ngày cập nhật',
            dataIndex: 'address',
            key: 'updateDate',
        },
        // {
        //     title: 'Mặc định',
        //     key: 'default',
        //     render: (text: any, record: any) => (
        //         <Button className='default-button' onClick={() => rowSelection(record)}>
        //             <Icon className='default-button-icon' component={() => (<img className='icon-default' src={DetailsIcon} alt='' />)} />
        //             <span className='default-button-text'>Đặt làm mặt định</span>
        //         </Button>
        //     ),
        // },
        {
            title: 'Thao tác',
            key: 'action',
            render: (text: any, record: any) => (
                <div>
                    <EditOutlined className='action' onClick={() => {
                        dispatch(setSelectWarehouse(record));
                        setIsOpenEditModal(true);
                    }} />
                    <DeleteOutlined className='action' onClick={() => handleDeleteModal(record)} />
                </div>
            ),
        },
    ];

    return (
        <div className="warehouse-main">
            <AddWarehouseModel
                isOpenModal={isOpenModal}
                toggleAddWarehouseModal={toggleAddWarehouseModal}
            />
            <EditWarehouseModal
                isOpenModal={isOpenEditModal}
                selectedWarehouse={selectedWarehouse}
                toggleEditWarehouseModal={toggleEditWarehouseModal}
            />

            <div className="warehouse-title">
                <Title className="title-text" level={3}>Chọn kho cần quản lý</Title>
                <Button className="title-button" onClick={() => setIsOpenModal(true)}>
                    Tạo kho mới
                </Button>
            </div>
            <div className="warehouse-table">
                <Table
                    columns={columns}
                    dataSource={lstWareHouse}
                    pagination={false}
                    rowKey="id"
                    size="small"
                    onRow={(record) => {
                        return {
                            onClick: () => rowSelection(record),
                        };
                    }}
                    //cursor: 'pointer'
                    style={{ cursor: 'pointer' }}
                />
            </div>
        </div>
    );
};

export default Warehouse;