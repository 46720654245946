import { Button, Form, Input, Modal, notification, Select, Spin, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete, AiOutlineCamera } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { CFG_BASE_URL } from '../../constants/config';
import './crate.scss';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const statusFruit = [
    {
        value: -1,
        label: 'Đã xuất kho'
    },
    {
        value: 0,
        label: 'Tốt'
    },
    {
        value: 1,
        label: 'Mới hỏng'
    },
    {
        value: 2,
        label: 'Đã hỏng'
    }
]
const lstFruitType = [
    { name: 'Thanh long', id: '659ba5ab96288a2378e8a1a7' },
    { name: 'Xoài', id: '659ba5a396288a2378e8a1a4' },
    { name: 'string', id: '668267d55982f06692fe8800' }
]
const Crate = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang 
    const [data, setData] = useState([]);
    const [total, setTotal] = useState<number>()
    const [lstWarehouse, setLstWarehouse] = useState<any>([]);
    const [formAdd] = Form.useForm();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectWarehouse, setSelectWarehouse] = useState<any>()
    const [isOpenCameraModal, setIsOpenCameraModal] = useState(false)
    const [formAddCamera] = Form.useForm();
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const [formEdit] = Form.useForm();
    const { warehouseId } = useParams<any>();
    const [lstCrate, setLstCrate] = useState<any>([])

    const columns: ColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'orderByNumber',
        },
        {
            title: 'ID sọt',
            dataIndex: 'crateAIId',
        },
        {
            title: 'Tên camera',
            dataIndex: 'camera',
        },
        {
            title: 'Loại quả',
            dataIndex: 'fruitType',

        },
        // {
        //     title: 'Tên kho',
        //     dataIndex: 'warehouseName',
        // },
        {
            title: 'Ngày nhập kho',
            dataIndex: 'importedDate',
        },
        {
            title: 'Chất lượng quả',
            dataIndex: 'status',
        }
    ];


    useEffect(() => {
        console.log(warehouseId);

        getAllFruitType();
        getAllCrateByWarehouse();
        getAllInfoWarehouse();
    }, [warehouseId]);

    const getAllCrateByWarehouse = async () => {
        let token = sessionStorage.getItem('token');
        console.log(token);
        if (token) {
            token = token.replace(/"/g, '');
        }

        console.log(token);


        await axios.get(`${CFG_BASE_URL}/crates/current?warehouseId=${warehouseId}&size=1000&offset=0`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res.data.data.items);
                // Lấy ra loại quả theo fruitTypeId

                if (res.data.data.items) {
                    const lst = res.data.data.items.map((fruit: any, index: any) => {
                        const fruitType = lstFruitType.find((item: any) => item.id === fruit.fruitTypeId);
                        const date = moment(fruit.importedDate).format('DD/MM/YYYY');
                        const status = statusFruit.find((item: any) => item.value === fruit.status);
                        const warehouseName = lstWarehouse.find((item: any) => item.id === fruit.warehouseId)?.name;
                        const camera = fruit.camera.name;
                        return {
                            ...fruit,
                            camera: camera,
                            fruitType: fruitType?.name,
                            status: status?.label,
                            warehouseName: warehouseName,
                            importedDate: date,
                            orderByNumber: index + 1
                        }
                    })
                    setLstCrate(lst);
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            setLoading(false);
        }, 1000);
    };

    const getAllFruitType = async () => {
        try {
            const fruitType = await axios.get(`${CFG_BASE_URL}/fruitTypes`)

            console.log(fruitType.data.data);

            if (fruitType.data.data) {

                console.log(fruitType.data.data);

                // setLstFruitType(fruitType.data.data.map((item: any, index: any) => {
                //     return {
                //         value: item.id,
                //         label: item.name
                //     }
                // }));
            }

        } catch (error) {
            console.error(error);
        }
    }

    const getAllInfoWarehouse = async () => {
        try {

            let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";

            if (token) {
                token = token.slice(1);
                token = token.slice(0, token.length - 1);
            }

            await axios.get(`${CFG_BASE_URL}/warehouses?size=100&offset=0`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res.data.data);
                if (res.data.data) {
                    setLstWarehouse(res.data.data);
                }
            }).catch(err => {
                console.log(err);
            })

        } catch (error) {
            console.error(error);
        }
    }

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: total,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };
    return (

        <Spin spinning={loading} tip='Đang lấy dữ liệu'>
            <div className='pack-manage' >
                <div className='main-header'>
                    <div className='title'>Quản lý sọt</div>
                </div>
                <div className='packs-detail'>
                    <Table bordered className='table-pack-manage' columns={columns} dataSource={lstCrate} pagination={paginationConfig} />
                </div>
            </div>
        </Spin>
    )
}

export default Crate