import { Button, Form, Input, Modal, notification, Select, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete, AiOutlineCamera } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { CFG_BASE_URL, WEBRTC_STREAMING } from '../../constants/config';
import './PackStyle.scss';
import { useNavigate } from 'react-router-dom';



const CPackManager = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang 
    const [data, setData] = useState([]);
    const [total, setTotal] = useState<number>()
    const [lstWarehouse, setLstWarehouse] = useState<any>([]);
    const [formAdd] = Form.useForm();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [lstFruitType, setLstFruitType] = useState<any>([]);
    const [selectWarehouse, setSelectWarehouse] = useState<any>()
    const [isOpenCameraModal, setIsOpenCameraModal] = useState(false)
    const [formAddCamera] = Form.useForm();
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const [formEdit] = Form.useForm();
    const navigate = useNavigate();

    const columns: ColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'orderByNumber',
        },
        {
            title: 'Tên kho',
            dataIndex: 'name',
        },
        {
            title: 'Địa chỉ',
            dataIndex: 'address',
        },
        {
            title: `Diện tích (m²)`,
            dataIndex: 'area',
            // render: (text, record) => (
            //     <span>{text} m²</span>
            // )
        },
        {
            title: 'Loại quả',
            dataIndex: 'fruitType',
        },
        {
            title: 'Nguồn gốc quả',
            dataIndex: 'origin',
        },
        {
            title: 'Số lượng quả trung bình trong 1 sọt (quả/sọt)',
            dataIndex: 'fruitsPerCrate',
            // render: (text, record) => (
            //     <span>{text} quả</span>
            // )
        },
        {
            title: 'Khối lượng trung bình của 1 sọt (kg/sọt)',
            dataIndex: 'weightPerCrate',
            // render: (text, record) => (
            //     <span>{text} kg</span>
            // )
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            render: (_, record) => (
                <div
                    className='action-cell-table'
                >
                    <span onClick={() => onViewWarehouse(record)}>
                        <Tooltip title='Xem kho'>
                            <AiOutlineEye />
                        </Tooltip>
                    </span>
                    <span onClick={() => onEditWarehouse(record)}>
                        <Tooltip title='Sửa kho'>
                            <AiOutlineEdit />
                        </Tooltip>
                    </span>
                    <span onClick={() => onDeleteWarehouse(record)}>
                        <Tooltip title='Xóa kho'>
                            <AiOutlineDelete />
                        </Tooltip>
                    </span>
                    <span onClick={() => onOpenAddCamera(record)}>
                        <Tooltip title='Thêm camera'>
                            <AiOutlineCamera />
                        </Tooltip>
                    </span>
                </div>
            )
        }
    ];


    useEffect(() => {
        getAllInfoWarehouse();
        getAllFruitType();
    }, []);


    const getAllInfoWarehouse = async () => {
        try {

            let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";

            if (token) {
                token = token.slice(1);
                token = token.slice(0, token.length - 1);
            }

            await axios.get(`${CFG_BASE_URL}/warehouses?size=100&offset=0`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                console.log(res.data.data);
                if (res.data.data) {
                    const lst = res.data.data.map((item: any, index: any) => {
                        return {
                            ...item,
                            fruitType: item.fruitType.name,
                            orderByNumber: index + 1
                        }
                    })
                    setLstWarehouse(lst);
                }
            }).catch((err) => {
                console.log(err);
            });

        } catch (error) {
            console.error(error);
        }
    }


    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            setLoading(false);

        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: total,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    const getAllFruitType = async () => {
        try {
            const fruitType = await axios.get(`${CFG_BASE_URL}/fruitTypes`)

            console.log(fruitType.data.data);

            if (fruitType.data.data) {

                console.log(fruitType.data.data);

                setLstFruitType(fruitType.data.data.map((item: any, index: any) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                }));
            }

        } catch (error) {
            console.error(error);
        }
    }

    const onOpenCameraModal = () => {
        console.log('Add camera');
        formAdd.resetFields();
        setIsOpenModal(true);
    }

    const onFinishAddPack = async (values: any) => {
        console.log(values);

        try {
            let useId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";

            if (useId) {
                useId = useId.slice(1);
                useId = useId.slice(0, useId.length - 1);
            }

            const body = {
                name: values.name,
                address: values.address,
                userId: useId,
                area: parseInt(values.area),
                fruitTypeId: values.fruitType,
                origin: values.origin,
                fruitsPerCrate: parseInt(values.fruitsPerCrate),
                weightPerCrate: parseInt(values.weightPerCrate),
                additionalProp1: {}
            }

            let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
            if (token) {
                token = token.replace(/"/g, '');
            }

            await axios.post(`${CFG_BASE_URL}/warehouses`, body, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                console.log(res.data.data);

                setSelectWarehouse(res.data.data);
                setIsOpenCameraModal(true);
                formAddCamera.resetFields();

                notification.success({
                    message: 'Thêm kho thành công',
                    description: 'Thêm thông tin kho thành công, vui lòng thêm camera cho kho'
                } as any);

                formAdd.resetFields();
                getAllInfoWarehouse();
                setIsOpenModal(false);
            }).catch((err) => {
                console.log(err);
            });



        } catch (error) {
            console.error(error);
        }
    }

    const onOpenAddCamera = (e: any) => {
        console.log('Add camera', e);
        setSelectWarehouse(e);
        setIsOpenCameraModal(true);
        formAddCamera.resetFields();
    }

    const onFinishAddCamera = async (values: any) => {
        console.log(values);

        console.log(selectWarehouse);
        
        const body = {
            name: values.name,
            warehouseId: selectWarehouse.id,
            link: values.link,
            note: values.note,
            createdDate: new Date(),
            additionalProp1: {}
        }

        let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : "";
        if (token) {
            token = token.replace(/"/g, '');
        }

        try {
            await axios.post(`${CFG_BASE_URL}/camera`, body, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            }).then(async (res) => {
                console.log(res.data.data);
                notification.success({
                    message: 'Thêm camera thành công',
                    description: 'Có thể thêm camera khác cho kho',
                    duration: 2,
                } as any);

                
                formAddCamera.resetFields();
                await axios.post(`${WEBRTC_STREAMING}/rtc/start_link?link=${values.link}`).then((res) => {
                    console.log(res.data);
                }).catch((err) => {
                    console.log(err);
                });
                // setIsOpenCameraModal(false);
            }).catch((err) => {
                console.log(err);
            });

        } catch (error) {
            console.error(error);
        }
    }

    const onDeleteWarehouse = async (e: any) => {
        console.log('Delete warehouse', e);
        Modal.confirm({
            title: 'Xác nhận',
            content: 'Bạn có chắc chắn muốn xóa kho này không?',
            onOk: async () => {
                try {
                    await axios.delete(`${CFG_BASE_URL}/warehouses/delete/${e.id}`).then((res) => {
                        console.log(res.data.data);
                        notification.success({
                            message: 'Xóa kho thành công',
                            description: 'Xóa thông tin kho thành công',
                            duration: 2,
                        } as any);

                        getAllInfoWarehouse();
                    }).catch((err) => {
                        console.log(err);
                    });

                } catch (error) {
                    console.error(error);
                }
            },
            okText: 'Xác nhận',
            cancelText: 'Hủy'
        });
    }

    const onEditWarehouse = (e: any) => {
        console.log('Edit warehouse', e);
        const fruitTypeEdit = lstFruitType.find((item: any) => item.label === e.fruitType);

        setSelectWarehouse(e);
        formEdit.setFieldsValue({
            name: e.name,
            address: e.address,
            area: e.area,
            fruitType: fruitTypeEdit.value,
            origin: e.origin,
            fruitsPerCrate: e.fruitsPerCrate,
            weightPerCrate: e.weightPerCrate
        });
        setIsOpenEditModal(true);
    }

    const onFinishEditPack = async (values: any) => {
        console.log(values);
        console.log(selectWarehouse);

        try {
            await axios.put(`${CFG_BASE_URL}/warehouses/update/${selectWarehouse.id}`, {
                name: values.name,
                address: values.address,
                area: parseInt(values.area),
                fruitTypeId: values.fruitType,
                origin: values.origin,
                fruitsPerCrate: parseInt(values.fruitsPerCrate),
                weightPerCrate: parseInt(values.weightPerCrate),
                additionalProp1: {}
            }).then((res) => {
                console.log(res.data.data);
                notification.success({
                    message: 'Sửa kho thành công',
                    description: 'Sửa thông tin kho thành công'
                } as any);

                formEdit.resetFields();
                getAllInfoWarehouse();
                setIsOpenEditModal(false);
            }).catch((err) => {
                console.log(err);
            });

        } catch (error) {
            console.error(error);
        }
    }

    const onViewWarehouse = (e: any) => {
        console.log('View warehouse', e);

        navigate(`/crate_manager/${e.id}`);
    }

    return (
        <div className='pack-manage' >
            <div className='main-header'>
                <div className='title'>Quản lý kho</div>
                <div className='btn-add'>
                    <Button type='primary' onClick={onOpenCameraModal}>Thêm kho</Button>
                </div>
            </div>
            <div className='packs-detail'>
                <Table bordered className='table-pack-manage' columns={columns} dataSource={lstWarehouse} pagination={paginationConfig} />
            </div>

            <Modal
                visible={isOpenModal}
                title='Thêm kho'
                onOk={() => setIsOpenModal(false)}
                onCancel={() => setIsOpenModal(false)}
                className='modal-add-pack'
            >
                <Form
                    form={formAdd}
                    layout='vertical'
                    name='form_add'
                    onFinish={onFinishAddPack}
                >
                    <Form.Item
                        label='Tên kho'
                        name='name'
                        rules={[{ required: true, message: 'Vui lòng nhập tên kho' }]}
                    >
                        <Input placeholder='Nhập tên kho' />
                    </Form.Item>
                    <Form.Item
                        label='Địa chỉ'
                        name='address'
                        rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                    >
                        <Input placeholder='Nhập địa chỉ' />
                    </Form.Item>
                    <Form.Item
                        label='Diện tích (m²)'
                        name='area'
                        rules={[{ required: true, message: 'Vui lòng nhập diện tích (m²)' }]}
                    >
                        <Input type='number' placeholder='Nhập diện tích (m²)' />
                    </Form.Item>
                    <Form.Item
                        label='Loại quả'
                        name='fruitType'
                        rules={[{ required: true, message: 'Vui lòng nhập loại quả' }]}
                    >
                        <Select
                            options={lstFruitType}
                            placeholder='Chọn loại quả'
                        />
                    </Form.Item>
                    <Form.Item
                        label='Nguồn gốc quả'
                        name='origin'
                        rules={[{ required: true, message: 'Vui lòng nhập nguồn gốc quả' }]}
                    >
                        <Input placeholder='Nhập nguồn gốc quả' />
                    </Form.Item>
                    <Form.Item
                        label='Số quả (quả/sọt)'
                        name='fruitsPerCrate'
                        rules={[{ required: true, message: 'Vui lòng nhập số quả (quả/sọt)' }]}
                    >
                        <Input type='number' placeholder='Nhập số quả (quả/sọt)' />
                    </Form.Item>
                    <Form.Item
                        label='Khối lượng quả (kg/sọt)'
                        name='weightPerCrate'
                        rules={[{ required: true, message: 'Vui lòng nhập khối lượng quả (kg/sọt)' }]}
                    >
                        <Input type='number' placeholder='Nhập khối lượng quả (kg/sọt)' />
                    </Form.Item>
                    <Form.Item>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px'
                        }}>
                            <Button type='default' onClick={() => setIsOpenModal(false)}>Hủy</Button>
                            <Button type='primary' htmlType='submit'>Thêm</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                visible={isOpenCameraModal}
                title='Thêm camera'
                onOk={() => setIsOpenCameraModal(false)}
                onCancel={() => setIsOpenCameraModal(false)}
                className='modal-add-pack'
            >

                <Form
                    form={formAddCamera}
                    layout='vertical'
                    name='form_add_camera'
                    onFinish={onFinishAddCamera}
                >
                    <Form.Item
                        label='Tên camera'
                        name='name'
                        rules={[{ required: true, message: 'Vui lòng nhập tên camera' }]}
                    >
                        <Input placeholder='Nhập tên camera' />
                    </Form.Item>
                    <Form.Item
                        label='Link camera'
                        name='link'
                        rules={[{ required: true, message: 'Vui lòng nhập link camera' }]}
                    >
                        <Input placeholder='Nhập link camera' />
                    </Form.Item>

                    <Form.Item
                        label='Mô tả'
                        name='note'
                    >
                        <Input.TextArea placeholder='Nhập mô tả' rows={4} />
                    </Form.Item>

                    <Form.Item>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px'
                        }}>
                            <Button type='default' onClick={() => setIsOpenCameraModal(false)}>Hủy</Button>
                            <Button type='primary' htmlType='submit'>Thêm</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                visible={isOpenEditModal}
                title='Sửa kho'
                onOk={() => setIsOpenEditModal(false)}
                onCancel={() => setIsOpenEditModal(false)}
                className='modal-add-pack'
            >

                <Form
                    form={formEdit}
                    layout='vertical'
                    name='form_edit'
                    onFinish={onFinishEditPack}
                >
                    <Form.Item
                        label='Tên kho'
                        name='name'
                        rules={[{ required: true, message: 'Vui lòng nhập tên kho' }]}
                    >
                        <Input placeholder='Nhập tên kho' />
                    </Form.Item>
                    <Form.Item
                        label='Địa chỉ'
                        name='address'
                        rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                    >
                        <Input placeholder='Nhập địa chỉ' />
                    </Form.Item>
                    <Form.Item
                        label='Diện tích'
                        name='area'
                        rules={[{ required: true, message: 'Vui lòng nhập diện tích' }]}
                    >
                        <Input type='number' placeholder='Nhập diện tích' />
                    </Form.Item>
                    <Form.Item
                        label='Loại quả'
                        name='fruitType'
                        rules={[{ required: true, message: 'Vui lòng nhập loại quả' }]}
                    >
                        <Select
                            options={lstFruitType}
                            placeholder='Chọn loại quả'
                        />
                    </Form.Item>
                    <Form.Item
                        label='Nguồn gốc quả'
                        name='origin'
                        rules={[{ required: true, message: 'Vui lòng nhập nguồn gốc quả' }]}
                    >
                        <Input placeholder='Nhập nguồn gốc quả' />
                    </Form.Item>

                    <Form.Item
                        label='Số quả/sọt'
                        name='fruitsPerCrate'
                        rules={[{ required: true, message: 'Vui lòng nhập số quả/sọt' }]}
                    >
                        <Input type='number' placeholder='Nhập số quả/sọt' />
                    </Form.Item>
                    <Form.Item
                        label='Khối lượng/sọt'
                        name='weightPerCrate'
                        rules={[{ required: true, message: 'Vui lòng nhập khối lượng/sọt' }]}
                    >
                        <Input type='number' placeholder='Nhập khối lượng/sọt' />
                    </Form.Item>

                    <Form.Item>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px'
                        }}>
                            <Button type='default' onClick={() => setIsOpenEditModal(false)}>Hủy</Button>
                            <Button type='primary' htmlType='submit'>Sửa</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
}

export default CPackManager